import React, { useState, useEffect } from "react";
import { Box, Grid, List, Typography } from "@mui/material";
import { ListItem } from "@mui/material";
import { ListItemIcon } from "@mui/material";
import { ListItemText } from "@mui/material";
import { Collapse } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { handleSpringDataRequest } from "../../api";

import AccessibilityIcon from "@mui/icons-material/Accessibility";

import SettingsIcon from "@mui/icons-material/Settings";
import { Policy } from "@mui/icons-material";
import WidgetsIcon from "@mui/icons-material/Widgets";
import AddIcon from "@mui/icons-material/Add";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import * as AiIcons from "react-icons/ai";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaidIcon from "@mui/icons-material/Paid";
import SellIcon from "@mui/icons-material/Sell";
import PaymentsSharpIcon from "@mui/icons-material/PaymentsSharp";
import PaymentConfiguration from "./PaymentConfiguration";
import Dashboard from "./Dashboard/Dashboard";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import RazorpayConfiguration from "./RazorpayConfiguration";
import { connect } from "react-redux";
import PaymentModal from "../AccessControl/PaymentModal";

function SidebarDashboard({ data }) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("dashboard");
  const [mykeys, setmykeys] = useState(1);
  const [accessControl, setaccessControl] = useState({});
  const handleClick = () => {
    setOpen(!open);
  };
  const handleMenuItemClick = (item, val) => {
    setSelectedItem(item);
    setmykeys(val);
  };

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/accesscontrol/addUserWizardRender?email=${localStorage.getItem(
        "email"
      )}`
    )
      .then((res) => {
        console.log(res);
        setaccessControl(res.user_feature_map);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  //--------------------

  const [openMenu, setOpenMenu] = useState(true);
  const [gridValue, setGridValue] = useState(9);
  useEffect(() => {
    if (openMenu) {
      setGridValue(9);
    } else {
      setGridValue(12);
    }
  }, [openMenu]);

  const handleMenuBar = () => {
    setOpenMenu((prev) => !prev);
  };

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };
  const [paymentAmount, setpaymentAmount] = useState(0);
  const [daysDiff, setdaysDiff] = useState(0);
  const [finalDate, setfinalDate] = useState(new Date());

  const [showPaymentNotifBanner, setshowPaymentNotifBanner] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("mainGroupId") &&
      localStorage.getItem("mainGroupId") !== undefined &&
      localStorage.getItem("mainGroupId") !== null
    ) {
      handleSpringDataRequest(
        `core/api/v1/account/get-single/?groupId=${localStorage.getItem(
          "mainGroupId"
        )}`
      )
        .then((res) => {
          if (
            res?.account_details?.payment_dues_notification_banner === "YES"
          ) {
            setshowPaymentNotifBanner(true);
          } else {
            setshowPaymentNotifBanner(false);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [localStorage.getItem("mainGroupId")]);

  useEffect(() => {
    if (
      localStorage.getItem("mainGroupId") &&
      localStorage.getItem("mainGroupId") !== undefined &&
      localStorage.getItem("mainGroupId") !== null &&
      showPaymentNotifBanner
    ) {
      handleSpringDataRequest(
        `web-api/get-payment-dues-amount?groupId=${localStorage.getItem(
          "mainGroupId"
        )}`
      ).then((res) => {
        if (
          res.pendingAmount < 0 &&
          Number(res.daysDiff) >= 30 &&
          Number(res.daysDiff) <= 60
        ) {
          setpaymentAmount(res.pendingAmount);
          setShowPaymentModal(true);
          setdaysDiff(Number(res.daysDiff));
          setfinalDate(res.date);
        }
      });
    }
  }, [localStorage.getItem("mainGroupId"), showPaymentNotifBanner]);

  return (
    Object.entries(accessControl).length > 0 && (
      <Grid container spacing={1}>
        {/* <Grid item xs={12}></Grid> */}
        <Grid
          xs={3}
          item
          className="sidebarStatic"
          sx={{ display: openMenu ? "block" : "none" }}
        >
          <Box
            sx={
              {
                // boxShadow: "rgba(33, 35, 38, 0.2) 10px 0px 10px -10px",
                // height: "100%",
              }
            }
          >
            <List>
              <ListItem
                button
                onClick={() => handleMenuItemClick("dashboard", 1)}
              >
                <ListItemIcon
                  style={{
                    color: `${
                      selectedItem === "dashboard" ? "#1BC5BD" : "#757575"
                    }`,
                    fontWeight: "bold",
                  }}
                >
                  <LineAxisIcon sx={{ fontWeight: 700 }} />
                </ListItemIcon>
                <ListItemText
                  style={{
                    color: `${
                      selectedItem === "dashboard" ? "#1BC5BD" : "#757575"
                    }`,
                    fontWeight: "bold",
                  }}
                  primary={
                    <Typography style={{ fontWeight: 700 }}>
                      {t("Dashboard")}
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem
                button
                onClick={() => handleMenuItemClick("paymentConfiguration", 2)}
              >
                <ListItemIcon
                  style={{
                    color: `${
                      selectedItem === "paymentConfiguration"
                        ? "#1BC5BD"
                        : "#757575"
                    }`,
                  }}
                >
                  <PaymentsSharpIcon sx={{ fontWeight: 700 }} />
                </ListItemIcon>
                <ListItemText
                  style={{
                    color: `${
                      selectedItem === "paymentConfiguration"
                        ? "#1BC5BD"
                        : "#757575"
                    }`,
                  }}
                  primary={
                    <Typography style={{ fontWeight: 700 }}>
                      {t("Payment configuration")}
                    </Typography>
                  }
                />
              </ListItem>
              {data?.country?.toLowerCase() === "india" && (
                <ListItem
                  button
                  onClick={() =>
                    handleMenuItemClick("razorpayConfiguration", 3)
                  }
                >
                  <ListItemIcon
                    style={{
                      color: `${
                        selectedItem === "razorpayConfiguration"
                          ? "#1BC5BD"
                          : "#757575"
                      }`,
                    }}
                  >
                    <CardMembershipIcon sx={{ fontWeight: 700 }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color: `${
                        selectedItem === "razorpayConfiguration"
                          ? "#1BC5BD"
                          : "#757575"
                      }`,
                    }}
                    primary={
                      <Typography style={{ fontWeight: 700 }}>
                        {t("Razorpay configuration")}
                      </Typography>
                    }
                  />
                </ListItem>
              )}

              {/*   {JSON.parse(accessControl?.ExpenseWListing).read === "YES" && (
              <ListItem
                button
                onClick={() => handleMenuItemClick("Add withdrawal", 2)}
              >
                <ListItemIcon
                  style={{
                    color: `${
                      selectedItem === "Add withdrawal" ? "#1BC5BD" : "#757575"
                    }`,
                  }}
                >
                  <SellIcon />
                </ListItemIcon>
                <ListItemText primary={"Add withdrawal"} />
              </ListItem>
            )}
            {JSON.parse(accessControl?.ExpenseTListing).read === "YES" && (
              <ListItem
                button
                onClick={() => handleMenuItemClick("Add topup", 3)}
              >
                <ListItemIcon
                  style={{
                    color: `${
                      selectedItem === "Add topup" ? "#1BC5BD" : "#757575"
                    }`,
                  }}
                >
                  <PaidIcon />
                </ListItemIcon>
                <ListItemText primary={"Add topup"} />
              </ListItem>
            )} */}
              {/* <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Configuration" />
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding> */}
              {/* {JSON.parse(accessControl?.ExpenseEConfig).read === "YES" && ( */}
              {/* <ListItem
                  button
                  style={{ marginLeft: "1rem" }}
                  onClick={() =>
                    handleMenuItemClick("Payment configuration", 1)
                  }
                >
                  <ListItemIcon
                    style={{
                      color: `${
                        selectedItem === "Payment configuration"
                          ? "#1BC5BD"
                          : "#757575"
                      }`,
                    }}
                  >
                    <PaymentsSharpIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Payment configuration"} />
                </ListItem> */}
              {/* )} */}
              {/* {JSON.parse(accessControl?.ExpenseWConfig).read === "YES" && (
                  <ListItem
                    button
                    style={{ marginLeft: "1rem" }}
                    // selected={selectedItem === "Introduction"}

                    onClick={() =>
                      handleMenuItemClick("Configure withdrawal", 5)
                    }
                  >
                    <ListItemIcon
                      style={{
                        color: `${
                          selectedItem === "Configure withdrawal"
                            ? "#1BC5BD"
                            : "#757575"
                        }`,
                      }}
                    >
                      <SellIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Configure withdrawal"} />
                  </ListItem>
                )}
                {JSON.parse(accessControl?.ExpenseTConfig).read === "YES" && (
                  <ListItem
                    button
                    style={{ marginLeft: "1rem" }}
                    // selected={selectedItem === "Introduction"}

                    onClick={() => handleMenuItemClick("Configure topup", 6)}
                  >
                    <ListItemIcon
                      style={{
                        color: `${
                          selectedItem === "Configure topup"
                            ? "#1BC5BD"
                            : "#757575"
                        }`,
                      }}
                    >
                      <PaidIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Configure topup"} />
                  </ListItem>
                )}
                {JSON.parse(accessControl?.ExpenseEmpConfig).read === "YES" && (
                  <ListItem
                    button
                    // selected={selectedItem === "Introduction"}
                    style={{ marginLeft: "1rem" }}
                    onClick={() => handleMenuItemClick("Configure employee", 7)}
                  >
                    <ListItemIcon
                      style={{
                        color: `${
                          selectedItem === "Configure employee"
                            ? "#1BC5BD"
                            : "#757575"
                        }`,
                      }}
                    >
                      <AccessibilityIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Configure employee"} />
                  </ListItem>
                )} */}
              {/* </List>
            </Collapse>*/}
            </List>
          </Box>
        </Grid>

        <Grid item xs={gridValue}>
          <MenuIcon
            fontSize="large"
            sx={{ mb: 1, cursor: "pointer" }}
            onClick={handleMenuBar}
          />
          {mykeys === 1 && <Dashboard fullScreen={!openMenu} />}
          {mykeys === 2 && <PaymentConfiguration />}
          {mykeys === 3 && <RazorpayConfiguration />}

          {/* {mykeys === 2 && <WithdrawalListings />}
          {mykeys === 3 && <TopupListings />}
          {mykeys === 4 && <ExpenseMaster />}
          {mykeys === 5 && <WithdrawalMaster />}
          {mykeys === 6 && <TopupMaster />}
          {mykeys === 7 && <EmployeeConfiguration />} */}
        </Grid>
        <PaymentModal
          showPaymentModal={showPaymentModal}
          paymentAmount={paymentAmount}
          closePaymentModal={closePaymentModal}
          days={daysDiff}
          finalDate={finalDate}
        />
      </Grid>
    )
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(SidebarDashboard);
