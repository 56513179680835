import {
  FormControl,
  Grid,
  Grow,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
  Button,
  Autocomplete,
  Box,
  Fab,
  Drawer,
  Typography,
  Divider,
} from "@mui/material";
import { cloneDeep } from "lodash";
import { makeStyles, useTheme } from "@mui/styles";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  getChannels,
  getHotelChannelsAndId,
  getRateMultiplier,
  updateOTArateMultiplier,
} from "../api/InventoryApi";
import { connect } from "react-redux";
import { toast } from "material-react-toastify";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import ConfirmationModal from "./ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  textDisplay: {
    fontSize: "12px",
    marginRight: 6,
  },
  subText: {
    fontSize: "14px",
  },
}));

function OtaRateMultiplier(props) {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  console.log("propsss", props);
  const classes = useStyles();
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [multiplier, setMultiplier] = useState(0);
  const [multiplierType, setMultiplierType] = useState("PERCENT");
  const [loader, setLoader] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const handleChange = (e) => {
    setMultiplier(e?.target?.value);
  };

  const getDisplayData = () => {
    let tempArray = [];
    selectedChannel.forEach((channel) => {
      tempArray.push(channel?.channelName);
    });
    return (
      <Grid container>
        <Grid display={"flex"} paddingBottom={1} item xs={12}>
          <Typography className={classes.textDisplay}>OTA's: </Typography>
          <Typography className={classes.subText} variant="body1">
            {[tempArray.join(", ")]}
          </Typography>
        </Grid>
        <Grid paddingBottom={1} display={"flex"} item xs={12}>
          <Typography className={classes.textDisplay}>
            Multiplier value:
          </Typography>
          <Typography className={classes.subText} variant="body1">
            {multiplier}
          </Typography>
        </Grid>
        <Grid paddingBottom={1} display={"flex"} item xs={12}>
          <Typography className={classes.textDisplay}>
            Multiplier type:
          </Typography>
          <Typography className={classes.subText} variant="body1">
            {multiplierType}
          </Typography>
        </Grid>

        <Grid paddingBottom={1} display={"grid"} item xs={12}>
          <div
            style={{
              borderTop: "2px solid black",
              marginTop: "1rem",
            }}
          ></div>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    // getChannels()
    //   .then((data) => {
    //     const finalData = data?.filter(
    //       (elm) => elm !== "walkin" && elm !== "booking engine"
    //     );
    //     setChannels(finalData);
    //   })
    //   .catch(() => {});

    getHotelChannelsAndId()
      .then((data) => {
        const finalData = data?.registered?.filter(
          (elm) =>
            elm?.channelName !== "walkin" &&
            elm?.channelName !== "booking engine"
        );
        setChannels(finalData);
        setSelectedChannel(finalData);
      })
      .catch(() => {});
  }, []);

  const handleSubmit = () => {
    setLoader(true);
    const selectedChannels = [];
    selectedChannel.map((item) => {
      return selectedChannels?.push(item?.channelName);
    });
    const payload = {
      hotelId: props?.data?.hotelId,
      channelIds: selectedChannels,
      multiplier: multiplier,
      type: multiplierType,
    };
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/inventory/add-ota-multiplier",
      payload
    )
      .then(() => {
        setLoader(false);
        toast.success("OTA rate updated successfully");
        props.toOpenBulkSyncReport();
      })
      .catch(() => {
        setLoader(false);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/inventory/get-ota-multiplier?channelId=${selectedChannel}`
    )
      .then((data) => {
        setMultiplier(data);
      })
      .catch(() => {});
  }, [props, selectedChannel]);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container className={classes.textField} spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            multiple
            disableClearable
            disableCloseOnSelect
            id="channels"
            onChange={(eve, val) => {
              if (val.length > 0) setSelectedChannel(val);
            }}
            ChipProps={{ variant: "outlined", color: "primary" }}
            value={selectedChannel}
            isOptionEqualToValue={(opt, val) => opt.channelId === val.channelId}
            options={channels}
            getOptionLabel={(option) => option.channelName}
            renderInput={(params) => (
              <TextField {...params} label={"Channels"} />
            )}
          />
        </Grid>
        {/* <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Channel</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Channel"
                value={selectedChannel}
                disabled={channels?.length <= 0}
                onChange={(e) => setSelectedChannel(e?.target.value)}
              >
                {channels?.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
        <Grid item xs={9} sm={8} md={8} lg={6} xl={6}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Set Multiplier"
            variant="outlined"
            value={multiplier}
            onChange={handleChange}
            disabled={!selectedChannel}
            InputProps={{
              type: "number",
              // endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={3} sm={4} md={4} lg={3} xl={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Type"
              value={multiplierType}
              onChange={(e) => setMultiplierType(e?.target.value)}
            >
              <MenuItem value={"PERCENT"}>{"%"}</MenuItem>
              <MenuItem value={"ABSOLUTE"}>
                {hotel.accessControl.hotel.hotel_currency}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          className={classes.textField}
          justifyContent="flex-end"
          container
        >
          <Fab
            onClick={() => setShowConfirm(true)}
            variant="extended"
            color="primary"
            disabled={loader || !multiplier || selectedChannel?.length < 1}
            // sx={{ width: 180 }}
          >
            {loader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${"Apply Multiplier"}`
            )}
          </Fab>
        </Grid>
      </Grid>

      <ConfirmationModal
        open={showConfirm}
        onClose={() => {
          setShowConfirm(false);
        }}
        onBackdropClick={true}
        title={"OTA rate multiplier"}
        loading={loader}
        displayData={showConfirm ? getDisplayData() : null}
        confirmAction={handleSubmit}
      ></ConfirmationModal>
    </Box>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}

export default connect(mapStateToProps)(OtaRateMultiplier);
