// ** React Imports
import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import SetPricingComponent from "./SetPricingView";
import InventoryCapping from "./InventoryCappingView";
import SetOnlineView from "./SetOnlineView";
import UpdateRatesView from "./UpdateRatesView";
// import { DropdownOptions } from "../constants/InventoryConstants";
import { getAllNonHiddenRoomTypes } from "../api/InventoryApi";
import CommonSlider from "../../CalendarPage/Views/CommonSlider";
import OpenCloseView from "./OpenCloseViews";
import { connect } from "react-redux";
import { handleSpringDataRequest } from "../../../api";
import { cloneDeep } from "lodash";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";
import ShowOfflineRooms from "./ShowOfflineRooms";
import OtaRateMultiplier from "./OtaRateMultiplier";
import BulkSync from "./BulkSyncView";
import BulkSyncStatusReport from "./BulkSyncStatusReport";
import RefreshIcon from "@mui/icons-material/Refresh";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(2),
  },
  linerProgress: {
    marginBottom: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginop: theme.spacing(2),
    },
  },
  header: {
    padding: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
    },
  },
};

const EditEventSidebar = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  const DropdownOptions = [
    { text: `${t("Set Pricing")}`, val: "set-price" },
    { text: `${t("Set Inventory")}`, val: "set-inventory" },
    { text: `${t("Rate Multiplier")}`, val: "rate-multiplier" },
    { text: `${t("Inventory Capping")}`, val: "inventory-cap" },
    { text: `${t("Set Online")}`, val: "set-online" },
    { text: `${t("Set Offline")}`, val: "set-offline" },
    { text: `${t("Block Rooms")}`, val: "block-room" },
    { text: `${t("Unblock Rooms")}`, val: "unblock-room" },
    { text: `${t("Delete Rooms")}`, val: "delete-room" },
    { text: `${t("Restore Deleted Rooms")}`, val: "restore" },
    { text: `${t("Update Rates from OTA")}`, val: "update-ota" },
    { text: `${t("Set Sold Out")}`, val: "sold-out" },
    { text: `${t("Edit Room Ids")}`, val: "edit-room" },
    { text: `${t("Reassign Room Type")}`, val: "reassign" },
    { text: `${t("Close/Open Rate Plan")}`, val: "Close Rate" },
    { text: `${t("Close/Open Room Type")}`, val: "Close Room" },
    { text: `${t("Close/Open On Arrival")}`, val: "Close On Arrival" },
    { text: `${t("Close/Open On Departure")}`, val: "Close On Departure" },
    { text: `${t("Min Length of Stay")}`, val: "Set Min Los" },
    { text: `${t("Max Length of Stay")}`, val: "Set Max Los" },
    { text: `${t("Min Advanced Booking Offset")}`, val: "Set Cutoff" },
    { text: `${t("Max Advanced Booking Offset")}`, val: "Set Max Adv Offset" },
    { text: `${t("Show offline rooms")}`, val: "Show offline rooms" },
    { text: `${t("OTA rate multiplier")}`, val: "OTA rate multiplier" },
    { text: `${t("Bulk sync")}`, val: "Bulk sync" },
  ];

  const EditRateAccess =
    JSON.parse(props?.data?.accessControl?.user_feature_map?.EditRates).read ===
    "YES";

  const EditInevntoryAccess =
    JSON.parse(props?.data?.accessControl?.user_feature_map?.EditInventory)
      .read === "YES";

  const [rooms, setRooms] = React.useState();
  const [selectedRoom, setSelectedRoom] = useState("");
  const [actionType, setActionType] = useState(
    EditRateAccess
      ? "set-price"
      : EditInevntoryAccess
      ? "set-inventory"
      : "edit-room"
  );
  const [dataLoading, setDataLoading] = useState(true);
  const [showLine, setShowLine] = useState(false);
  const [syncReportRefresh, setSyncReportRefresh] = useState({});
  const classes = useStyles();
  const { handleAddEventSidebar, open, refresh } = props;
  const toOpenBulkSyncReport = () => {
    setActionType("Bulk sync report");
  };

  const setLineVisible = () => {
    setShowLine(true);
  };

  const setLineInvisible = () => {
    setShowLine(false);
  };

  const isOnlineComponent = (selectType) => {
    if (
      selectType === "set-online" ||
      selectType === "set-offline" ||
      selectType === "block-room" ||
      selectType === "unblock-room" ||
      selectType === "delete-room" ||
      selectType === "edit-room" ||
      "sold-out"
    ) {
      return true;
    }
    return false;
  };

  const getActionName = (name) => {
    const item = DropdownOptions.find((option) => option.val === name);
    return item.text;
  };

  const intialFunc = async () => {
    setLineVisible();
    setDataLoading(true);
    let resp = await getAllNonHiddenRoomTypes();
    setSelectedRoom(Object.keys(resp)[0]);
    setRooms(resp);
    setDataLoading(false);
  };

  useEffect(() => {
    intialFunc();
  }, []);

  const [roomAndPlans, setroomAndPlans] = useState([]);

  useEffect(() => {
    handleSpringDataRequest(`core/api/v1/room/get-all-roomtype-obj`)
      .then((res) => {
        setroomAndPlans(res);
      })
      .catch((err) => console.error(err));
  }, []);

  const [AccessControl, setAccessControl] = useState([
    {
      EditRateAccess: new Set([
        `${t("Set Pricing")}`,
        `${t("Rate Multiplier")}`,
        `${t("Update Rates from OTA")}`,
        `${t("Min Advanced Booking Offset")}`,
        `${t("Max Advanced Booking Offset")}`,
        `${t("OTA rate multiplier")}`,
        `${t("Bulk sync")}`,
      ]),
    },
    {
      EditInevntoryAccess: new Set([
        `${t("Set Inventory")}`,
        `${t("Inventory Capping")}`,
        `${t("Set Offline")}`,
        `${t("Set Online")}`,
        `${t("Unblock Rooms")}`,
        `${t("Block Rooms")}`,
        `${t("Set Sold Out")}`,
        `${t("Reassign Room Type")}`,
        `${t("Close/Open Room Type")}`,
        `${t("Close/Open Rate Plan")}`,
        `${t("Close/Open On Arrival")}`,
        `${t("Close/Open On Departure")}`,
        `${t("Min Length of Stay")}`,
        `${t("Max Length of Stay")}`,
        `${t("Delete Rooms")}`,
        `${t("Restore Deleted Rooms")}`,
        `${t("Edit Room Ids")}`,
        `${t("Show offline rooms")}`,
      ]),
    },
  ]);

  return (
    <div className={classes.root}>
      <CommonSlider
        open={open}
        onClose={() => {
          handleAddEventSidebar();
        }}
      >
        <Grid container>
          <Grid container item xs={12} className={classes.header}>
            <Grid
              container
              xs={10}
              spacing={2}
              justifyContent="flex-start"
              item
            >
              {actionType === "Bulk sync report" ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="h5">
                    Latest rates and inventory activity feed
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t("Action Type")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={actionType}
                      label={t("Action Type")}
                      title={getActionName(actionType)}
                      onChange={(eve) => {
                        if (isOnlineComponent(eve.target.value)) {
                          setSelectedRoom("all-rooms");
                        }
                        if (
                          selectedRoom === "all-rooms" &&
                          !isOnlineComponent(eve.target.value)
                        ) {
                          setSelectedRoom(Object.keys(rooms)[0]);
                        }
                        if (eve.target.value === "Show offline rooms") {
                          setSelectedRoom(Object.keys(rooms)[0]);
                        }
                        setActionType(eve.target.value);
                      }}
                      MenuProps={MenuProps}
                    >
                      {DropdownOptions.map((name) => {
                        if (EditRateAccess) {
                          if (
                            AccessControl[0]["EditRateAccess"].has(name.text)
                          ) {
                            return (
                              <MenuItem key={name.text} value={name.val}>
                                {name.text}
                              </MenuItem>
                            );
                          }
                        }
                        if (EditInevntoryAccess) {
                          if (
                            AccessControl[1]["EditInevntoryAccess"].has(
                              name.text
                            ) &&
                            name.text === `${t("Unblock Rooms")}` &&
                            props?.data?.accessControl?.ADMINRIGHTS !==
                              "ADMIN" &&
                            props?.data?.accessControl?.ADMINRIGHTS !==
                              "SUBADMIN" &&
                            JSON.parse(
                              props?.data?.accessControl?.user_feature_map
                                ?.HideUnBlockRooms
                            ).read !== "YES"
                          ) {
                            return (
                              <MenuItem key={name.text} value={name.val}>
                                {name.text}
                              </MenuItem>
                            );
                          }

                          if (
                            AccessControl[1]["EditInevntoryAccess"].has(
                              name.text
                            ) &&
                            name.text === `${t("Block Rooms")}` &&
                            props?.data?.accessControl?.ADMINRIGHTS !==
                              "ADMIN" &&
                            props?.data?.accessControl?.ADMINRIGHTS !==
                              "SUBADMIN" &&
                            JSON.parse(
                              props?.data?.accessControl?.user_feature_map
                                ?.HideBlockRooms
                            ).read !== "YES"
                          ) {
                            return (
                              <MenuItem key={name.text} value={name.val}>
                                {name.text}
                              </MenuItem>
                            );
                          }

                          if (
                            AccessControl[1]["EditInevntoryAccess"].has(
                              name.text
                            ) &&
                            name.text !== `${t("Block Rooms")}` &&
                            name.text !== `${t("Unblock Rooms")}` &&
                            name.text !== `${t("Delete Rooms")}` &&
                            name.text !== `${t("Restore Deleted Rooms")}` &&
                            props?.data?.accessControl?.ADMINRIGHTS !==
                              "ADMIN" &&
                            props?.data?.accessControl?.ADMINRIGHTS !==
                              "SUBADMIN"
                          ) {
                            return (
                              <MenuItem key={name.text} value={name.val}>
                                {name.text}
                              </MenuItem>
                            );
                          }

                          if (
                            AccessControl[1]["EditInevntoryAccess"].has(
                              name.text
                            ) &&
                            (props?.data?.accessControl?.ADMINRIGHTS ===
                              "ADMIN" ||
                              props?.data?.accessControl?.ADMINRIGHTS ===
                                "SUBADMIN")
                          ) {
                            return (
                              <MenuItem key={name.text} value={name.val}>
                                {name.text}
                              </MenuItem>
                            );
                          }
                        }
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {!dataLoading && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      hidden={
                        actionType === "rate-multiplier" ||
                        actionType === "inventory-cap" ||
                        actionType === "Set Max Adv Offset" ||
                        actionType === "Set Cutoff" ||
                        actionType === "Set Max Los" ||
                        actionType === "Set Min Los" ||
                        actionType === "Close On Departure" ||
                        actionType === "Close On Arrival" ||
                        actionType === "Close Room" ||
                        actionType === "Close Rate" ||
                        actionType === "set-inventory" ||
                        actionType === "set-price" ||
                        actionType === "OTA rate multiplier" ||
                        actionType === "Bulk sync" ||
                        actionType === "Bulk sync report"
                      }
                      id="demo-simple-select-label"
                    >
                      {t("Room Types")}
                    </InputLabel>
                    <Select
                      disabled={
                        actionType === "sold-out" ||
                        actionType === "update-ota" ||
                        actionType === "edit-room"
                      }
                      labelId="demo-simple-select-label"
                      onChange={(eve) => {
                        setSelectedRoom(eve.target.value);
                      }}
                      hidden={
                        actionType === "rate-multiplier" ||
                        actionType === "inventory-cap" ||
                        actionType === "Set Max Adv Offset" ||
                        actionType === "Set Cutoff" ||
                        actionType === "Set Max Los" ||
                        actionType === "Set Min Los" ||
                        actionType === "Close On Departure" ||
                        actionType === "Close On Arrival" ||
                        actionType === "Close Room" ||
                        actionType === "Close Rate" ||
                        actionType === "set-inventory" ||
                        actionType === "set-price" ||
                        actionType === "OTA rate multiplier" ||
                        actionType === "Bulk sync" ||
                        actionType === "Bulk sync report"
                      }
                      id="demo-simple-select"
                      value={selectedRoom}
                      label={t("Room Types")}
                      MenuProps={MenuProps}
                    >
                      {actionType === "sold-out" &&
                        actionType !== "Show offline rooms" && (
                          <MenuItem key="all-rooms" value={"all-rooms"}>
                            {t("All Rooms")}
                          </MenuItem>
                        )}
                      {actionType !== "sold-out" &&
                        actionType !== "Show offline rooms" &&
                        isOnlineComponent(actionType) && (
                          <MenuItem key="all-rooms" value={"all-rooms"}>
                            {t("All Rooms")}
                          </MenuItem>
                        )}
                      {Object.keys(rooms).map((name) => (
                        <MenuItem key={name} value={name}>
                          {rooms[name]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid container xs={2} item justifyContent="flex-end">
              <Box>
                {actionType === "Bulk sync report" && (
                  <Tooltip title="Refresh">
                    <IconButton
                      onClick={() => setSyncReportRefresh(new Date())}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Close">
                  <IconButton onClick={handleAddEventSidebar}>
                    <Close />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          {showLine && <LinearProgress className={classes.linerProgress} />}
          {!showLine && <Divider sx={{ width: "100%", marginBottom: 2 }} />}

          {!dataLoading && (
            <Grid sx={{ width: "100%", height: "100%" }} container>
              {actionType === "set-price" && (
                <SetPricingComponent
                  data={props.data}
                  componentType="set-price"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                  roomAndPlanscopy={cloneDeep(roomAndPlans)}
                  selectedLanguage={props.selectedLanguage}
                  toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}
              {actionType === "set-inventory" && (
                <SetPricingComponent
                  data={props.data}
                  componentType="set-inventory"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                  // selectedLanguage={props.selectedLanguage}
                />
              )}
              {actionType === "rate-multiplier" && (
                <InventoryCapping
                  componentType="rate-multiplier"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  selectedLanguage={props.selectedLanguage}
                  roomAndPlans={roomAndPlans}
                  toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}
              {actionType === "inventory-cap" && (
                <InventoryCapping
                  componentType="inventory-cap"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  selectedLanguage={props.selectedLanguage}
                  roomAndPlans={roomAndPlans}
                  // toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}
              {actionType === "set-online" && (
                <SetOnlineView
                  data={props.data}
                  componentType="set-online"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  selectedLanguage={props.selectedLanguage}
                  // toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "set-offline" && (
                <SetOnlineView
                  data={props.data}
                  componentType="set-offline"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  selectedLanguage={props.selectedLanguage}
                  // toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "block-room" && (
                <SetOnlineView
                  data={props.data}
                  componentType="block-room"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  selectedLanguage={props.selectedLanguage}
                  // toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "unblock-room" && (
                <SetOnlineView
                  data={props.data}
                  componentType="unblock-room"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  selectedLanguage={props.selectedLanguage}
                  // toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "delete-room" && (
                <SetOnlineView
                  data={props.data}
                  componentType="delete-room"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  selectedLanguage={props.selectedLanguage}
                  // toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "restore" && (
                <SetOnlineView
                  data={props.data}
                  componentType="restore"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  selectedLanguage={props.selectedLanguage}
                  // toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "sold-out" && (
                <UpdateRatesView
                  data={props.data}
                  componentType="sold-out"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  selectedLanguage={props.selectedLanguage}
                  // toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "update-ota" && (
                <UpdateRatesView
                  data={props.data}
                  componentType="update-ota"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  selectedLanguage={props.selectedLanguage}
                  // toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "reassign" && (
                <SetOnlineView
                  data={props.data}
                  componentType="reassign"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  selectedLanguage={props.selectedLanguage}
                  // toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "edit-room" && (
                <UpdateRatesView
                  data={props.data}
                  componentType="edit-room"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  selectedLanguage={props.selectedLanguage}
                  toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "Close Rate" && (
                <OpenCloseView
                  componentType="Close Rate"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                  selectedLanguage={props.selectedLanguage}
                  toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "Close Room" && (
                <OpenCloseView
                  componentType="Close Room"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                  selectedLanguage={props.selectedLanguage}
                  toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "Close On Arrival" && (
                <OpenCloseView
                  componentType="Close On Arrival"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                  selectedLanguage={props.selectedLanguage}
                  toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "Close On Departure" && (
                <OpenCloseView
                  componentType="Close On Departure"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                  selectedLanguage={props.selectedLanguage}
                  toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "Set Min Los" && (
                <OpenCloseView
                  componentType="Set Min Los"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                  selectedLanguage={props.selectedLanguage}
                  toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "Set Max Los" && (
                <OpenCloseView
                  componentType="Set Max Los"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                  selectedLanguage={props.selectedLanguage}
                  toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "Set Cutoff" && (
                <OpenCloseView
                  componentType="Set Cutoff"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                  selectedLanguage={props.selectedLanguage}
                  // toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}

              {actionType === "Set Max Adv Offset" && (
                <OpenCloseView
                  componentType="Set Max Adv Offset"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                  selectedLanguage={props.selectedLanguage}
                  // toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}
              {actionType === "Show offline rooms" && (
                <ShowOfflineRooms
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  refresh={refresh}
                  setLineInvisible={setLineInvisible}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                  selectedLanguage={props.selectedLanguage}
                  // toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}
              {actionType === "OTA rate multiplier" && (
                <OtaRateMultiplier
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  refresh={refresh}
                  setLineInvisible={setLineInvisible}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                  selectedLanguage={props.selectedLanguage}
                  toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}
              {actionType === "Bulk sync" && (
                <BulkSync
                  refresh={refresh}
                  open={true}
                  handleAddEventSidebar={handleAddEventSidebar}
                  selectedLanguage={props.selectedLanguage}
                  setLineInvisible={setLineInvisible}
                  setLineVisible={setLineVisible}
                  toOpenBulkSyncReport={toOpenBulkSyncReport}
                />
              )}
              {actionType === "Bulk sync report" && (
                <BulkSyncStatusReport syncReportRefresh={syncReportRefresh} />
              )}
            </Grid>
          )}
        </Grid>
      </CommonSlider>
    </div>
  );
};
function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}

export default connect(mapStateToProps)(EditEventSidebar);
