import React, { useState, useEffect } from "react";
import {
  Stack,
  Modal,
  Typography,
  Box,
  Button,
  Divider,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  Chip,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import {
  SecondaryButton,
  OutlinedButton,
} from "../../../../../Utils/ButtonHelper";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
} from "../../../../../api";
import { toast } from "material-react-toastify";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  maxHeight: 550,
  pb: 2,
  overflowY: "scroll",
};

function SettlePayment({
  data,
  openSettlePaymentModal,
  // handleOpenSettlePaymentModal,
  handleCloseSettlePaymentModal,
  // tableId,
  tableName,
  // shopId,
  // orderId,
  balanceDue,
  // checkComplimentaryOrder,
  // shopName,
  // hotelId,
  // tax,
  paymentId = null,
  // paymentPaid = 0,
  // paymentPaidType = null,
  // handleGetAllOrders,
  // customerName = null,
  // customerEmail = null,
  // customerPhone = null,
  updatePayment = false,
  // bookingId = null,
  loggedUserEmail = null,
  updateSummaryData = null,
  viewOrderFlag = 0,
  updateAllInViewOrders = null,
  // reservationId = null,
  order,
  inroom,
  saveOrder,
  // handleGetOrderDetails = null,
}) {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const { shopType, shopName } = useParams();
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const hotelCurrency = localStorage.getItem("hotelCurrency");

  // Getting custom pg data
  const [customPgData, setCustomPgData] = useState(null);
  const getCustomPgData = () => {
    djangoHandleDataRequests(`payments/getHotelCustomPgInfo/`)
      .then((res) => setCustomPgData(res !== null ? res : []))
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error(err));
  };
  useEffect(() => {
    getCustomPgData();
  }, [hotelId]);

  // Defining the payment type, settlement amount, and balanced due captured
  const [paymentType, setPaymentType] = useState("cash");
  const [settlementAmount, setSettlementAmount] = useState("");
  const handleUpdateSettlementAmount = () => {
    if (order?.complimentary_order === "NO") {
      return setSettlementAmount(Number(balanceDue));
    }
    return setSettlementAmount(0.0);
  };
  useEffect(() => {
    handleUpdateSettlementAmount();
  }, [order, balanceDue]);

  // Defining the fields while taking the payments
  // This is the basic calculator to calculate what amount customer is giving and what what is supposed to be returned
  const [customerPaid, setCustomerPaid] = useState("");

  // function to calculate return to customer if payment type is cash
  const [returnToCustomer, setReturnToCustomer] = useState(0.0);
  const handleGetReturnToCustomer = () => {
    if (Number(customerPaid) < Number(balanceDue)) {
      return setReturnToCustomer("Less amount");
    } else {
      const returnToCustomer = Number(customerPaid) - Number(balanceDue);
      return setReturnToCustomer(Number(returnToCustomer).toFixed(2));
    }
  };

  useEffect(() => {
    handleGetReturnToCustomer();
  }, [customerPaid]);

  // wallet lists
  // 1) India
  // 2) US, etc.
  const walletListIndia = [
    "Gpay",
    "Paytm",
    "Phone pe",
    "Amazon pay",
    "WhatsApp pe",
  ];
  const walletListOthers = ["Gpay"];
  // select wallet name if the payment type is wallet
  const [walletName, setWalletName] = useState("Gpay");

  // in case of payment type as other provide a field for settle payment type as well
  const [settlePaymentType, setSettlePaymentType] = useState("");

  // for part payments creating a separate payload with payment type as well as the settlement amount
  const [partPayment, setPartPayment] = useState([
    {
      id: "1",
      subPaymentType: "Cash",
      amount: 0.0,
    },
    {
      id: "2",
      subPaymentType: "Card",
      amount: 0.0,
    },
    {
      id: "3",
      subPaymentType: "Wallet",
      amount: 0.0,
    },
  ]);

  // If order is complimentary mark the payment status as Complimentary
  const handlePaymentStatus = () => {
    if (order?.complimentary_order === "YES") {
      return "COMPLIMENTARY";
    } else {
      if (Number(settlementAmount) === Number(balanceDue)) {
        return "SETTLED";
      } else {
        return "DUE";
      }
    }
  };

  // POS order payment settle
  const handlePosOrderPayment = async () => {
    setShowSettleLoader(true);
    try {
      if (inroom && !paymentId) {
        await saveOrder({ updatedOrderStatus: "INROOM" });
      }
      const res = await handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/order-payment/create`,
        settlePayment
      );
      if (viewOrderFlag === 1) {
        updateAllInViewOrders();
      }
      if (paymentType !== "EMV_CARD") {
        handleCleanPostPayment(`${t("Succesfully settled Payment")}`);
      }
    } catch (err) {
      toast.error(err);
    } finally {
      setShowSettleLoader(false);
    }
  };

  // POS bill settlement using CASH
  const handleFolioCashSettlement = () => {
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordCashPayment/`,
        {
          hotelId: hotelId,
          bookingID: order?.booking_id,
          amount: Number(settlementAmount),
          offline_card_date: moment().format("YYYY-MM-DD HH:mm:ss"),
          service_amount_desc: `POS settlement for order ${order?.id} using CASH.`,
          logged_user_email: loggedUserEmail ?? data?.accessControl?.email,
        }
      )
      .then((res) => {
        handlePosOrderPayment();
        updateSummaryData && updateSummaryData();
      });
  };

  // POS bill settlement using CARD
  const handleFolioCardSettlement = () => {
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOfflineCardPayment/`,
        {
          hotelId: hotelId,
          bookingID: order?.booking_id,
          amount: Number(settlementAmount),
          offline_card_date: moment().format("YYYY-MM-DD HH:mm:ss"),
          service_amount_desc: `POS settlement for order ${order?.id} using CARD.`,
          logged_user_email: loggedUserEmail ?? data?.accessControl?.email,
        }
      )
      .then((res) => {
        handlePosOrderPayment();
        updateSummaryData && updateSummaryData();
      });
  };

  // POS bill settlement using Wallet (UPI)
  const handleFolioWalletSettlement = () => {
    axios
      .post(`${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordUPIPayment/`, {
        hotelId: hotelId,
        bookingID: order?.booking_id,
        amount: Number(settlementAmount),
        offline_upi_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        service_amount_desc: `POS settlement for order ${order?.id} using CARD.`,
        logged_user_email: loggedUserEmail ?? data?.accessControl?.email,
      })
      .then((res) => {
        handlePosOrderPayment();
        updateSummaryData && updateSummaryData();
      });
  };

  // POS bill settlement using Other Payment Mode
  const handleFolioOtherModePaySettlement = () => {
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOtherPayment/`,
        {
          hotelId: hotelId,
          bookingID: order?.booking_id,
          amount: Number(settlementAmount),
          other_payment_date: moment().format("YYYY-MM-DD HH:mm:ss"),
          service_amount_desc: `POS settlement for order ${order?.id} using CARD.`,
          logged_user_email: loggedUserEmail ?? data?.accessControl?.email,
        }
      )
      .then((res) => {
        handlePosOrderPayment();
        updateSummaryData && updateSummaryData();
      });
  };

  // POS bill settlement for EMV card
  const [emvTerminalId, setEmvTerminalId] = useState(null);
  useEffect(() => {
    if (customPgData) {
      const terminalId = () => {
        if (customPgData.hasOwnProperty("terminals")) {
          setEmvTerminalId(customPgData.terminals[0].terminal_id);
        }
      };
      terminalId();
    }
  }, [customPgData, hotelId]);

  const handleChargeEmvCard = async () => {
    try {
      const data = {
        hotelId: hotelId,
        bookingID: order?.booking_id,
        reservation_id: order?.reservation_id,
        cust_email: order?.cust_email,
        // name: customerName,
        name: order?.cust_name,
        is_emv: "YES",
        trans_type: "sale",
        add_card: false,
        amount: Number(settlementAmount),
        logged_user_email: loggedUserEmail ?? data?.accessControl?.email,
        order_id: order?.id,
      };
      if (emvTerminalId) {
        data["terminal_id"] = emvTerminalId;
      }

      const res = await djangoHandleDataMutationRequest(
        "POST",
        "invoice/chargeCustomerCardOwn/",
        data
      );

      await handlePosOrderPayment();
      handleCleanPostPayment(`${t("EMV card successfully charged")}`);
    } catch (error) {
      toast.error(error);
    }
  };

  // Settle payments into folio as well
  const handleSettleFolioPayments = () => {
    if (order?.booking_id !== null) {
      if (order?.booking_id.trim().length !== 0) {
        switch (paymentType.toLowerCase()) {
          case "cash":
            handleFolioCashSettlement();
            break;
          case "card":
            handleFolioCardSettlement();
            break;
          case "wallet":
            handleFolioWalletSettlement();
            break;
          case "other":
            handleFolioOtherModePaySettlement();
            break;
        }
      } else {
        handlePosOrderPayment();
      }
    } else {
      handlePosOrderPayment();
    }
  };

  // Settling the payment
  const settlePayment = {
    id: paymentId,
    order_id: order?.id,
    order_table_id: order?.table_id,
    payment_type:
      order?.complimentary_order === "YES"
        ? "COMP"
        : String(paymentType).toUpperCase(),
    payment_sub_type:
      order?.complimentary_order === "YES"
        ? "COMP"
        : paymentType.toLowerCase() === "wallet"
        ? walletName.toUpperCase()
        : paymentType.toUpperCase(),
    settled_amount: Number(settlementAmount),
    balance_amount:
      order?.complimentary_order === "YES"
        ? 0.0
        : Number(balanceDue) - Number(settlementAmount),
    payment_status: handlePaymentStatus(),
    metadata: "",
    shop_id: order?.shop_id,
    hotel_id: hotelId,
    booking_id: order?.booking_id ? order?.booking_id : "",
    reservation_id: order?.reservation_id ? order?.reservation_id : "",
    service_discount: 0,
    amount_before_tax:
      order?.complimentary_order === "YES"
        ? 0.0
        : Number(balanceDue).toFixed(2) -
          Number(order?.tax_percentage).toFixed(2) -
          Number(order?.service_charge),
    order_tax: Number(order?.tax_percentage).toFixed(2),
    remark: settlePaymentType,
    service_charge: order?.service_charge,
  };

  // Show loader while settling the payment
  const [showSettleLoader, setShowSettleLoader] = useState(false);

  // Clean-up function after settling the payments
  // Clean-up will be called either in main function or with EMV
  // Since EMV can take time to execute
  function handleCleanPostPayment(msg) {
    setShowSettleLoader(false);
    toast.success(msg);
    if (paymentId === null) {
      if (shopType.toLowerCase() === "other") {
        history.push({
          pathname: `/outlet/${order?.shop_id}/${shopName}/${shopType}/viewOrders`,
          search: `?hotelId=${hotelId}`,
          state: { index: location.state.index + 1 },
        });
      } else {
        history.push({
          pathname: `/outlet/${order?.shop_id}/${shopName}/${shopType}/placeOrder`,
          search: `?hotelId=${hotelId}`,
          state: { index: location.state.index },
        });
      }
    } else {
      handleCloseSettlePaymentModal();
    }
  }

  // Calling the api for payment settlement
  const handlePaymentSettlemnet = () => {
    setShowSettleLoader(true);
    if (paymentType === "EMV_CARD") {
      handleChargeEmvCard();
    } else {
      handleSettleFolioPayments();
    }
  };

  // Updating the payment settlement
  // const handleUpdatePayment = () => {
  //   handleSpringDataMutationRequest(
  //     "PUT",
  //     `core/api/v1/order-payment/update`,
  //     settlePayment
  //   )
  //     .then((res) => toast.success("Balance updated successfully."))
  //     .then((res) => handleGetAllOrders())
  //     .then((res) => handleCloseSettlePaymentModal())
  //     .catch((err) => toast.error("Error in updating the payment."));
  // };

  const enablePerformEMVTxn = ["FORTIS", "TSYS", "SHIFT4", "STRIPE"].includes(
    customPgData?.active_pg_name
  );

  return (
    <Modal
      open={openSettlePaymentModal}
      onClose={handleCloseSettlePaymentModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      hideBackdrop
      disableEscapeKeyDown
    >
      <Box sx={style}>
        <Stack
          className="position-relative w-100 justify-content-between p-2"
          direction="row"
        >
          <Stack spacing={1}>
            <Stack
              direction="row"
              spacing={1}
              variant="h6"
              style={{
                fontSize: "15px",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                {t("Settle & save for")} {tableName && tableName}{" "}
              </Typography>
              <Typography
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  fontSize: "12px",
                }}
                className="mt-0 mb-0 badge badge-warning text-light"
              >
                {balanceDue}
              </Typography>
            </Stack>
            {order?.complimentary_order === "YES" && (
              <Typography className="text-warning">
                ( Complimentary order )
              </Typography>
            )}
          </Stack>

          <Typography
            variant="h6"
            style={{
              fontSize: "15px",
              cursor: "pointer",
              amrginTop: "auto",
              marginBottom: "auto",
            }}
            onClick={() => {
              handleCloseSettlePaymentModal();
              setCustomerPaid(0.0);
              handleUpdateSettlementAmount();
            }}
          >
            X
          </Typography>
        </Stack>
        <Divider />
        <Stack className="mt-2 p-5">
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              {t("Payment type")}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={paymentType}
              name="radio-buttons-group"
              className="mt-4"
              onChange={(e) => setPaymentType(e.target.value.toUpperCase())}
            >
              <Stack direction="row" spacing={3}>
                <FormControlLabel
                  value="cash"
                  control={<Radio />}
                  label={t("Cash")}
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                />
                <FormControlLabel
                  value="card"
                  control={<Radio />}
                  label={t("Offline card")}
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                />
                {enablePerformEMVTxn && (
                  <FormControlLabel
                    value="emv_card"
                    control={<Radio />}
                    label={t("EMV card")}
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  />
                )}
                {hotelCurrency?.toUpperCase() === "INR" && (
                  <FormControlLabel
                    value="wallet"
                    control={<Radio />}
                    label={t("Wallet")}
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  />
                )}
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label={t("Other")}
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                />
                {/* <FormControlLabel
                  value="part"
                  control={<Radio />}
                  label="Part"
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                /> */}
              </Stack>
            </RadioGroup>
          </FormControl>
          <Stack className="mt-4" spacing={2}>
            {paymentType.toLowerCase() === "cash" && (
              <>
                <TextField
                  id="outlined-basic"
                  label={t("Calculate amount")}
                  value={customerPaid}
                  onChange={(e) => {
                    /^[0-9]*.[0-9]*$/.test(e.target.value)
                      ? setCustomerPaid(e.target.value)
                      : setCustomerPaid("");
                  }}
                />
                <TextField
                  error
                  id="outlined-basic"
                  label={t("Return to customer")}
                  value={returnToCustomer}
                  disabled
                />
              </>
            )}
            {paymentType.toLowerCase() === "wallet" && (
              <Stack className="mt-4" spacing={4}>
                <Typography variant="h6" style={{ fontSize: "12.5px" }}>
                  {t("Select your preferred wallet")}
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ cursor: "pointer" }}
                  className="position-relative w-100 d-flex flex-wrap justify-content-start"
                >
                  {walletListIndia.map((wallet) => (
                    <Typography onClick={() => setWalletName(wallet)}>
                      <Chip
                        label={wallet}
                        variant={walletName !== wallet && "outlined"}
                      />
                    </Typography>
                  ))}
                </Stack>
              </Stack>
            )}
            {/* {paymentType.toLowerCase() === "other" && ( */}
            <TextField
              id="outlined-basic"
              label={t("Remarks")}
              value={settlePaymentType}
              onChange={(e) => setSettlePaymentType(e.target.value)}
            />
            {/* )} */}
            {paymentType.toLowerCase() !== "part" && (
              <TextField
                required
                id="outlined-basic"
                label={t("Settlement amount")}
                value={settlementAmount}
                disabled={order?.complimentary_order === "YES" ? true : false}
                onChange={(e) =>
                  /^[0-9]*.[0-9]*$/.test(e.target.value)
                    ? setSettlementAmount(e.target.value)
                    : setSettlementAmount("")
                }
                helperText={
                  Number(settlementAmount) > Number(balanceDue) ? (
                    order?.complimentary_order === "NO" && (
                      <Stack style={{ color: "red", marginTop: "2.5px" }}>
                        {" "}
                        {t(
                          "Your settlement amount is more than the balanced due. Please enter a valid amount."
                        )}
                      </Stack>
                    )
                  ) : Number(settlementAmount) < Number(balanceDue) ? (
                    <Stack>
                      {order.complimentary_order === "NO" && (
                        <Stack style={{ color: "red", marginTop: "2.5px" }}>
                          {" "}
                          {t(
                            "Your settlement amount is less than the balanced due. Click on Settle & Save button to proceed"
                          )}{" "}
                        </Stack>
                      )}
                      {[null, ""].includes(order?.cust_name) &&
                        [null, ""].includes(order?.cust_phone) &&
                        [null, ""].includes(order?.cust_email) && (
                          <Stack style={{ color: "red", marginTop: "2px" }}>
                            {t(
                              "Please enter Customer details before proceeding."
                            )}
                          </Stack>
                        )}
                    </Stack>
                  ) : (
                    ""
                  )
                }
              />
            )}
            {paymentType.toLowerCase() === "emv_card" &&
              customPgData?.hasOwnProperty("terminals") && (
                <div className="fieldWrapper mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-autowidth-label">
                      {t("Terminal Id(s)")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={emvTerminalId}
                      label={t("Terminal Id(s)")}
                      onChange={(e) => setEmvTerminalId(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {customPgData?.terminals?.map((item, idx) => (
                        <MenuItem value={item.id} key={idx}>
                          {item.terminal_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            {paymentType.toLowerCase() === "part" && (
              <Stack spacing={3} className="mt-4">
                {partPayment.map((payment, index) => (
                  <Stack
                    direction="row"
                    className="position-relative w-100 d-flex justify-content-between"
                    key={index}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "12.5px",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      {payment.subPaymentType}
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      label={t("Amount")}
                      value={payment.amount}
                      onChange={(e) => {
                        partPayment
                          .filter(
                            (pay) => String(pay.id) === String(payment.id)
                          )
                          .map((item) => {
                            return (item.amount = /^[0-9]*.[0-9]*$/.test(
                              e.target.value
                            )
                              ? Number(e.target.value)
                              : Number(payment?.amount));
                          });
                        setPartPayment([...partPayment]);
                      }}
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    />
                  </Stack>
                ))}
              </Stack>
            )}

            {inroom && (
              <p style={{ color: "red", marginTop: "1rem" }}>
                Note: Once an order is settled to a room, it cannot be modified
                or removed. Kindly verify all details carefully before
                proceeding.
              </p>
            )}
          </Stack>
        </Stack>

        <Stack
          direction="row"
          className="position-relative w-100 mt-3 d-flex justify-content-between p-5"
        >
          <SecondaryButton
            text={t("Cancel")}
            onClick={() => {
              handleCloseSettlePaymentModal();
              setCustomerPaid(Number(0.0));
              handleUpdateSettlementAmount();
            }}
          />
          <Button
            variant="custom-button"
            onClick={() =>
              showSettleLoader === false && handlePaymentSettlemnet()
            }
            disabled={
              showSettleLoader ||
              (Number(settlementAmount) > Number(balanceDue)
                ? true
                : Number(balanceDue) > Number(settlementAmount)
                ? [null, ""].includes(order?.cust_name) &&
                  [null, ""].includes(order?.cust_phone) &&
                  [null, ""].includes(order?.cust_email)
                  ? true
                  : false
                : false)
            }
          >
            {showSettleLoader ? (
              <CircularProgress size="15px" />
            ) : (
              `${t("Settle & Save")}`
            )}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

// export default SettlePayment;
function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(SettlePayment);
