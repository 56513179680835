// ** React Imports
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fab,
  Fade,
  FormControl,
  Grid,
  Grow,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Card, Modal } from "react-bootstrap";
import { makeStyles, useTheme } from "@mui/styles";
import { useFormik } from "formik";
import { isArray } from "lodash";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { shallowEqual, useSelector } from "react-redux";
import * as yup from "yup";
import { ENV } from "../../CalendarPage/constants/AmplitudeConstants";
import {
  customErrorMessage,
  dateFormat,
} from "../../CalendarPage/constants/Calendar-constants";
import {
  getAllRatePlans,
  getChannels,
  getCtaNamesNew,
  getInventoryCappingChannels,
  getRegisteredChannels,
  inventoryCapping,
  rateMultipler,
} from "../api/InventoryApi";
import { weekdays } from "../constants/InventoryConstants";
import ConfirmationModal from "./ConfirmationModal";
import amplitude from "amplitude-js";
import { handleSpringDataRequest } from "../../../api";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  textDisplay: {
    fontSize: "12px",
    marginRight: 6,
  },
  subText: {
    fontSize: "14px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const InventoryCapping = (props) => {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const hotelId = hotel.hotelId;
  const {
    selectedRoom,
    rooms,
    setLineInvisible,
    setLineVisible,
    refresh,
    componentType,
    closeFunc,
    selectedLanguage,
    roomAndPlans,
    toOpenBulkSyncReport,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const DropdownOptions = [
    { text: `${t("Set Pricing")}`, val: "set-price" },
    { text: `${t("Set Inventory")}`, val: "set-inventory" },
    { text: `${t("Rate Multiplier")}`, val: "rate-multiplier" },
    { text: `${t("Inventory Capping")}`, val: "inventory-cap" },
    { text: `${t("Set Online")}`, val: "set-online" },
    { text: `${t("Set Offline")}`, val: "set-offline" },
    { text: `${t("Block Rooms")}`, val: "block-room" },
    { text: `${t("Unblock Rooms")}`, val: "unblock-room" },
    { text: `${t("Delete Rooms")}`, val: "delete-room" },
    { text: `${t("Restore Deleted Rooms")}`, val: "restore" },
    { text: `${t("Update Rates from OTA")}`, val: "update-ota" },
    { text: `${t("Set Sold Out")}`, val: "sold-out" },
    { text: `${t("Edit Room Ids")}`, val: "edit-room" },
    { text: `${t("Reassign Room Type")}`, val: "reassign" },
    { text: `${t("Close/Open Rate Plan")}`, val: "Close Rate" },
    { text: `${t("Close/Open Room Type")}`, val: "Close Room" },
    { text: `${t("Close/Open On Arrival")}`, val: "Close On Arrival" },
    { text: `${t("Close/Open On Departure")}`, val: "Close On Departure" },
    { text: `${t("Min Length of Stay")}`, val: "Set Min Los" },
    { text: `${t("Max Length of Stay")}`, val: "Set Max Los" },
    { text: `${t("Min Advanced Booking Offset")}`, val: "Set Cutoff" },
    { text: `${t("Max Advanced Booking Offset")}`, val: "Set Max Adv Offset" },
  ];

  const [currentDateSelection, setCurrentDateSelection] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(
        new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(
          0,
          0,
          0,
          0
        )
      ),
    },
  ]);
  const [showConfirm, setShowConfirm] = useState(false);

  const [roomTypesArray, setroomTypesArray] = useState([]);
  useEffect(() => {
    const val = Object.values(rooms);

    if (componentType === "rate-multiplier") {
      setroomTypesArray(
        Object.keys(roomAndPlans)
          .map((room) => {
            if (roomAndPlans[room]?.activeDependency.toLowerCase() === "no")
              return roomAndPlans[room]?.roomTypeName;
          })
          .filter((item) => item !== undefined)
      );
      setSelectedRoomNew(
        Object.keys(roomAndPlans)
          .map((room) => {
            if (roomAndPlans[room]?.activeDependency.toLowerCase() === "no")
              return roomAndPlans[room]?.roomTypeName;
          })
          .filter((item) => item !== undefined)
      );
    } else {
      setroomTypesArray(val);
    }
  }, [rooms, roomAndPlans]);

  //Amplitude check on Bulk Operations page
  const amplitudeEventProperties = {
    hotel_id: hotel?.hotelId,
    environment: ENV,
    email: hotel?.accessControl?.email,
    role: hotel?.accessControl?.ADMINRIGHTS,
  };

  const getDisplayData = () => {
    switch (componentType) {
      case "rate-multiplier":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Room Types")} :
              </Typography>
              <Typography className={classes.subText}>
                {selectedRoomNew.join(",")}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"flex"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Rate Plans")} :
              </Typography>
              <Typography className={classes.subText} variant="body">
                {selectedRatePlan.map((rate) => rate.name).join(",")}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"flex"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {" "}
                {t("Channels")} :
              </Typography>
              <Typography className={classes.subText} variant="body">
                {selectedChannel.join(",")}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"flex"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("CTA's")} :
              </Typography>
              <Typography className={classes.subText} variant="body">
                {selectedCta.map((ct) => ct.cta_name).join(",")}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"flex"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {" "}
                {t("Multiplier Value")} :
              </Typography>
              <Typography className={classes.subText} variant="body">
                {`${formik.values.cappingValue} ${capping.label}`}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Date Ranges")} :
              </Typography>
              {currentSelection.map((data) => (
                <Typography
                  className={classes.subText}
                  key={data.id}
                  variant="body"
                >
                  {data.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        );

      case "inventory-cap":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Room Types")} :
              </Typography>
              <Typography className={classes.subText}>
                {selectedRoomNew.join(",")}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"flex"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {" "}
                {t("Channels")} :
              </Typography>
              <Typography className={classes.subText} variant="body">
                {selectedChannel.join(",")}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"flex"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("CTA's")} :
              </Typography>
              <Typography className={classes.subText} variant="body">
                {selectedCta.map((ct) => ct.cta_name).join(",")}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"flex"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {" "}
                {t("Capping Value")} :
              </Typography>
              <Typography className={classes.subText} variant="body">
                {`${formik.values.cappingValue} ${capping.label}`}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Date Ranges")} :
              </Typography>
              {currentSelection.map((data) => (
                <Typography
                  className={classes.subText}
                  key={data.id}
                  variant="body"
                >
                  {data.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        );
    }
  };

  const validationSchema = yup.object({
    currentDateSelection: yup
      .array()
      .required(
        `${t("Select a range and click on apply for atleast one range")}`
      ),
    cappingValue: yup.number().required(`${t("Value is required")}`),
  });

  const formik = useFormik({
    initialValues: { currentDateSelection: [], cappingValue: "" },
    validationSchema: validationSchema,
    onSubmit: () => {
      setShowConfirm(true);
    },
  });
  const [selectDays, setSelectDays] = useState(weekdays);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentSelection, setCurrentSelection] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cta, setCta] = useState([]);
  const [selectedCta, setSelecteCta] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ratePlans, setRatePlans] = useState([]);
  const [selectedRatePlan, setSelectedRatePlan] = useState([]);
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [selectedRoomNew, setSelectedRoomNew] = useState(Object.values(rooms));
  const [capping, setCapping] = useState({ label: "%", id: 2 });
  const newweekdays = [
    { id: "Sunday", value: `${t("Sunday")}` },
    { id: "Monday", value: `${t("Monday")}` },
    { id: "Tuesday", value: `${t("Tuesday")}` },
    { id: "Wednesday", value: `${t("Wednesday")}` },
    { id: "Thursday", value: `${t("Thursday")}` },
    { id: "Friday", value: `${t("Friday")}` },
    { id: "Saturday", value: `${t("Saturday")}` },
  ];
  const getValue = (val) => {
    const res = newweekdays.filter((item) => item.id === val)[0];
    return res.value;
  };

  useEffect(() => {
    setInitailData();
  }, [selectedRoom]);

  const setInitailData = async () => {
    setLineVisible();
    setLoading(true);
    try {
      switch (componentType) {
        case "rate-multiplier":
          await setRoomData();
          break;
        case "inventory-cap":
          await setInventoryData();
          break;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLineInvisible();
      setLoading(false);
    }
  };

  const setSubmit = async () => {
    setApiLoading(true);

    try {
      switch (componentType) {
        case "rate-multiplier":
          await setPricingFunc();
          // .then((res) => {
          // handleShow();
          // amplitude
          //   .getInstance()
          //   .logEvent(
          //     "USER PERFORMING RATE MULTIPLIER OPERATION IN BULK OPERATIONS",
          //     amplitudeEventProperties
          //   );
          // });
          break;
        case "inventory-cap":
          await setInventoryFunc();
          // .then((res) => {
          // handleShow();
          // amplitude
          //   .getInstance()
          //   .logEvent(
          //     "USER PERFORMING INVENTORY CAPPING OPERATION IN BULK OPERATIONS",
          //     amplitudeEventProperties
          //   );
          // });
          break;
      }
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.error(e);
    } finally {
      setCurrentDateSelection([
        {
          startDate: new Date(new Date().setHours(0, 0, 0, 0)),
          endDate: new Date(
            new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(
              0,
              0,
              0,
              0
            )
          ),
        },
      ]);
      formik.setFieldValue("currentDateSelection", []);
      setCurrentSelection([]);
      formik.resetForm();
      setApiLoading(false);
      setShowConfirm(false);
      refresh();
    }
  };

  const [selectedFilter, setSelectedFilter] = useState("Inventory");
  const [filterLogData, setfilterLogData] = useState([]);
  const [show, setShow] = useState(false);
  const [cmData, setcmData] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setloader] = useState(false);
  // const getAllDatas = () => {
  //   setloader(true);
  //   handleSpringDataRequest(`core/api/v1/cmLogs/getCmLogs`)
  //     .then((res) => {
  //       setcmData(res);
  //       setloader(false);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       setloader(false);
  //     });
  // };
  // useEffect(() => {
  //   if (selectedFilter === "Inventory") {
  //     setfilterLogData(cmData["inventoryLogs"]);
  //   } else if (selectedFilter === "Pricing") {
  //     setfilterLogData(cmData["rateLogs"]);
  //   } else if (selectedFilter === "Restriction") {
  //     setfilterLogData(cmData["restrictionLogs"]);
  //   }
  // }, [selectedFilter, cmData]);

  const setInventoryFunc = async () => {
    let payload = [];
    currentSelection.forEach((daterange) => {
      let val = formik.values.cappingValue.toString();
      let st = capping.label === "%" ? "%" : "V";
      val = val + "_" + st;
      let temp_obj = {
        hotelId: hotelId,
        roomTypes: selectedRoomNew,
        startDate: daterange.startDate,
        endDate: daterange.endDate,
        channels: selectedChannel,
        ctas: selectedCta.map((ct) => ct.cta_id),
        capPerc: val,
        daysIncluded: daterange.daysIncluded,
      };
      payload.push(temp_obj);
    });
    await inventoryCapping(payload).then(() => {
      toast.success(`${t("Successfully updated the inventory capping")}`);
    });
    // getAllDatas();
  };

  const setPricingFunc = async () => {
    if (selectedRoomNew && selectedRoomNew?.length === 0) {
      toast.error(`${t("Please select atleast one room type")}`);
      return;
    }
    let payload = [];
    currentSelection.forEach((daterange) => {
      let val = formik.values.cappingValue.toString();
      let st = capping.label === "%" ? "%" : "V";
      val = val + "_" + st;
      let temp_obj = {
        hotelId: hotelId,
        roomTypes: selectedRoomNew,
        ratePlans: selectedRatePlan.map((ratePlan) => ratePlan.id),
        startDate: daterange.startDate,
        endDate: daterange.endDate,
        channels: selectedChannel,
        ctas: selectedCta.map((ct) => ct.cta_id),
        value: val,
        daysIncluded: daterange.daysIncluded,
      };
      payload.push(temp_obj);
    });
    await rateMultipler(payload)
      .then(() => {
        toOpenBulkSyncReport();
      })
      .catch(() => {});

    // getAllDatas();
  };

  const setRoomData = async () => {
    const resp = await getAllRatePlans();
    setRatePlans(resp);
    setSelectedRatePlan(resp);
    const channels =
      componentType === "rate-multiplier"
        ? await getChannels()
        : await getInventoryCappingChannels();
    setChannels(channels);
    const CTA = await getCtaNamesNew();
    if (!isArray(CTA)) {
      setCta([]);
      setSelecteCta([]);
    } else {
      setCta(CTA);
      setSelecteCta([]);
    }

    setSelectedChannel(channels.filter((item) => item !== "goibibob2b"));
  };

  const setInventoryData = async () => {
    const channels =
      componentType === "rate-multiplier"
        ? await getChannels()
        : await getInventoryCappingChannels();
    setChannels(channels);
    const CTA = await getCtaNamesNew();
    if (!isArray(CTA)) {
      setCta([]);
      setSelecteCta([]);
    } else {
      setCta(CTA);
      setSelecteCta([]);
    }
    setSelectedChannel(channels.filter((item) => item !== "goibibob2b"));
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 0) {
      return;
    }
    setSelectDays(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setCurrentSelection([]);
  };
  const classes = useStyles();
  const theme = useTheme();

  const getDateText = () => {
    let val = "";
    val =
      val +
      moment(currentDateSelection[0].startDate).format("DD MMM YYYY") +
      " - ";
    val = val + moment(currentDateSelection[0].endDate).format("DD MMM YYYY");
    return val;
  };

  const handleChipDelete = (val) => {
    const arr = currentSelection.filter((chip) => chip.id !== val.id);
    setCurrentSelection(arr);
  };

  const isRoomTypeAllSelected =
    roomTypesArray.length > 0 &&
    selectedRoomNew.length === roomTypesArray.length;

  const handleRoomTypeChange = (event) => {
    // const {
    //   target: { value },
    // } = event;

    // if (value.length <= 0) {
    //   return;
    // }
    const val = event.target.value;
    if (val[val.length - 1] === "all") {
      setSelectedRoomNew(
        selectedRoomNew.length === roomTypesArray.length ? [] : roomTypesArray
      );

      return;
    }
    setSelectedRoomNew(val);
    // setSelectedChannel(
    //   // On autofill we get a the stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );
  };

  // const isRatePlanAllSelected =
  //   ratePlans.length > 0 && selectedRatePlan.length === ratePlans.length;

  // const handleRatePlanChange = (event) => {
  // const {
  //   target: { value },
  // } = event;

  // if (value.length <= 0) {
  //   return;
  // }
  // const val = event.target.value;
  // if (val[val.length - 1] === "all") {
  //   setSelectedRatePlan(
  //     selectedRatePlan.length === roomTypesArray.length ? [] : roomTypesArray
  //   );

  //   return;
  // }
  // setSelectedRatePlan(val);
  // setSelectedChannel(
  //   // On autofill we get a the stringified value.
  //   typeof value === "string" ? value.split(",") : value
  // );
  // };

  // console.log("ratePlans : ", ratePlans);
  // console.log("selectedRatePlan : ", selectedRatePlan);

  const handleSelectionChange = (event, value, reason) => {
    if (
      reason === "selectOption" &&
      value.find((v) => v.cta_name === "Select All")
    ) {
      setSelecteCta(cta);
    } else if (
      reason === "removeOption" &&
      value.find((v) => v.cta_name === "Select All")
    ) {
      setSelecteCta([]);
    } else if (
      reason === "selectOption" &&
      value.find((v) => v.cta_name === "Deselect All")
    ) {
      setSelecteCta([]);
    } else {
      setSelecteCta(
        value.filter(
          (v) => v.cta_name !== "Select All" && v.cta_name !== "Deselect All"
        )
      );
    }
  };

  const extendedOptions = [
    { cta_name: "Select All" },
    { cta_name: "Deselect All" },
    ...cta,
  ];

  const handleSelectionChangeForSource = (event, value, reason) => {
    if (reason === "selectOption" && value.find((v) => v === "Select All")) {
      setSelectedChannel(channels);
    } else if (
      reason === "selectOption" &&
      value.find((v) => v === "Deselect All")
    ) {
      setSelectedChannel([]);
    } else if (
      reason === "removeOption" &&
      value.find((v) => v === "Select All")
    ) {
      setSelectedChannel([]);
    } else {
      setSelectedChannel(
        value.filter((v) => v !== "Select All" && v !== "Deselect All")
      );
    }
  };

  const extendedOptionsforSource = ["Select All", "Deselect All", ...channels];
  return (
    <Grow in timeout={1000} style={{ transformOrigin: "0 0 0" }}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container>
          {!loading && (
            <Grid container className={classes.textField} spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple">{t("Room Types")}</InputLabel>
                  <Select
                    multiple
                    labelId="demo-simple-romm"
                    id="multiple-roomtypes"
                    value={selectedRoomNew}
                    onChange={handleRoomTypeChange}
                    label={t("Room Types")}
                    MenuProps={MenuProps}
                    input={
                      <OutlinedInput
                        id="multiple-roomtypes"
                        label={t("Room Types")}
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            variant="outlined"
                            color="primary"
                            key={value}
                            label={value}
                            style={{
                              fontWeight: "bold",
                              fontSize: "1rem",
                              border: "1px solid black",
                              color: "black",
                            }}
                          />
                        ))}
                      </Box>
                    )}
                    // onChange={(eve) => {
                    //   if (eve.target.value.length <= 0) {
                    //     return;
                    //   }
                    //   setSelectedRoomNew(eve.target.value);
                    // }}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isRoomTypeAllSelected ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: classes.indeterminateColor,
                          }}
                          checked={isRoomTypeAllSelected}
                          indeterminate={
                            selectedRoomNew.length > 0 &&
                            selectedRoomNew.length < roomTypesArray.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary={t("Select All")}
                      />
                    </MenuItem>
                    {roomTypesArray.map((roomtype) => (
                      <MenuItem key={roomtype} value={roomtype}>
                        <ListItemIcon>
                          <Checkbox
                            checked={selectedRoomNew.indexOf(roomtype) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={roomtype} />
                        {/* {roomtype} */}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {componentType === "rate-multiplier" && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple">{t("Rate Plans")}</InputLabel>
                    <Select
                      multiple
                      labelId="demo-simple"
                      id="multiple-rateplans"
                      value={selectedRatePlan}
                      label={t("Rate Plans")}
                      MenuProps={MenuProps}
                      input={
                        <OutlinedInput
                          id="multiple-rateplans"
                          label={t("Rate Plans")}
                        />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip
                              variant="outlined"
                              color="primary"
                              key={value.id}
                              label={value.displayName}
                              style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                border: "1px solid black",
                                color: "black",
                              }}
                            />
                          ))}
                        </Box>
                      )}
                      onChange={(eve) => {
                        if (eve.target.value.length <= 0) {
                          return;
                        }
                        setSelectedRatePlan(eve.target.value);
                      }}
                    >
                      {ratePlans.map((ratePlan) => (
                        <MenuItem key={ratePlan.id} value={ratePlan}>
                          {ratePlan.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="days-multiple-chip-label">
                    {t("Days")}
                  </InputLabel>
                  <Select
                    labelId="days-multiple-chip-label"
                    id="days-multiple-chip"
                    multiple
                    value={selectDays}
                    onChange={handleChange}
                    input={
                      <OutlinedInput id="day-multiple-chip" label="Days" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            variant="outlined"
                            color="primary"
                            key={value}
                            label={getValue(value)}
                            style={{
                              fontWeight: "bold",
                              fontSize: "1rem",
                              border: "1px solid black",
                              color: "black",
                            }}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {newweekdays.map((name) => (
                      <MenuItem
                        key={name.id}
                        value={name.id}
                        style={getStyles(name, selectDays, theme)}
                      >
                        {name.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <ClickAwayListener
                    onClickAway={() => {
                      setShowDatePicker(false);
                      setAnchorEl(null);
                    }}
                  >
                    <div>
                      <TextField
                        fullWidth
                        focused={showDatePicker}
                        required
                        InputProps={{ readOnly: true }}
                        value={getDateText()}
                        label={t("Date range")}
                        id="currentDateSelection"
                        name="currentDateSelection"
                        error={
                          formik.touched.currentDateSelection &&
                          Boolean(formik.errors.currentDateSelection)
                        }
                        helperText={
                          formik.touched.currentDateSelection &&
                          formik.errors.currentDateSelection
                        }
                        onClick={(eve) => {
                          setAnchorEl(eve.currentTarget);
                          setShowDatePicker(true);
                        }}
                      ></TextField>
                      {showDatePicker && (
                        <Popper
                          style={{ zIndex: 2 }}
                          open={showDatePicker}
                          anchorEl={anchorEl}
                          placement="bottom"
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              style={{
                                transformOrigin: (placement = "bottom-end"),
                              }}
                              {...TransitionProps}
                            >
                              <Paper
                                elevation={5}
                                className="inventory-daterange-custom"
                              >
                                <DateRange
                                  color="#1BC5BD"
                                  rangeColors={["#1BC5BD"]}
                                  editableDateInputs={false}
                                  minDate={new Date()}
                                  moveRangeOnFirstSelection={false}
                                  onChange={(data) => {
                                    setCurrentDateSelection([
                                      { ...data.range1 },
                                    ]);
                                  }}
                                  ranges={currentDateSelection}
                                ></DateRange>
                                <Divider />
                                <Grid
                                  container
                                  item
                                  sx={{
                                    padding: 1,
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    sx={{ marginRight: 1 }}
                                    onClick={() => {
                                      setShowDatePicker(false);
                                    }}
                                    color="error"
                                  >
                                    {t("Cancel")}
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      currentSelection.push({
                                        text: getDateText(),
                                        id: currentSelection.length + 1,
                                        datesText: selectDays.join(","),
                                        startDate: moment(
                                          currentDateSelection[0].startDate
                                        ).format(dateFormat),
                                        endDate: moment(
                                          currentDateSelection[0].endDate
                                        ).format(dateFormat),
                                        daysIncluded: selectDays,
                                      });
                                      formik.setFieldValue(
                                        "currentDateSelection",
                                        currentSelection
                                      );
                                      setCurrentSelection(currentSelection);
                                      setShowDatePicker(false);
                                    }}
                                    color="primary"
                                  >
                                    {t("Apply")}
                                  </Button>
                                </Grid>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      )}
                    </div>
                  </ClickAwayListener>
                </div>
              </Grid>
              {currentSelection.length > 0 && (
                <Fade in={currentSelection.length > 0}>
                  <Grid item xs={12}>
                    <InputLabel>{t("Current Selections")}</InputLabel>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                      {currentSelection.map((data) => {
                        return (
                          <Chip
                            sx={{
                              width: "auto",
                              height: "auto",
                              fontWeight: "bold",
                              border: "1px solid black",
                              color: "black",
                            }}
                            variant="outlined"
                            color="primary"
                            onDelete={(eve) => {
                              handleChipDelete(data);
                            }}
                            label={
                              <div>
                                <Typography
                                  sx={{ padding: 0.5, fontWeight: "bold" }}
                                >
                                  {" "}
                                  {data.text}
                                </Typography>
                                <Divider />
                                <Typography
                                  sx={{
                                    padding: 0.5,
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                    fontWeight: "bold",
                                  }}
                                  display="inline-block"
                                >
                                  {" "}
                                  {data.datesText}
                                </Typography>
                              </div>
                            }
                            key={data.id}
                          />
                        );
                      })}
                    </Box>
                  </Grid>
                </Fade>
              )}
              <Grid item spacing={2} container>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Autocomplete
                    fullWidth
                    disableClearable
                    id="combo-box"
                    multiple
                    ChipProps={{
                      variant: "outlined",
                      sx: {
                        // backgroundColor: "black", // Set the chip background color to black
                        color: "black", // Ensure text is visible
                        fontWeight: "bold",
                        border: "1px solid black",
                      },
                    }}
                    options={extendedOptionsforSource}
                    value={selectedChannel}
                    onChange={handleSelectionChangeForSource}
                    renderInput={(params) => (
                      <TextField {...params} label="Source" />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <MenuItem {...props}>
                        <Checkbox
                          checked={
                            option === "Select All"
                              ? selectedChannel.length === channels.length
                              : option === "Deselect All"
                              ? selectedChannel.length === 0
                              : selected
                          }
                          indeterminate={
                            option === "Select All" &&
                            selectedChannel.length > 0 &&
                            selectedChannel.length < channels.length
                          }
                        />
                        <ListItemText primary={option} />
                      </MenuItem>
                    )}
                  />
                </Grid>
                {cta?.length > 0 && (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Autocomplete
                      fullWidth
                      id="combo"
                      multiple
                      options={extendedOptions}
                      getOptionLabel={(option) => option.cta_name}
                      isOptionEqualToValue={(option, value) =>
                        option.cta_name === value.cta_name
                      }
                      onChange={handleSelectionChange}
                      value={selectedCta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Direct Billing Accounts"
                        />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <MenuItem {...props}>
                          <Checkbox
                            checked={
                              option.cta_name === "Select All"
                                ? selectedCta.length === cta.length
                                : option.cta_name === "Deselect All"
                                ? selectedCta.length === 0
                                : selected
                            }
                            indeterminate={
                              option.cta_name === "Select All" &&
                              selectedCta.length > 0 &&
                              selectedCta.length < cta.length
                            }
                          />
                          <ListItemText primary={option.cta_name} />
                        </MenuItem>
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item container spacing={1} justifyContent="flex-start">
                <Grid item xs={9} sm={8} md={8} lg={6} xl={6}>
                  <TextField
                    id="cappingValue"
                    name="cappingValue"
                    error={
                      formik.touched.cappingValue &&
                      Boolean(formik.errors.cappingValue)
                    }
                    helperText={
                      formik.touched.cappingValue && formik.errors.cappingValue
                    }
                    value={formik.values.cappingValue}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                    label={
                      componentType === "inventory-cap"
                        ? `${t("Cap Value")}`
                        : `${t("Set Multiplier")}`
                    }
                    type="number"
                  ></TextField>
                </Grid>
                {componentType === "inventory-cap" && (
                  <Grid item xs={3} sm={4} md={4} lg={3} xl={3}>
                    <Autocomplete
                      fullWidth
                      value={capping}
                      onChange={(eve, data) => {
                        setCapping(data);
                      }}
                      id="price-percentage"
                      disableClearable
                      options={[
                        { label: "count", id: 1 },
                        { label: "%", id: 2 },
                      ]}
                      renderInput={(params) => (
                        <TextField {...params} label={t("type")} />
                      )}
                      isOptionEqualToValue={(opt, val) => opt.id === val.id}
                    />
                  </Grid>
                )}
                {componentType === "rate-multiplier" && (
                  <Grid item xs={3} sm={4} md={4} lg={3} xl={3}>
                    <Autocomplete
                      fullWidth
                      value={capping}
                      onChange={(eve, data) => {
                        setCapping(data);
                      }}
                      id="price-percentage"
                      disableClearable
                      isOptionEqualToValue={(opt, val) => opt.id === val.id}
                      options={[
                        {
                          label: hotel.accessControl.hotel.hotel_currency,
                          id: 1,
                        },
                        { label: "%", id: 2 },
                      ]}
                      renderInput={(params) => (
                        <TextField {...params} label={t("type")} />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {!loading && (
            <Grid container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                item
                className={classes.textField}
                justifyContent="flex-end"
                container
              >
                <Fab
                  type="submit"
                  disabled={apiLoading}
                  variant="extended"
                  color="primary"
                >
                  {componentType === "rate-multiplier" &&
                    `${t("Apply Multiplier")}`}
                  {componentType === "inventory-cap" && `${t("Apply Capping")}`}
                </Fab>
              </Grid>
            </Grid>
          )}
          <Modal
            show={show}
            onHide={handleClose}
            style={{ zIndex: "3000" }}
            dialogClassName={"myModalBodysxsse"}
            contentClassName={"myModalContent"}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {t("Inventory, pricing and restriction logs")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{
                  display: "flex",
                  fontSize: "0.9rem",
                  marginTop: "1rem",
                  justifyContent: "space-between",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{
                    marginLeft: "1rem",
                    border: "1px solid #DDDDDD",
                    padding: "5px",
                    borderRadius: "25px",
                    width: "9rem",
                    textAlign: "center",
                    backgroundColor:
                      selectedFilter === "Inventory" && "#1AB393",
                    cursor: "pointer",
                    color: selectedFilter === "Inventory" && "#fff",
                  }}
                  onClick={() => setSelectedFilter("Inventory")}
                >
                  {t("Inventory")}
                </div>

                <div
                  style={{
                    marginLeft: "1rem",
                    border: "1px solid #DDDDDD",
                    padding: "5px",
                    borderRadius: "25px",
                    width: "9rem",
                    textAlign: "center",
                    backgroundColor: selectedFilter === "Pricing" && "#1AB393",
                    cursor: "pointer",
                    color: selectedFilter === "Pricing" && "#fff",
                  }}
                  onClick={() => setSelectedFilter("Pricing")}
                >
                  {t("Pricing")}
                </div>
                <div
                  style={{
                    marginLeft: "1rem",
                    border: "1px solid #DDDDDD",
                    padding: "5px",
                    borderRadius: "25px",
                    width: "9rem",
                    textAlign: "center",
                    backgroundColor:
                      selectedFilter === "Restriction" && "#1AB393",
                    cursor: "pointer",
                    color: selectedFilter === "Restriction" && "#fff",
                  }}
                  onClick={() => setSelectedFilter("Restriction")}
                >
                  {t("Restriction")}
                </div>
              </div>

              {filterLogData?.map((e) => (
                <Card
                  className="mb-3"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  }}
                >
                  <Card.Body>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex flex-column">
                        <h5>{t("Room name")}</h5>
                        <p>{e.roomName}</p>
                      </div>
                      <div className="d-flex flex-column">
                        <h5>{t("Date")}</h5>
                        <p>{e.dateRange}</p>
                      </div>
                      <div className="d-flex flex-column">
                        <h5>{t("OTAs")}</h5>
                        <p>{e.channel}</p>
                      </div>
                      <div className="d-flex flex-column">
                        <h5>{t("Status")}</h5>
                        <p>{e.status}</p>
                      </div>
                      <div className="d-flex flex-column">
                        <h5> {t("Updated on OTA")}</h5>
                        <p>{e.updatedTime}</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {t("Close")}
              </Button>
              <Button variant="custom-button">
                {loader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Refresh")}`
                )}
              </Button>
            </Modal.Footer>
          </Modal>
          <ConfirmationModal
            open={showConfirm}
            onClose={() => {
              setShowConfirm(false);
            }}
            onBackdropClick={closeFunc}
            title={
              DropdownOptions.find((item) => item.val === componentType).text
            }
            loading={apiLoading}
            displayData={showConfirm ? getDisplayData() : null}
            confirmAction={setSubmit}
          ></ConfirmationModal>
        </Grid>
      </form>
    </Grow>
  );
};

export default InventoryCapping;
