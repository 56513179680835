import React, { Fragment, useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import {
  readableTimeConverter,
  validateAddress,
  handleFullAddString,
} from "../../Utils/Utils";
import axios from "axios";
import { getBookingPaymentStatus, getMapForTheRatePlans } from "./FolioUtils";
import { unionTerritories } from "../../Utils/UnionTerritories";
import i18n from "../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";
import { handleSpringDataRequest } from "../../api";

const borderColor = "#E5e5e5";

// formatting the date
const formatDate = (date) => {
  return moment(date).format("MMM DD YYYY hh:mm A");
};

// rounding of the digits
function handleDigits(num) {
  let formattedNum = parseFloat(num);
  if (isNaN(formattedNum)) {
    return 0.0;
  } else {
    return Math.abs(formattedNum).toFixed(2);
  }
}

const InvoiceDualText = ({
  boldText,
  normalText,
  style,
  boldTextStyle,
  customDetails,
  enableNewLine,
  propertyState,
  utgst,
}) => {
  return (
    <View style={styles.dualText}>
      <Text
        style={[
          styles.boldText,
          {
            color: customDetails?.content_color,
            fontSize: customDetails?.content_size,
          },
          boldTextStyle && boldTextStyle,
        ]}
      >
        {boldText === "SGST"
          ? unionTerritories?.includes(propertyState) &&
            utgst?.status === "ENABLED"
            ? "UTGST"
            : "SGST"
          : boldText}
        :
      </Text>
      <Text
        style={[
          styles.normalText,
          {
            color: customDetails?.content_color,
            fontSize: customDetails?.content_size,
          },
          style && style,
        ]}
      >
        {enableNewLine && normalText.length > 42
          ? normalText.substring(0, 42) +
          "-" +
          "\n" +
          normalText.substring(42, normalText.length)
          : normalText}
      </Text>
    </View>
  );
};

// Tax percentage formatter
const taxPercentageFormatter = (item) => {
  let taxPercent = 0;
  if (Math.abs(item?.service_amount) != 0) {
    taxPercent =
      Number(Math.abs(item?.service_tax)) /
      Number(Math.abs(item?.service_amount));
    taxPercent = taxPercent * 100;
  } else {
    taxPercent = 0;
  }
  return Number(taxPercent).toFixed(2);
};

// get the image extension
const getExtension = (img) => {
  if (img?.charAt(0) === "/") {
    return `data:image/jpeg;base64, ${img}`;
  } else if (img?.charAt(0) === "i") {
    return `data:image/png;base64, ${img}`;
  } else if (img?.charAt(0) === "U") {
    return `data:image/webp;base64, ${img}`;
  } else if (img?.charAt(0) === "R") {
    return `data:image/gif;base64, ${img}`;
  }
};

// Handling customer address
const handleCustomerAddress = (address) => {
  let formattedAddress = "";

  Object.values(address).map(function (add) {
    if (validateAddress(add)) {
      formattedAddress += add + ", ";
    }
  });

  const lastCommaIndex = formattedAddress.lastIndexOf(", ");
  const newFormattedAddress = formattedAddress.substring(0, lastCommaIndex);
  return newFormattedAddress;
};

// handle invoice header
const handleInvoiceHeader = (customFolioConfig) => {
  if (
    customFolioConfig?.booking_invoice_header &&
    !customFolioConfig?.booking_invoice_header
      .toLowerCase()
      .includes("null", "n/a")
  ) {
    return customFolioConfig?.booking_invoice_header;
  } else {
    return "Folio";
  }
};

// Check if the data is not null or includes n/a
const checkInvoiceData = (data) => {
  if (data === null) {
    return "";
  } else if (String(data).toLowerCase().startsWith("null")) {
    return "";
  } else if (String(data).toLowerCase().startsWith("n/a")) {
    return "";
  } else {
    return String(data);
  }
};

const handleCustomBookingId = (invoice, customFolioConfig) => {
  if (
    customFolioConfig?.generate_cust_invoice_cancel_noshow === "YES" &&
    (invoice?.status === "NO_SHOW" || invoice?.status === "CANCELLED")
  ) {
    return String(invoice?.custom_invoice_id);
  }
  if (invoice?.status === "CHECKED_OUT") {
    // return (
    //   String(checkInvoiceData(customFolioConfig.cust_booking_invoice_prefix)) +
    //   String(checkInvoiceData(customFolioConfig?.cust_booking_seq_start)) +
    //   String(checkInvoiceData(customFolioConfig?.cust_booking_invoice_suffix))
    // );
    return String(invoice?.custom_invoice_id);
  } else {
    return "";
  }
};

const handleGetBillToCta = (invoice, summaryData) => {
  let adjustedAmt = 0;
  let finalAmt = 0;
  if (invoice?.cta_billed_items.length > 0) {
    const data = invoice.cta_billed_items?.forEach(
      (item) => (adjustedAmt += item?.refund_amount)
    );
    finalAmt = Number(summaryData?.bill_to_cta_amt) - Number(adjustedAmt);
  } else {
    finalAmt = 0;
  }
  return parseFloat(finalAmt).toFixed(2);
};

const handleTotalRefund = (invoice, summaryData) => {
  let refund = 0;
  let adjustedAmt = 0;
  const data = invoice?.cta_billed_items?.forEach(
    (item) => (adjustedAmt += item?.refund_amount)
  );
  if (invoice?.cta_billed_items.length > 0) {
    refund = Number(summaryData?.refund) - Number(adjustedAmt);
  } else {
    refund = Number(summaryData?.refund) - Number(summaryData?.bill_to_cta_amt);
  }
  return Number(refund).toFixed(2);
};

function AdvInvoiceDoc({
  advInvoice,
  invoice,
  hotelData,
  symbol,
  customDetails,
  hotelLogo,
  summaryData,
  customerIdentification,
  ctaDetails,
  billToPref,
  custCompanyDetails,
  ifShowNewCancellationPolicy,
  igst,
  dbigst,
  customerState,
  propertyState,
  selectedLanguage,
  folioConfig,
  isTTDCProperty,
  paymentRefId,
  folioLogs,
  hotelEmail,
  folioHeading,
  otaBookingInfoData,
}) {
  const [utgst, setUtgst] = useState(null);

  useEffect(() => {
    handleSpringDataRequest("core/api/v1/utgst-config/get")
      .then((data) => {
        setUtgst(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  };

  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const InvoiceTableHeader = ({ customDetails, symbol, propertyState }) => {
    return (
      <View style={styles.headerContainer}>
        <Text
          style={[
            styles.infoRow,
            {
              width: "40%",
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
            },
          ]}
        >
          {t("Date")} {"&"} {t("Description")}
        </Text>
        <Text
          style={[
            styles.infoRow,
            {
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
            },
          ]}
        >
          {t("Type")}
        </Text>
        {["Rs.", "rs.", "INR", "inr"].includes(hotelCurrency) &&
          folioConfig?.display_hsn_code_invoice === "YES" && (
            <Text
              style={[
                styles.infoRow,
                {
                  color: customDetails?.content_color,
                  fontSize: customDetails?.content_size,
                },
              ]}
            >
              {t("HSN / SAC code")}
            </Text>
          )}
        <Text
          style={[
            styles.infoRow,
            {
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
            },
          ]}
        >
          {t("Sub-total")}
        </Text>
        <Text
          style={[
            styles.infoRow,
            {
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
            },
          ]}
        >
          {t("Tax %")}
        </Text>
        <Text
          style={[
            styles.infoRow,
            {
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
            },
          ]}
        >
          {t("Tax")}
        </Text>
        {(igst?.status === "ENABLED" &&
          customerState !== "NA" &&
          customerState !== propertyState) ||
          dbigst?.status === "ENABLED" ? (
          ["Rs.", "rs.", "INR", "inr"].includes(symbol) && (
            <Text
              style={[
                styles.infoRow,
                {
                  color: customDetails?.content_color,
                  fontSize: customDetails?.content_size,
                },
              ]}
            >
              {t("IGST")}
            </Text>
          )
        ) : (
          <>
            {["Rs.", "rs.", "INR", "inr"].includes(symbol) && (
              <Text
                style={[
                  styles.infoRow,
                  {
                    color: customDetails?.content_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {t("CGST")}
              </Text>
            )}
            {["Rs.", "rs.", "INR", "inr"].includes(symbol) && (
              <Text
                style={[
                  styles.infoRow,
                  {
                    color: customDetails?.content_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {unionTerritories?.includes(propertyState) &&
                  utgst?.status === "ENABLED"
                  ? "UTGST"
                  : "SGST"}
              </Text>
            )}
          </>
        )}
        <Text
          style={[
            styles.infoRow,
            {
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
            },
          ]}
        >
          {t("Total")}
        </Text>
      </View>
    );
  };

  const InvoiceTaxBreakdownHeader = ({ customDetails }) => (
    <View style={styles.headerContainer}>
      <Text
        style={[
          [
            styles.infoRow,
            {
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
            },
          ],
          { width: "50%" },
        ]}
      >
        {t("Tax Name")}
      </Text>
      <Text
        style={[
          [
            styles.infoRow,
            {
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
            },
          ],
          { width: "50%" },
        ]}
      >
        {t("Tax Amount")}
      </Text>
    </View>
  );

  const InvoiceFeeBreakdownHeader = ({ customDetails }) => (
    <View style={styles.headerContainer}>
      <Text
        style={[
          [
            styles.infoRow,
            {
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
              width: "50%",
            },
          ],
        ]}
      >
        {t("Fee Name")}
      </Text>
      <Text
        style={[
          [
            styles.infoRow,
            {
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
            },
          ],
          { width: "50%" },
        ]}
      >
        {t("Fee Amount")}
      </Text>
    </View>
  );

  const hotelCurrency = localStorage.getItem("hotelCurrency");
  const [convertedAmount, setconvertedAmount] = useState(0);

  const [hotelConvertedCurrency, sethotelConvertedCurrency] = useState(
    localStorage.getItem("hotelCurrency")
  );
  const [hotelConvertedCurrencySymbol, sethotelConvertedCurrencySymbol] =
    useState(localStorage.getItem("symbol"));

  const getConvertedvalue = async () => {
    if (
      sessionStorage.getItem("defaultRate") === 0 &&
      sessionStorage.getItem("defaultRate") === undefined &&
      sessionStorage.getItem("defaultRate") === null
    ) {
      const data = await axios.get(
        `${process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}`
      );
      setconvertedAmount(data.data);
    } else {
      const data = await axios.get(
        `${process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}&amount=${sessionStorage.getItem("defaultRate")}`
      );
      setconvertedAmount(data.data);
    }
    symbol =
      hotelCurrency === hotelConvertedCurrency
        ? symbol
        : hotelConvertedCurrencySymbol;
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("convertedHotelCurrency") != null &&
      sessionStorage.getItem("convertedHotelCurrency") !== undefined
    ) {
      sethotelConvertedCurrency(
        sessionStorage.getItem("convertedHotelCurrency")
      );
      sethotelConvertedCurrencySymbol(
        sessionStorage.getItem("convertedHotelCurrencySymbol")
      );
      if (
        hotelCurrency !== "" &&
        hotelCurrency !== undefined &&
        hotelCurrency !== null &&
        sessionStorage.getItem("convertedHotelCurrency") !== "" &&
        sessionStorage.getItem("convertedHotelCurrency") !== undefined &&
        sessionStorage.getItem("convertedHotelCurrency") !== null
      ) {
        getConvertedvalue();
      }
    }
  }, [
    sessionStorage.getItem("convertedHotelCurrency"),
    sessionStorage.getItem("defaultRate"),
  ]);

  function addSpaceAfterFiveChars(str) {
    let newStr = "";
    for (let i = 0; i < str?.length; i++) {
      newStr += str[i];
      // Add a space after every 5 characters, but not at the end of the string
      if ((i + 1) % 7 === 0 && i + 1 !== str?.length) {
        newStr += " ";
      }
    }
    return newStr;
  }

  /* New logic to generate the invoice table row */

  const InvoiceTableRow = ({ items, symbol, bookingFees }) => {
    let rowItems = [];
    if (bookingFees > 0) {
      let bookingItem = {
        service_id: "-2",
        service_amount: Number(invoice?.total_fees),
        service_tax: "-",
        service_total: Number(invoice?.total_fees),
        service_date: moment(invoice?.checkin, "YYYY-MM-DDTHH:mm").format(
          "MMM DD YYYY"
        ),
        pos_order_id: "",
        service_desc: "Booking fees",
        service_amount_type: "DEBIT",
      };
      rowItems = [...items, bookingItem];
    } else {
      rowItems = [...items];
    }
    const rows = rowItems.map((item, index) => (
      <View style={styles.row} key={index}>
        {/* Service description */}
        <View style={[styles.infoRow, { width: "40%" }]}>
          <Text
            style={{
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
            }}
          >
            {item?.service_date}
          </Text>
          <Text
            style={{
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
            }}
          >
            {item?.service_desc}
          </Text>
        </View>

        {/* Service amount type */}

        <Text
          style={[
            styles.infoRow,
            {
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
            },
          ]}
        >
          {item.service_amount_type}
        </Text>
        {["Rs.", "rs.", "INR", "inr"].includes(hotelCurrency) &&
          folioConfig?.display_hsn_code_invoice === "YES" && (
            <Text
              style={[
                styles.infoRow,
                {
                  color: customDetails?.content_color,
                  fontSize: customDetails?.content_size,
                },
              ]}
            >
              {addSpaceAfterFiveChars(item.hsn_code)}
            </Text>
          )}

        {/* Subtotal */}
        {hotelCurrency === hotelConvertedCurrency && (
          <Text
            style={[
              styles.infoRow,
              {
                color: customDetails?.content_color,
                fontSize: customDetails?.content_size,
              },
            ]}
          >
            {symbol + "" + handleDigits(Math.abs(item.service_amount))}
          </Text>
        )}
        {hotelCurrency !== hotelConvertedCurrency && (
          <Text
            style={[
              styles.infoRow,
              {
                color: customDetails?.content_color,
                fontSize: customDetails?.content_size,
              },
            ]}
          >
            {symbol +
              "" +
              handleDigits(Math.abs(item.service_amount) * convertedAmount)}
          </Text>
        )}

        {/* Tax percentage */}
        <Text
          style={[
            styles.infoRow,
            {
              color: customDetails?.content_color,
              fontSize: customDetails?.content_size,
            },
          ]}
        >
          {!isNaN(taxPercentageFormatter(item))
            ? taxPercentageFormatter(item)
            : "-"}
        </Text>

        {/* Total amount */}
        {hotelCurrency === hotelConvertedCurrency && (
          <Text
            style={[
              styles.infoRow,
              {
                color: customDetails?.content_color,
                fontSize: customDetails?.content_size,
              },
            ]}
          >
            {symbol + "" + handleDigits(Math.abs(item.service_tax))}
          </Text>
        )}

        {hotelCurrency !== hotelConvertedCurrency && (
          <Text
            style={[
              styles.infoRow,
              {
                color: customDetails?.content_color,
                fontSize: customDetails?.content_size,
              },
            ]}
          >
            {symbol +
              "" +
              isNaN(handleDigits(Math.abs(item.service_tax) * convertedAmount))
              ? "-"
              : handleDigits(Math.abs(item.service_tax) * convertedAmount)}
          </Text>
        )}
        {["Rs.", "rs.", "INR", "inr"].includes(hotelCurrency) &&
          ((igst?.status === "ENABLED" &&
            customerState !== "NA" &&
            customerState !== propertyState) ||
            dbigst?.status === "ENABLED" ? (
            hotelCurrency === hotelConvertedCurrency ? (
              <Text
                style={[
                  styles.infoRow,
                  {
                    color: customDetails?.content_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {symbol + "" + handleDigits(Math.abs(item.service_tax))} (
                {!isNaN(taxPercentageFormatter(item))
                  ? taxPercentageFormatter(item)
                  : "-"}
                %)
              </Text>
            ) : (
              <Text
                style={[
                  styles.infoRow,
                  {
                    color: customDetails?.content_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {symbol +
                  "" +
                  isNaN(
                    handleDigits(Math.abs(item.service_tax) * convertedAmount)
                  )
                  ? "-"
                  : handleDigits(
                    Math.abs(item.service_tax) * convertedAmount
                  )}{" "}
                (
                {!isNaN(taxPercentageFormatter(item))
                  ? taxPercentageFormatter(item)
                  : "-"}
                %)
              </Text>
            )
          ) : (
            <>
              {["Rs.", "rs.", "INR", "inr"].includes(hotelCurrency) &&
                (!isNaN(Number(Math.abs(item?.service_tax))) ? (
                  <Text
                    style={[
                      styles.infoRow,
                      {
                        color: customDetails?.content_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {symbol}{" "}
                    {isNaN(Math.abs(item?.service_tax) / 2)
                      ? "-"
                      : handleDigits(
                        Math.abs(
                          hotelCurrency === hotelConvertedCurrency
                            ? item?.service_tax
                            : item?.service_tax * hotelConvertedCurrency
                        ) / 2
                      )}{" "}
                    (
                    {!isNaN(taxPercentageFormatter(item)) &&
                      Math.abs(taxPercentageFormatter(item) / 2).toFixed(2) +
                      "%"}
                    )
                  </Text>
                ) : (
                  <Text
                    style={[
                      styles.infoRow,
                      {
                        color: customDetails?.content_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {" "}
                  </Text>
                ))}

              {["Rs.", "rs.", "INR", "inr"].includes(hotelCurrency) &&
                (!isNaN(Number(Math.abs(item?.service_tax))) ? (
                  <Text
                    style={[
                      styles.infoRow,
                      {
                        color: customDetails?.content_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {symbol}{" "}
                    {isNaN(Math.abs(item?.service_tax) / 2)
                      ? "-"
                      : handleDigits(
                        Math.abs(
                          hotelCurrency === hotelConvertedCurrency
                            ? item?.service_tax
                            : item?.service_tax * hotelConvertedCurrency
                        ) / 2
                      )}{" "}
                    (
                    {!isNaN(taxPercentageFormatter(item)) &&
                      Math.abs(taxPercentageFormatter(item) / 2).toFixed(2) +
                      "%"}
                    )
                  </Text>
                ) : (
                  <Text
                    style={[
                      styles.infoRow,
                      {
                        color: customDetails?.content_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {" "}
                  </Text>
                ))}
            </>
          ))}

        {hotelCurrency === hotelConvertedCurrency && (
          <Text
            style={[
              styles.infoRow,
              {
                color: customDetails?.content_color,
                fontSize: customDetails?.content_size,
              },
            ]}
          >
            {symbol + "" + handleDigits(Math.abs(item?.service_total))}
          </Text>
        )}
        {hotelCurrency !== hotelConvertedCurrency && (
          <Text
            style={[
              styles.infoRow,
              {
                color: customDetails?.content_color,
                fontSize: customDetails?.content_size,
              },
            ]}
          >
            {symbol +
              "" +
              handleDigits(Math.abs(item?.service_total) * convertedAmount)}
          </Text>
        )}
      </View>
    ));
    return <Fragment>{rows}</Fragment>;
  };

  /* Old logic to generate the invoice table row */

  // const InvoiceTableRow = ({ items, symbol }) => {
  //   const rows = items.map((item, index) => (
  //     <View style={styles.row} key={index}>
  //       <View style={[styles.description]}>
  //         <Text style={[{ marginBottom: 3, fontSize: "9px" }]}>
  //           {item?.service_date}
  //         </Text>
  //         <Text
  //           style={[
  //             {
  //               fontSize: "9px",
  //               marginBottom: 5,
  //               display: "flex",
  //               flexWrap: "wrap",
  //               width: "75%",
  //             },
  //           ]}
  //         >
  //           {item?.service_desc}
  //         </Text>
  //       </View>
  //       <Text style={[styles.qty, { fontSize: "9px", marginTop: 5.5 }]}>
  //         {item.service_amount_type}
  //       </Text>
  //       {hotelCurrency === hotelConvertedCurrency && (
  //         <Text style={[styles.rate, { fontSize: "9px" }]}>
  //           {symbol + "" + handleDigits(Math.abs(item.service_amount))}
  //         </Text>
  //       )}

  //       {hotelCurrency !== hotelConvertedCurrency && (
  //         <Text style={[styles.rate, { fontSize: "9px" }]}>
  //           {symbol +
  //             "" +
  //             handleDigits(Math.abs(item.service_amount) * convertedAmount)}
  //         </Text>
  //       )}
  //       <Text style={[styles.rate, { fontSize: "9px", marginTop: 5.5 }]}>
  //         {taxPercentageFormatter(item)}
  //       </Text>
  //       {hotelCurrency === hotelConvertedCurrency && (
  //         <Text style={[styles.rate, { fontSize: "9px" }]}>
  //           {symbol + "" + handleDigits(Math.abs(item.service_tax))}
  //         </Text>
  //       )}

  //       {hotelCurrency !== hotelConvertedCurrency && (
  //         <Text style={[styles.rate, { fontSize: "9px" }]}>
  //           {symbol +
  //             "" +
  //             handleDigits(Math.abs(item.service_tax) * convertedAmount)}
  //         </Text>
  //       )}

  //       {hotelCurrency === hotelConvertedCurrency && (
  //         <Text style={[styles.amount, { fontSize: "9px" }]}>
  //           {symbol + "" + handleDigits(Math.abs(item?.service_total))}
  //         </Text>
  //       )}
  //       {hotelCurrency !== hotelConvertedCurrency && (
  //         <Text style={[styles.amount, { fontSize: "9px" }]}>
  //           {symbol +
  //             "" +
  //             handleDigits(Math.abs(item?.service_total) * convertedAmount)}
  //         </Text>
  //       )}
  //     </View>
  //   ));
  //   return <Fragment>{rows}</Fragment>;
  // };

  /* New logic to generate the tax items */
  const InvoiceTaxBreakdownHeaderItems = ({
    items,
    symbol,
    isNonRoom = false,
  }) => {
    const rows = (
      <View>
        <>
          {(igst?.status === "ENABLED" &&
            customerState !== "NA" &&
            !isNonRoom &&
            customerState !== propertyState) ||
            dbigst?.status === "ENABLED" ? (
            <View style={styles.row}>
              <Text
                style={[
                  styles.infoRow,
                  {
                    width: "50%",
                    color: customDetails?.content_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {t("IGST")}
              </Text>
              {hotelCurrency === hotelConvertedCurrency && (
                <Text
                  style={[
                    styles.infoRow,
                    {
                      width: "50%",
                      color: customDetails?.content_color,
                      fontSize: customDetails?.content_size,
                    },
                  ]}
                >
                  {symbol +
                    "" +
                    handleDigits(items["GST"] || items["CGST"] * 2)}
                </Text>
              )}
              {hotelCurrency !== hotelConvertedCurrency && (
                <Text
                  style={[
                    styles.infoRow,
                    {
                      width: "50%",
                      color: customDetails?.content_color,
                      fontSize: customDetails?.content_size,
                    },
                  ]}
                >
                  {symbol +
                    "" +
                    handleDigits(
                      (items["GST"] || items["CGST"] * 2) * convertedAmount
                    )}
                </Text>
              )}
            </View>
          ) : (
            Object.keys(items)?.map((item, index) => (
              <View style={styles.row} key={index}>
                <Text
                  style={[
                    styles.infoRow,
                    {
                      width: "50%",
                      color: customDetails?.content_color,
                      fontSize: customDetails?.content_size,
                    },
                  ]}
                >
                  {item}
                </Text>
                {hotelCurrency === hotelConvertedCurrency && (
                  <Text
                    style={[
                      styles.infoRow,
                      {
                        width: "50%",
                        color: customDetails?.content_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {symbol + "" + handleDigits(items[item])}
                  </Text>
                )}
                {hotelCurrency !== hotelConvertedCurrency && (
                  <Text
                    style={[
                      styles.infoRow,
                      {
                        width: "50%",
                        color: customDetails?.content_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {symbol + "" + handleDigits(items[item] * convertedAmount)}
                  </Text>
                )}
              </View>
            ))
          )}
        </>
        {summaryData?.promo_tax !== 0 &&
          summaryData?.promo_amount !== undefined &&
          summaryData?.promo_amount !== null && (
            <View style={styles.row}>
              <Text
                style={[
                  styles.infoRow,
                  {
                    width: "50%",
                    color: customDetails?.content_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {t("PROMO TAX")}
              </Text>
              {hotelCurrency === hotelConvertedCurrency && (
                <Text
                  style={[
                    styles.infoRow,
                    {
                      width: "50%",
                      color: customDetails?.content_color,
                      fontSize: customDetails?.content_size,
                    },
                  ]}
                >
                  {symbol + "" + handleDigits(summaryData?.promo_tax)}
                </Text>
              )}
              {hotelCurrency !== hotelConvertedCurrency && (
                <Text
                  style={[
                    styles.infoRow,
                    {
                      width: "50%",
                      color: customDetails?.content_color,
                      fontSize: customDetails?.content_size,
                    },
                  ]}
                >
                  {symbol +
                    "" +
                    handleDigits(summaryData?.promo_tax * convertedAmount)}
                </Text>
              )}
            </View>
          )}

        {/* {summaryData?.total_services_tax && !isNonRoom && (
          <View style={styles.row}>
            <Text
              style={[
                styles.infoRow,
                {
                  width: "50%",
                  color: customDetails?.content_color,
                  fontSize: customDetails?.content_size,
                },
              ]}
            >
              {t("Other service tax")}
            </Text>
            {
              <Text
                style={[
                  styles.infoRow,
                  {
                    width: "50%",
                    color: customDetails?.content_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {hotelCurrency === hotelConvertedCurrency
                  ? symbol + "" + handleDigits(summaryData?.total_services_tax)
                  : hotelConvertedCurrencySymbol +
                    " " +
                    handleDigits(
                      summaryData?.total_services_tax * convertedAmount
                    )}
              </Text>
            }
          </View>
        )} */}
      </View>
    );
    return <Fragment>{rows}</Fragment>;
  };

  /* Old logic to generate the tax items */

  // const InvoiceTaxBreakdownHeaderItems = ({ items, symbol }) => {
  //   const rows = Object.keys(items)?.map((item, index) => (
  //     <View style={styles.taxRow} key={index}>
  //       <Text style={[styles.taxDescription, { fontSize: "9px" }]}>
  //         {item}
  //       </Text>
  //       {hotelCurrency === hotelConvertedCurrency && (
  //         <Text
  //           style={[
  //             { width: "50%", textAlign: "center" },
  //             { fontSize: "9px" },
  //           ]}
  //         >
  //           {symbol + "" + handleDigits(items[item])}
  //         </Text>
  //       )}
  //       {hotelCurrency !== hotelConvertedCurrency && (
  //         <Text
  //           style={[
  //             { width: "50%", textAlign: "center" },
  //             { fontSize: "9px" },
  //           ]}
  //         >
  //           {symbol + "" + handleDigits(items[item] * convertedAmount)}
  //         </Text>
  //       )}
  //     </View>
  //   ));
  //   return <Fragment>{rows}</Fragment>;
  // };
  // Render component
  // Rendering invoice items
  const renderInvoiceItems = (invoice, items, customDetails) => {
    const renderedItems = [];
    for (let i = 8; i < items.length + 1; i += 10) {
      renderedItems.push(
        <View
          style={[
            styles.tableContainer,
            { marginTop: "10px", borderTop: "1px solid #E5e5e5" },
          ]}
          break
        >
          <InvoiceTableRow
            bookingFees={Number(invoice?.total_fees)}
            items={items?.slice(i, i + 10)}
            symbol={
              hotelCurrency === hotelConvertedCurrency
                ? symbol
                : hotelConvertedCurrencySymbol
            }
            customDetails={customDetails}
          />
        </View>
      );
    }
    return renderedItems;
  };

  function renderTaxBreakdown() {
    const taxes = { ...summaryData?.tax_breakdown };
    let showGST = true;
    if (taxes.hasOwnProperty("GST")) {
      if (Object.keys(taxes).length > 1 && Number(taxes?.GST) === 0) {
        delete taxes?.GST;
        showGST = false;
      }
    }
    return { taxes: taxes, showGST: showGST };
  }

  // Function to generate the default cancellation policies
  function handleGenerateCancellationPolicies(invoice, customDetails) {
    return (
      <View>
        <Text
          style={{
            fontSize: "10px",
            marginBottom: 5,
            color: customDetails?.content_color,
          }}
        >
          {t("Guests will be refunded")}{" "}
          {invoice?.policy?.refund_prestay_before}%{" "}
          {t("of total booking value when cancelled before")}{" "}
          {readableTimeConverter(invoice?.policy?.prestay_before)}{" "}
          {t("of checkin date.")}
        </Text>
        <Text
          style={{
            fontSize: "10px",
            marginBottom: 5,
            color: customDetails?.content_color,
          }}
        >
          {t("Guests will be refunded")}{" "}
          {invoice?.policy?.refund_prestay_within}% {t("if cancelled within")}
          {readableTimeConverter(invoice?.policy?.prestay_before)}{" "}
          {t("of checkin date.")}
        </Text>
      </View>
    );
  }

  return (
    <View
      style={{
        position: "relative",
        padding: "9px",
        paddingTop: customDetails?.margin_top,
      }}
    >
      <View
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        {hotelData?.hotel_id === "27838" && (
          <View style={{ position: "relative", width: "33.33%" }}></View>
        )}
        {/* Hotel LOGO */}
        <View
          style={{
            position: "relative",
            width: hotelData?.hotel_id === "27838" ? "33.33%" : "50%",
            display: "flex",
            alignItems: hotelData?.hotel_id === "27838" && "center",
          }}
        >
          {customDetails &&
            customDetails?.show_logo.toLowerCase() === "yes" &&
            getExtension(hotelLogo) && (
              <Image
                style={[styles.image, { width: customDetails?.logo_size }]}
                src={getExtension(hotelLogo)}
              />
            )}
        </View>

        {/* Displaying the type of invoice */}
        <View
          style={{
            position: "relative",
            display: "flex",
            width: hotelData?.hotel_id === "27838" ? "33.33%" : "50%",
            alignItems: "flex-end",
          }}
        >
          <Text
            style={{
              fontSize: "15px",
              color: "grey",
            }}
          >
            {folioHeading === undefined ? t("Advanced") : t("Detailed")}{" "}
            {handleInvoiceHeader(customDetails)}
          </Text>

          {customDetails?.invoice_date_rule !== "DISABLED" &&
            customDetails?.invoice_date_rule !== "HIDE_INVOICE_DATE" && (
              <Text
                style={{
                  fontSize: "8px",
                  marginBottom: 3,
                  color: "grey",
                  marginTop: 3,
                }}
              >
                {t("Invoice date :")}{" "}
                {customDetails?.invoice_date_rule === "SHOW_CURRENT_DATE"
                  ? moment().format(`MMM DD YYYY`)
                  : moment(invoice?.actual_checkout).format("MMM DD YYYY")}
                {/* Invoice Date : {moment(invoice?.actual_checkout).format(`MMM DD YYYY`)} */}
              </Text>
            )}

          <Text style={{ fontSize: "10px" }}>
            {customDetails &&
              (Number(summaryData?.payment_made) > 0 ||
                handleGetBillToCta(invoice, summaryData) > 0) &&
              handleCustomBookingId(invoice, customDetails).trim().length > 0 ? (
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  marginBottom: 3,
                  color: customDetails?.header_color,
                }}
              >
                {isTTDCProperty ? "TTDC Reference ID :" : t("Invoice no. :")}{" "}
                {handleCustomBookingId(invoice, customDetails)}
              </Text>
            ) : null}
          </Text>
        </View>
      </View>

      <View
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        {/* Hotel details */}
        <View
          style={{
            position: "relative",
            width: "40%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {/* <Text style={{ fontSize: "9px", marginBottom: 3.5 }}>Bill From</Text> */}
          <Text
            style={[
              styles.boldText,
              {
                marginBottom: 2,
                color: customDetails?.header_color,
                fontSize: customDetails?.content_size,
              },
            ]}
          >
            {hotelData?.hotel_name}
          </Text>
          <Text
            style={[
              styles.boldText,
              {
                marginBottom: 3,
                flexWrap: "wrap",
                color: customDetails?.header_color,
                fontSize: customDetails?.content_size,
              },
            ]}
          >
            {handleFullAddString(hotelData?.hotel_address)}
          </Text>
          <View style={styles.dualText}>
            <Text
              style={[
                styles.boldText,
                {
                  color: customDetails?.header_color,
                  fontSize: customDetails?.content_size,
                },
              ]}
            >
              {customDetails &&
                customDetails?.invoice_contact_email &&
                !customDetails?.invoice_contact_email
                  .toLowerCase()
                  .includes("null", "n/a")
                ? customDetails?.invoice_contact_email
                : hotelData?.hotel_email}
            </Text>
          </View>
          <View style={styles.dualText}>
            <Text
              style={[
                styles.boldText,
                {
                  color: customDetails?.header_color,
                  fontSize: customDetails?.content_size,
                },
              ]}
            >
              {t("Phone:")}
            </Text>
            <Text
              style={[
                styles.boldText,
                {
                  color: customDetails?.header_color,
                  fontSize: customDetails?.content_size,
                },
              ]}
            >
              {customDetails &&
                customDetails?.invoice_contact_phone &&
                !customDetails?.invoice_contact_phone
                  .toLowerCase()
                  .includes("null", "n/a")
                ? customDetails?.invoice_contact_phone
                : hotelData?.hotel_contact}
            </Text>
          </View>
          {hotelData?.tax_identification_number.length > 0 ? (
            <View style={styles.dualText}>
              <Text
                style={[
                  styles.boldText,
                  {
                    color: customDetails?.header_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {String(symbol)?.includes("Rs")
                  ? `${t("Hotel GST No. : ")}`
                  : `${t("Hotel Tax IN : ")}`}
              </Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    color: customDetails?.header_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {hotelData.tax_identification_number}
              </Text>
            </View>
          ) : null}
          {invoice?.booking_reference_id !== "" && (
            <View style={styles.dualText}>
              <Text
                style={[
                  styles.boldText,
                  {
                    color: customDetails?.header_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {t("Booking reference id : ")}
              </Text>

              <Text
                style={[
                  styles.boldText,
                  {
                    color: customDetails?.header_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {invoice?.booking_reference_id}
              </Text>
            </View>
          )}
        </View>

        {/* Bill to - logic */}
        <View
          style={{
            position: "relative",
            display: "flex",
            flexWrap: "wrap",
            width: "40%",
            justifyContent: "flex-end",
          }}
        >
          {/* CTA Details incase bill is settled to DIRECT_BILLING */}
          {/* {invoice?.segment === "DIRECT_BILLING" && (
            <View style={{ textAlign: "right", width: "50%" }}>
              <Text
                style={{
                  fontSize: "10px",
                  paddingRight: "5px",
                  marginBottom: 3,
                }}
              >
                Bill to
              </Text>
              <Text style={[styles.boldText, { marginBottom: 3.5 }]}>
                {ctaDetails?.cta_name}
              </Text>
              {ctaDetails?.cta_address_line1.length > 0 && (
                <Text
                  style={[
                    styles.boldText,
                    {
                      marginBottom: 3.5,
                      marginLeft: 10,
                      flexWrap: "wrap",
                    },
                  ]}
                >
                  {ctaDetails?.cta_address_line1}{" "}
                  {ctaDetails?.cta_address_line2} {ctaDetails?.cta_address_city}{" "}
                  {ctaDetails?.cta_address_state}
                </Text>
              )}
              <View
                style={[
                  {
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginBottom: 3.5,
                  },
                  { textAlign: "right" },
                ]}
              >
                <Text style={styles.boldText}>Email : </Text>
                {ctaDetails?.cta_email.length > 0 ? (
                  <Text style={styles.boldText}>{ctaDetails?.cta_email}</Text>
                ) : (
                  <Text style={styles.boldText}>{"-"}</Text>
                )}
              </View>
              <View
                style={[
                  {
                    flexDirection: "row",
                    marginBottom: 3.5,
                    justifyContent: "flex-end",
                  },
                  { textAlign: "right" },
                ]}
              >
                <Text style={styles.boldText}>Phone:</Text>
                {ctaDetails?.cta_phone?.length > 0 ||
                ctaDetails?.cta_phone !== "NA" ? (
                  <Text style={styles.boldText}>{ctaDetails?.cta_phone}</Text>
                ) : (
                  <Text style={styles.boldText}>{"-"}</Text>
                )}
              </View>
              {ctaDetails?.cta_tax_id_no?.length === 0 ? (
                <View
                  style={[
                    {
                      flexDirection: "row",
                      marginBottom: 3.5,
                      justifyContent: "flex-end",
                      marginRight: 50,
                    },
                  ]}
                >
                  <Text style={styles.boldText}>
                    {" "}
                    {String(symbol).includes("Rs")
                      ? "CTA GST No. :"
                      : "CTA Tax IN :"}
                  </Text>
                  <Text style={styles.boldText}> </Text>
                </View>
              ) : (
                <View
                  style={[
                    {
                      flexDirection: "row",
                      marginBottom: 3.5,
                      justifyContent: "flex-end",
                    },
                    { textAlign: "right" },
                  ]}
                >
                  <Text style={styles.boldText}>
                    {" "}
                    {String(symbol).includes("Rs")
                      ? "CTA GST No. :"
                      : "CTA Tax IN :"}
                  </Text>
                  <Text style={styles.boldText}>
                    {ctaDetails?.cta_tax_id_no}
                  </Text>
                </View>
              )}
            </View>
          )} */}

          <View
            style={{
              textAlign: "right",
            }}
          >
            {/* <Text
              style={{
                fontSize: "10px",
                paddingRight: "5px",
                marginBottom: 3,
              }}
            >
              Bill to
            </Text> */}
            <Text
              style={[
                styles.boldText,
                {
                  marginBottom: 2,
                  color: customDetails?.header_color,
                  fontSize: customDetails?.content_size,
                },
              ]}
            >
              {invoice?.segment === "DIRECT_BILLING"
                ? ctaDetails?.cta_name
                : billToPref === "COMPANY"
                  ? custCompanyDetails[0]?.company_name
                  : invoice?.customer_name}
            </Text>

            {invoice.segment === "DIRECT_BILLING" ? (
              <Text
                style={[
                  styles.boldText,
                  {
                    marginBottom: 2,
                    flexWrap: "wrap",
                    color: customDetails?.header_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {ctaDetails?.cta_address_line1}
                {ctaDetails?.cta_address_line2} {ctaDetails?.cta_address_city}{" "}
                {ctaDetails?.cta_address_state}
              </Text>
            ) : !invoice?.customer_address_full_str.includes("NA", "null") ||
              billToPref === "COMPANY" ? (
              <Text
                style={[
                  styles.boldText,
                  {
                    marginBottom: 2,
                    flexWrap: "wrap",
                    color: customDetails?.header_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {billToPref === "COMPANY"
                  ? custCompanyDetails[0]?.company_address
                  : handleFullAddString(invoice?.customer_address_full_str)}
              </Text>
            ) : (
              <Text
                style={[
                  styles.boldText,
                  {
                    marginBottom: 2,
                    flexWrap: "wrap",
                    color: customDetails?.header_color,
                    fontSize: customDetails?.content_size,
                  },
                ]}
              >
                {handleCustomerAddress(invoice?.customer_address)}
              </Text>
            )}

            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                marginBottom: 2,
                color: customDetails?.header_color,
                fontSize: customDetails?.content_size,
              }}
            >
              {/* <Text style={styles.boldText}>Email : </Text> */}
              {invoice?.segment === "DIRECT_BILLING" ? (
                ctaDetails?.cta_email.length > 0 ? (
                  <Text
                    style={[
                      styles.boldText,
                      {
                        color: customDetails?.header_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {ctaDetails?.cta_email}
                  </Text>
                ) : null
              ) : invoice?.customer_email.length > 0 ||
                billToPref === "COMPANY" ? (
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: customDetails?.header_color,
                      fontSize: customDetails?.content_size,
                    },
                  ]}
                >
                  {billToPref === "COMPANY"
                    ? custCompanyDetails[0]?.company_email
                    : invoice?.customer_email}
                </Text>
              ) : null}
            </View>

            {invoice?.segment === "DIRECT_BILLING" ? (
              ctaDetails?.cta_phone?.length > 0 &&
                ctaDetails?.cta_phone !== "NA" ? (
                <View
                  style={{
                    position: "relative",
                    flexDirection: "row",
                    marginBottom: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    color: customDetails?.header_color,
                  }}
                >
                  <Text
                    style={[
                      styles.boldText,
                      {
                        color: customDetails?.header_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {t("Phone:")}
                  </Text>
                  <Text
                    style={[
                      styles.boldText,
                      {
                        color: customDetails?.header_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {ctaDetails?.cta_phone}
                  </Text>
                </View>
              ) : null
            ) : invoice?.customer_phone.length > 0 ||
              billToPref === "COMPANY" ? (
              <View
                style={{
                  position: "relative",
                  flexDirection: "row",
                  marginBottom: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  color: customDetails?.header_color,
                }}
              >
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: customDetails?.header_color,
                      fontSize: customDetails?.content_size,
                    },
                  ]}
                >
                  {t("Phone:")}
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: customDetails?.header_color,
                      fontSize: customDetails?.content_size,
                    },
                  ]}
                >
                  {billToPref === "COMPANY"
                    ? custCompanyDetails[0]?.company_phone
                    : invoice?.customer_phone}
                </Text>
              </View>
            ) : null}

            {invoice?.segment === "DIRECT_BILLING" ? (
              ctaDetails?.cta_tax_id_no?.length !== 0 &&
                ctaDetails?.cta_tax_id_no !== "NA" ? (
                <View
                  style={[
                    {
                      flexDirection: "row",
                      marginBottom: 2,
                      justifyContent: "flex-end",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.boldText,
                      {
                        color: customDetails?.header_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {" "}
                    {String(symbol).includes("Rs")
                      ? `${t("Direct billing GST No. :")}`
                      : `${t("Direct billing tax id: ")}`}
                    {/* : "CTA Tax IN :" */}
                  </Text>
                  <Text
                    style={[
                      styles.boldText,
                      {
                        color: customDetails?.header_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {ctaDetails?.cta_tax_id_no}
                  </Text>
                </View>
              ) : null
            ) : customerIdentification?.length !== 0 ||
              billToPref === "COMPANY" ? (
              <View
                style={[
                  {
                    flexDirection: "row",
                    marginBottom: 2,
                    justifyContent: "flex-end",
                  },
                ]}
              >
                {billToPref === "COMPANY" ? (
                  <Text
                    style={[
                      styles.boldText,
                      {
                        color: customDetails?.header_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {String(symbol).includes("Rs")
                      ? `${t("Company GST No. :")}`
                      : `${t("Company Tax IN :")}`}
                  </Text>
                ) : (
                  <Text
                    style={[
                      styles.boldText,
                      {
                        color: customDetails?.header_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {String(symbol).includes("Rs")
                      ? `${t("Guest GST No. :")}`
                      : `${t("Guest Tax IN :")}`}
                  </Text>
                )}
                {billToPref === "COMPANY" ? (
                  <Text
                    style={[
                      styles.boldText,
                      {
                        color: customDetails?.header_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {custCompanyDetails[0]?.gst_number}
                  </Text>
                ) : (
                  <Text
                    style={[
                      styles.boldText,
                      {
                        color: customDetails?.header_color,
                        fontSize: customDetails?.content_size,
                      },
                    ]}
                  >
                    {customerIdentification}
                  </Text>
                )}
              </View>
            ) : null}

            {invoice.segment === "DIRECT_BILLING" ||
              billToPref === "COMPANY" ? (
              <View
                style={{
                  marginBottom: 3.5,
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: customDetails?.header_color,
                      fontSize: customDetails?.content_size,
                      marginTop: "auto",
                      marginBottom: "auto",
                    },
                  ]}
                >
                  {t("Guest name:")}
                </Text>
                <Text
                  style={[
                    [
                      styles.boldText,
                      {
                        color: customDetails?.header_color,
                        // marginBottom: 2,
                        fontSize: customDetails?.content_size,
                        marginTop: "auto",
                        marginBottom: "auto",
                      },
                    ],
                  ]}
                >
                  {" "}
                  {invoice?.customer_name}
                </Text>
              </View>
            ) : null}
            {isTTDCProperty && paymentRefId && paymentRefId !== "" && (
              <View
                style={{
                  marginBottom: 3.5,
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: customDetails?.header_color,
                      fontSize: customDetails?.content_size,
                      marginTop: "auto",
                      marginBottom: "auto",
                    },
                  ]}
                >
                  {t("Payment Reference ID: ")}
                </Text>
                <Text
                  style={[
                    [
                      styles.boldText,
                      {
                        color: customDetails?.header_color,
                        fontSize: customDetails?.content_size,
                        marginTop: "auto",
                        marginBottom: "auto",
                      },
                    ],
                  ]}
                >
                  {" "}
                  {paymentRefId}
                </Text>
              </View>
            )}
            {/* Only one GST can be associated with an invoice */}
            {/* {invoice.segment === "DIRECT_BILLING" &&
            customerIdentification.length > 0 ? (
              <View
                style={{
                  marginBottom: 3.5,
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: customDetails?.header_color,
                      fontSize: customDetails?.content_size,
                    },
                  ]}
                >
                  {String(symbol).includes("Rs")
                    ? "Guest GST No. :"
                    : "Guest Tax IN :"}
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      marginBottom: 2,
                      color: customDetails?.header_color,
                      fontSize: customDetails?.content_size,
                    },
                  ]}
                >
                  {customerIdentification}
                </Text>
              </View>
            ) : null} */}
          </View>
        </View>
      </View>

      {/* Folio details */}
      <View
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <View style={{ position: "relative", width: "50%", marginTop: "9px" }}>
          {/* <Text style={{ marginBottom: 5, fontSize: "9px" }}>
            {handleInvoiceHeader(customDetails)}
          </Text> */}

          {customDetails?.hide_stayflexi_booking_id.toLowerCase() === "no" && (
            <Text
              style={{
                fontSize: customDetails?.content_size,
                fontWeight: "bold",
                marginBottom: 3,
                color: customDetails?.content_color,
              }}
            >
              {invoice?.bookingid}
            </Text>
          )}
          {/* {customDetails &&
          Number(summaryData?.payment_made) > 0 &&
          handleCustomBookingId(invoice, customDetails).trim().length > 0 ? (
            <Text
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                marginBottom: 3,
              }}
            >{`Advanced ${handleInvoiceHeader(
              customDetails
            )}#${handleCustomBookingId(invoice, customDetails)}`}</Text>
          ) : null} */}
          <InvoiceDualText
            boldText={t("Booking made on")}
            normalText={formatDate(invoice?.booking_made_on)}
            customDetails={customDetails}
          />
          <InvoiceDualText
            customDetails={customDetails}
            boldText={t("Check-in")}
            normalText={formatDate(invoice?.checkin)}
          />
          <InvoiceDualText
            customDetails={customDetails}
            boldText={t("Check-out")}
            normalText={formatDate(invoice?.checkout)}
          />
          {customDetails?.show_actual_cin_cout === "YES" && (
            <>
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Actual Check-in")}
                normalText={formatDate(invoice?.actual_checkin)}
              />
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Actual Check-out")}
                normalText={formatDate(invoice?.actual_checkout)}
              />
            </>
          )}

          {hotelEmail?.includes("@maharashtratourism.gov.in") &&
            folioLogs?.invoice?.folio_log_data?.filter((elm) =>
              elm?.log_text?.startsWith("CREATED")
            )?.[0]?.user_email &&
            folioLogs?.invoice?.folio_log_data
              ?.filter((elm) => elm?.log_text?.startsWith("CREATED"))?.[0]
              ?.user_email?.includes("@") && (
              <InvoiceDualText
                boldText={t("Booked by")}
                normalText={
                  folioLogs?.invoice?.folio_log_data?.filter((elm) =>
                    elm?.log_text?.startsWith("CREATED")
                  )?.[0]?.user_email
                }
                customDetails={customDetails}
              />
            )}
          {hotelEmail?.includes("@maharashtratourism.gov.in") &&
            folioLogs?.invoice?.folio_log_data?.filter((elm) =>
              elm?.log_text?.startsWith("Cancelled")
            )?.[0]?.user_email &&
            folioLogs?.invoice?.folio_log_data
              ?.filter((elm) => elm?.log_text?.startsWith("Cancelled"))?.[0]
              ?.user_email?.includes("@") && (
              <InvoiceDualText
                boldText={t("Cancelled by")}
                normalText={
                  folioLogs?.invoice?.folio_log_data?.filter((elm) =>
                    elm?.log_text?.startsWith("Cancelled")
                  )?.[0]?.user_email
                }
                customDetails={customDetails}
              />
            )}
          {hotelEmail?.includes("@maharashtratourism.gov.in") &&
            folioLogs?.invoice?.folio_log_data?.filter((elm) =>
              elm?.log_text?.startsWith("Cancelled")
            )?.[0] && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Cancelled Time")}
                normalText={formatDate(
                  folioLogs?.invoice?.folio_log_data?.filter((elm) =>
                    elm?.log_text?.startsWith("Cancelled")
                  )?.[0]?.log_timestamp
                )}
              />
            )}
          {hotelEmail?.includes("@maharashtratourism.gov.in") &&
            otaBookingInfoData?.ota_booking_id &&
            (invoice?.status === "CONFIRMED" ||
              invoice?.status === "CANCELLED") && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("OTA booking ID")}
                normalText={otaBookingInfoData?.ota_booking_id}
              />
            )}
        </View>
        <View
          style={{
            position: "relative",
            width: "50%",
            display: "flex",
            alignItems: "flex-end",
            alignContent: "flex-end",
          }}
        >
          {/* <View style={styles.dualText}> */}
          <InvoiceDualText
            customDetails={customDetails}
            boldText={t("Nights")}
            normalText={invoice?.num_nights}
          />
          <InvoiceDualText
            customDetails={customDetails}
            boldText={t("Adults")}
            normalText={invoice?.adults}
          />
          <InvoiceDualText
            customDetails={customDetails}
            boldText={t("Children")}
            normalText={invoice?.children}
          />
          {/* </View> */}
          <InvoiceDualText
            boldText={t("Rate plan(s)")}
            normalText={getMapForTheRatePlans(invoice?.rate_plan_names)}
            customDetails={customDetails}
          />
          <InvoiceDualText
            customDetails={customDetails}
            boldText={t("Room No.(s)")}
            // normalText={invoice?.roomids}
            normalText={
              invoice.hasOwnProperty("confirmed_room_ids_list")
                ? invoice?.confirmed_room_ids_list.join(",")
                : invoice?.roomids
            }
            enableNewLine={true}
          />
          <InvoiceDualText
            customDetails={customDetails}
            boldText={t("Source")}
            normalText={invoice?.booking_source_displayname}
          />
          <InvoiceDualText
            boldText={t("Status")}
            normalText={getBookingPaymentStatus(
              summaryData?.invoice_total_amount,
              summaryData?.balance_due,
              summaryData?.total_cta_balance,
              symbol
            )}
            customDetails={customDetails}
          />
        </View>
      </View>

      {/* Displaying the items for the adv invoice */}
      {advInvoice?.items && (
        <>
          <View style={styles.tableContainer}>
            <InvoiceTableHeader
              customDetails={customDetails}
              symbol={
                hotelCurrency === hotelConvertedCurrency
                  ? symbol
                  : hotelConvertedCurrencySymbol
              }
              propertyState={propertyState}
              utgst={utgst}
            />
            <InvoiceTableRow
              bookingFees={Number(invoice?.total_fees)}
              items={advInvoice?.items}
              symbol={
                hotelCurrency === hotelConvertedCurrency
                  ? symbol
                  : hotelConvertedCurrencySymbol
              }
              customDetails={customDetails}
              propertyState={propertyState}
              utgst={utgst}
            />
          </View>
          {/* {renderInvoiceItems(invoice, advInvoice?.items, customDetails)} */}
        </>
      )}

      {/* Displaying tax details and the authorized amount details */}
      <View
        style={{
          marginTop: 15,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ display: "flex", flexDirection: "column" }}>
          {summaryData?.auths && summaryData?.auths.length > 0 && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "7.5px",
              }}
            >
              {summaryData?.auths?.map((item, index) => (
                <View
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "7.5px",
                  }}
                >
                  <Text
                    style={{
                      wordBreak: "break-word",
                      fontSize: customDetails?.content_size,
                      color: customDetails?.content_color,
                    }}
                  >
                    {item?.pm_payment_type} {item?.pm_payment_issuer}{" "}
                    {t("Authorized:")}
                  </Text>
                  {hotelCurrency === hotelConvertedCurrency && (
                    <Text
                      style={{
                        wordBreak: "break-word",
                        fontSize: customDetails?.content_size,
                        color: customDetails?.content_color,
                      }}
                    >
                      {symbol} {Number(item?.pm_amount)}
                    </Text>
                  )}
                  {hotelCurrency !== hotelConvertedCurrency && (
                    <Text
                      style={{
                        wordBreak: "break-word",
                        fontSize: customDetails?.content_size,
                        color: customDetails?.content_color,
                      }}
                    >
                      {hotelConvertedCurrencySymbol}{" "}
                      {(Number(item?.pm_amount) * convertedAmount).toFixed(2)}
                    </Text>
                  )}
                </View>
              ))}
            </View>
          )}
          {
            summaryData?.tax_breakdown && (
              // !["Rs.", "rs.", "INR", "inr"].includes(symbol) && (
              <View>
                <Text
                  style={{
                    // fontSize: "10px",
                    fontSize: customDetails?.content_size,
                    marginBottom: 5,
                    color: customDetails?.content_color,
                  }}
                >
                  {t("Room tax breakdown")}
                </Text>
                <View style={{ width: "60%" }}>
                  <InvoiceTaxBreakdownHeader customDetails={customDetails} />
                  <InvoiceTaxBreakdownHeaderItems
                    // items={summaryData?.tax_breakdown}
                    items={renderTaxBreakdown()["taxes"]}
                    symbol={
                      hotelCurrency === hotelConvertedCurrency
                        ? symbol
                        : hotelConvertedCurrencySymbol
                    }
                    customDetails={customDetails}
                  />
                </View>
              </View>
            )
            // )
          }
          {summaryData?.total_services_tax && (
            <View>
              <Text
                style={{
                  fontSize: customDetails?.content_size,
                  marginBottom: 5,
                  marginTop: "10px",
                  color: customDetails?.content_color,
                }}
              >
                {t("Other tax breakdown")}
              </Text>
              <View style={{ width: "60%" }}>
                <InvoiceTaxBreakdownHeader customDetails={customDetails} />
                <InvoiceTaxBreakdownHeaderItems
                  items={{
                    "Other service tax": summaryData?.total_services_tax,
                  }}
                  symbol={
                    hotelCurrency === hotelConvertedCurrency
                      ? symbol
                      : hotelConvertedCurrencySymbol
                  }
                  customDetails={customDetails}
                  isNonRoom={true}
                />
              </View>
            </View>
          )}
          {summaryData &&
            Object.entries(summaryData?.fee_breakdown).length > 0 && (
              <View>
                <Text
                  style={{
                    fontSize: customDetails?.content_size,
                    color: customDetails?.content_color,
                    marginBottom: 5,
                    marginTop: "10px",
                  }}
                >
                  {t("Booking fees breakdown")}
                </Text>
                <View style={{ width: "60%" }}>
                  <InvoiceFeeBreakdownHeader customDetails={customDetails} />
                  <InvoiceTaxBreakdownHeaderItems
                    items={summaryData?.fee_breakdown}
                    symbol={
                      hotelCurrency === hotelConvertedCurrency
                        ? symbol
                        : hotelConvertedCurrencySymbol
                    }
                    customDetails={customDetails}
                    isNonRoom={true}
                  />
                </View>
              </View>
            )}
        </View>

        {/* Displaying the agg values such as Subtotal, taxes & fee, etc.  */}
        <View>
          {hotelCurrency === hotelConvertedCurrency && (
            <InvoiceDualText
              customDetails={customDetails}
              boldText={t("Sub total")}
              normalText={
                symbol +
                "" +
                handleDigits(summaryData?.total_pretax_amount_with_services)
              }
              style={styles.extendedNormalStyle}
              boldTextStyle={styles.extendedBoldStyle}
            />
          )}
          {hotelCurrency !== hotelConvertedCurrency && (
            <InvoiceDualText
              customDetails={customDetails}
              boldText={t("Sub total")}
              normalText={
                hotelConvertedCurrencySymbol +
                "" +
                handleDigits(
                  summaryData?.total_pretax_amount_with_services *
                  convertedAmount
                )
              }
              style={styles.extendedNormalStyle}
              boldTextStyle={styles.extendedBoldStyle}
            />
          )}
          {/* {invoice?.applied_promocode &&
            invoice?.applied_promocode.length > 0 &&
            Number(invoice?.promo_amount) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
customDetails={customDetails}
                boldText={`Discount`}
                normalText={symbol + "" + handleDigits(invoice?.promo_amount * -1)}
              />
            )}
          {invoice?.applied_promocode &&
            invoice?.applied_promocode.length > 0 &&
            Number(invoice?.promo_amount) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
customDetails={customDetails}
                boldText={`Discount`}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(invoice?.promo_amount * convertedAmount * -1)
                }
              />
            )} */}
          {["Rs.", "INR"]?.includes(symbol) ? (
            (igst?.status === "ENABLED" &&
              customerState &&
              customerState !== "NA" &&
              customerState !== propertyState) ||
              dbigst?.status === "ENABLED" ? (
              <>
                {hotelCurrency === hotelConvertedCurrency &&
                  renderTaxBreakdown()["showGST"] && (
                    <InvoiceDualText
                      boldText={"IGST"}
                      normalText={
                        hotelCurrency === hotelConvertedCurrency
                          ? symbol +
                          "" +
                          handleDigits(summaryData?.total_room_tax_amount)
                          : hotelConvertedCurrencySymbol +
                          "" +
                          handleDigits(
                            summaryData?.total_room_tax_amount *
                            convertedAmount
                          )
                      }
                      style={styles.extendedNormalStyle}
                      boldTextStyle={styles.extendedBoldStyle}
                      customDetails={customDetails}
                    />
                  )}
              </>
            ) : (
              <>
                {hotelCurrency === hotelConvertedCurrency &&
                  renderTaxBreakdown()["showGST"] && (
                    <InvoiceDualText
                      customDetails={customDetails}
                      boldText={t("CGST")}
                      normalText={
                        symbol +
                        "" +
                        handleDigits(
                          Number(summaryData?.total_room_tax_amount) / 2
                        )
                      }
                      style={styles.extendedNormalStyle}
                      boldTextStyle={styles.extendedBoldStyle}
                    />
                  )}
                {hotelCurrency !== hotelConvertedCurrency &&
                  renderTaxBreakdown()["showGST"] && (
                    <InvoiceDualText
                      customDetails={customDetails}
                      boldText={t("CGST")}
                      normalText={
                        hotelConvertedCurrencySymbol +
                        "" +
                        handleDigits(
                          (Number(summaryData?.total_room_tax_amount) *
                            convertedAmount) /
                          2
                        )
                      }
                      style={styles.extendedNormalStyle}
                      boldTextStyle={styles.extendedBoldStyle}
                    />
                  )}
                {hotelCurrency === hotelConvertedCurrency &&
                  renderTaxBreakdown()["showGST"] && (
                    <InvoiceDualText
                      customDetails={customDetails}
                      boldText={t("SGST")}
                      normalText={
                        symbol +
                        "" +
                        handleDigits(
                          Number(summaryData?.total_room_tax_amount) / 2
                        )
                      }
                      style={styles.extendedNormalStyle}
                      boldTextStyle={styles.extendedBoldStyle}
                      propertyState={propertyState}
                      utgst={utgst}
                    />
                  )}
                {hotelCurrency !== hotelConvertedCurrency &&
                  renderTaxBreakdown()["showGST"] && (
                    <InvoiceDualText
                      customDetails={customDetails}
                      boldText={t("SGST")}
                      normalText={
                        hotelConvertedCurrencySymbol +
                        "" +
                        handleDigits(
                          (Number(summaryData?.total_room_tax_amount) *
                            convertedAmount) /
                          2
                        )
                      }
                      style={styles.extendedNormalStyle}
                      boldTextStyle={styles.extendedBoldStyle}
                      propertyState={propertyState}
                      utgst={utgst}
                    />
                  )}
              </>
            )
          ) : (
            <>
              {hotelCurrency === hotelConvertedCurrency && (
                <InvoiceDualText
                  customDetails={customDetails}
                  boldText={t("Tax & Fees")}
                  normalText={
                    symbol +
                    "" +
                    handleDigits(summaryData?.total_room_tax_amount)
                  }
                  style={styles.extendedNormalStyle}
                  boldTextStyle={styles.extendedBoldStyle}
                />
              )}

              {hotelCurrency !== hotelConvertedCurrency && (
                <InvoiceDualText
                  customDetails={customDetails}
                  boldText={t("Tax & Fees")}
                  normalText={
                    hotelConvertedCurrencySymbol +
                    "" +
                    handleDigits(
                      summaryData?.total_room_tax_amount * convertedAmount
                    )
                  }
                  style={styles.extendedNormalStyle}
                  boldTextStyle={styles.extendedBoldStyle}
                />
              )}
            </>
          )}

          {summaryData?.total_services_tax &&
            (hotelCurrency === hotelConvertedCurrency ? (
              <InvoiceDualText
                boldText={t("Other service tax")}
                normalText={
                  symbol + "" + handleDigits(summaryData?.total_services_tax)
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
                customDetails={customDetails}
              />
            ) : (
              <InvoiceDualText
                boldText={t("Other service tax")}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(
                    summaryData?.total_services_tax * convertedAmount
                  )
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
                customDetails={customDetails}
              />
            ))}

          {hotelCurrency === hotelConvertedCurrency && (
            <InvoiceDualText
              boldText={t("Total")}
              normalText={
                symbol +
                "" +
                handleDigits(summaryData?.total_amount_with_services)
              }
              style={styles.extendedNormalStyle}
              boldTextStyle={styles.extendedBoldStyle}
              customDetails={customDetails}
            />
          )}
          {hotelCurrency !== hotelConvertedCurrency && (
            <InvoiceDualText
              boldText={t("Total")}
              normalText={
                hotelConvertedCurrencySymbol +
                "" +
                handleDigits(
                  summaryData?.total_amount_with_services * convertedAmount
                )
              }
              style={styles.extendedNormalStyle}
              boldTextStyle={styles.extendedBoldStyle}
              customDetails={customDetails}
            />
          )}

          {parseInt(summaryData?.total_online_payment) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Paid via Gateway payments")}
                normalText={
                  symbol + "" + handleDigits(summaryData?.total_online_payment)
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {parseInt(summaryData?.total_online_payment) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Paid via Gateway payments")}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(
                    summaryData?.total_online_payment * convertedAmount
                  )
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {parseInt(summaryData?.cash_payment) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Paid via Gateway payments")}
                normalText={
                  symbol + "" + handleDigits(summaryData?.cash_payment)
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {parseInt(summaryData?.cash_payment) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Paid via Cash payments")}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(summaryData?.cash_payment * convertedAmount)
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {parseInt(summaryData?.offline_check_payment) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Paid via Offline cheque payments")}
                normalText={
                  symbol + "" + handleDigits(summaryData?.offline_check_payment)
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {parseInt(summaryData?.offline_check_payment) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Paid via Offline cheque payments")}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(
                    summaryData?.offline_check_payment * convertedAmount
                  )
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}
          {parseInt(summaryData?.offline_card_payment) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Paid via Offline card payments")}
                normalText={
                  symbol + "" + handleDigits(summaryData?.offline_card_payment)
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {parseInt(summaryData?.offline_card_payment) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Paid via Offline card payments")}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(
                    summaryData?.offline_card_payment * convertedAmount
                  )
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {parseInt(summaryData?.upi_payment) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Paid via UPI payments")}
                normalText={
                  symbol + "" + handleDigits(summaryData?.upi_payment)
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {parseInt(summaryData?.upi_payment) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Paid via UPI payments")}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(summaryData?.upi_payment * convertedAmount)
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {/* Bank transfer */}
          {parseInt(summaryData?.bank_transfer_payment) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={t("Paid via Bank transfer")}
                normalText={
                  symbol + "" + handleDigits(summaryData?.bank_transfer_payment)
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
                customDetails={customDetails}
              />
            )}

          {parseInt(summaryData?.bank_transfer_payment) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={t("Paid via Bank transfer")}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(
                    summaryData?.bank_transfer_payment * convertedAmount
                  )
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
                customDetails={customDetails}
              />
            )}

          {parseInt(summaryData?.other_payment) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Paid via Other payments")}
                normalText={
                  symbol + "" + handleDigits(summaryData?.other_payment)
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {parseInt(summaryData?.other_payment) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Paid via Other payments")}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(summaryData?.other_payment * convertedAmount)
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {hotelCurrency === hotelConvertedCurrency &&
            parseInt(handleTotalRefund(invoice, summaryData)) > 0 && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Refund")}
                normalText={
                  symbol + "" + handleTotalRefund(invoice, summaryData)
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {hotelCurrency !== hotelConvertedCurrency &&
            parseInt(handleTotalRefund(invoice, summaryData)) > 0 && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={t("Refund")}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  (
                    handleTotalRefund(invoice, summaryData) * convertedAmount
                  ).toFixed(2)
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {/* {hotelCurrency === hotelConvertedCurrency && (
            <InvoiceDualText
              customDetails={customDetails}
              boldText={"Total paid"}
              normalText={symbol + "" + handleDigits(summaryData?.payment_made)}
              style={styles.extendedNormalStyle}
              boldTextStyle={styles.extendedBoldStyle}
            />
          )}

          {hotelCurrency !== hotelConvertedCurrency && (
            <InvoiceDualText
              customDetails={customDetails}
              boldText={"Total paid"}
              normalText={
                hotelConvertedCurrencySymbol +
                handleDigits(summaryData?.payment_made * convertedAmount)
              }
              style={styles.extendedNormalStyle}
              boldTextStyle={styles.extendedBoldStyle}
            />
          )} */}

          {Number(handleGetBillToCta(invoice, summaryData)) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={
                  `${t("Billed to")}` +
                  " " +
                  invoice?.booking_source_displayname
                }
                normalText={
                  symbol +
                  "" +
                  handleDigits(handleGetBillToCta(invoice, summaryData))
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {Number(handleGetBillToCta(invoice, summaryData)) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                customDetails={customDetails}
                boldText={
                  `${t("Billed to")}` +
                  " " +
                  invoice?.booking_source_displayname
                }
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(
                    Number(handleGetBillToCta(invoice, summaryData)) *
                    convertedAmount
                  )
                }
                style={styles.extendedNormalStyle}
                boldTextStyle={styles.extendedBoldStyle}
              />
            )}

          {hotelCurrency === hotelConvertedCurrency && (
            <InvoiceDualText
              customDetails={customDetails}
              boldText={t("Balance due")}
              normalText={symbol + "" + handleDigits(summaryData?.balance_due)}
              style={styles.extendedNormalStyle}
              boldTextStyle={styles.extendedBoldStyle}
            />
          )}
          {hotelCurrency !== hotelConvertedCurrency && (
            <InvoiceDualText
              customDetails={customDetails}
              boldText={t("Balance due")}
              normalText={
                hotelConvertedCurrencySymbol +
                "" +
                handleDigits(summaryData?.balance_due * convertedAmount)
              }
              style={styles.extendedNormalStyle}
              boldTextStyle={styles.extendedBoldStyle}
            />
          )}
        </View>
      </View>

      {/* Displaying guest signature */}
      {customDetails?.show_signature === "YES" && (
        <View style={styles.signature}>
          <View style={styles.signatureData}>
            <Text
              style={{
                color: customDetails?.content_color,
                fontSize: customDetails?.content_size,
                marginBottom: 5,
                marginTop: "auto",
              }}
            >
              {t("Guest Signature :")}
            </Text>
            {Object.keys(customDetails).length > 0 &&
              invoice?.customer_signature_base64.length > 0 ? (
              <Image
                style={[styles.parentImage, { marginLeft: 5 }]}
                src={getExtension(invoice.customer_signature_base64)}
              />
            ) : (
              <Text
                style={{ marginLeft: 5, color: customDetails?.content_color }}
              >
                _________________________
              </Text>
            )}
          </View>
          <View style={styles.signatureData}>
            {symbol === "$" ? (
              <Text
                style={{
                  color: customDetails?.content_color,
                  fontSize: customDetails?.content_size,
                  marginBottom: 5,
                }}
              >
                {t("Print Name :")}
              </Text>
            ) : (
              <Text
                style={{
                  color: customDetails?.content_color,
                  fontSize: customDetails?.content_size,
                  marginBottom: 5,
                }}
              >
                {t("Authorised Signature :")}
              </Text>
            )}
            <Text
              style={{ marginLeft: 5, color: customDetails?.content_color }}
            >
              _________________________
            </Text>
          </View>
        </View>
      )}

      {/* Displaying Cancellation Policies */}
      {customDetails &&
        Object.keys(customDetails).length > 0 > 0 &&
        customDetails?.show_cancellation_policy === "YES" && (
          <View>
            <Text
              style={{
                fontSize: "10px",
                marginTop: 7.5,
                marginBottom: 7.5,
                textDecoration: "underline",
                color: customDetails?.content_color,
              }}
            >
              {t("Cancellation Policies")}
            </Text>
            {invoice.hasOwnProperty("cancellationPolicies") ? (
              invoice?.cancellationPolicies?.length > 0 &&
                ifShowNewCancellationPolicy ? (
                <View>
                  {invoice?.cancellationPolicies
                    ?.map((policies) => policies.replace(":", "\n"))
                    .map((policy) => (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 5,
                          flexWrap: "wrap",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "10px",
                            color: customDetails?.content_color,
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          {policy}
                        </Text>
                      </View>
                    ))}
                </View>
              ) : (
                handleGenerateCancellationPolicies(invoice, customDetails)
              )
            ) : (
              handleGenerateCancellationPolicies(invoice, customDetails)
            )}
          </View>
        )}

      {/* Displaying Terms and Conditions */}
      {Object.keys(customDetails).length > 0 &&
        customDetails?.show_terms_and_condition === "YES" && (
          <View style={{ marginTop: 7.5 }}>
            <Text
              style={{
                fontSize: "10px",
                marginBottom: 5,
                textDecoration: "underline",
                color: customDetails?.content_color,
              }}
            >
              {t("Terms and Conditions")}
            </Text>
            <Text
              style={{
                fontSize: "10px",
                marginBottom: 5,
                color: customDetails?.content_color,
              }}
            >
              {localStorage.getItem("tnc")}
            </Text>
          </View>
        )}
    </View>
  );
}

export default AdvInvoiceDoc;

// Create styles
const styles = StyleSheet.create({
  parentImage: {
    width: "85px",
    height: "auto",
  },
  image: {
    position: "relative",
    width: "75px",
    alignSelf: "left",
  },
  textHeight: {
    height: "20px",
  },
  page: {
    flexDirection: "column",
    width: "50vw",
    backgroundColor: "",
    height: "100vh",
  },
  section: {
    padding: 10,
    flexGrow: 1,
  },
  dualText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 2,
    alignContent: "flex-end",
  },
  boldText: {
    fontWeight: "bold",
    color: "#222",
    marginRight: "5px",
    fontSize: "9px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: "auto",
    marginBottom: "auto",
  },
  normalText: {
    fontWeight: 400,
    color: "black",
    fontSize: "9px",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    marginRight: "5px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  container: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E5e5e5",
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderLeft: "none",
  },
  headerContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E5e5e5",
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderLeft: "none",
  },
  description: {
    width: "45%",
    wordWrap: "word-break",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // textAlign: "center",
    paddingLeft: 2.5,
    paddingTop: 2,
  },
  taxDescription: {
    width: "60%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 2.5,
    wordWrap: "word-break",
  },
  rate: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 2.5,
    wordWrap: "word-break",
  },
  amount: {
    width: "15%",
    paddingLeft: 2.5,
    wordWrap: "word-break",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: borderColor,
    borderLeft: "none",
    borderTop: "none",
  },
  row: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E5e5e5",
    borderTop: "none",
    borderLeft: "none",
  },
  taxRow: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 32,
    fontStyle: "bold",
  },
  signature: {
    marginTop: 25,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "9px",
  },
  signatureData: {
    display: "flex",
    flexDirection: "row",
    fontSize: "9px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  infoRow: {
    position: "relative",
    width: "60px",
    marginRight: "2px",
    wordBreak: "break-all",
    borderLeft: "1px solid #E5e5e5",
    fontSize: "9px",
    padding: "2.5px",
  },
  extendedNormalStyle: {
    width: "75px",
    textAlign: "right",
    justifyContent: "flex-end",
    marginRight: "0px",
    alignSelf: "flex-end",
    marginLeft: "0px",
  },
  extendedBoldStyle: {
    width: "75px",
    flexWrap: "wrap",
  },
});
