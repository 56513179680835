import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import { handleSpringDataRequest } from "../../../api";
import { useEffect } from "react";
import { useState } from "react";
import { channelLogoMapDash } from "../../CalendarPage/constants/Calendar-constants";
import moment from "moment";
import BulkSyncLoader from "./BulkSyncLoader";

function BulkSyncStatusReport({ syncReportRefresh }) {
  const customStatus = {
    IN_PROGRESS: { color: "orange", text: "In progress" },
    SUCCESS: { color: "green", text: "Success" },
    FAILURE: { color: "red", text: "Failed" },
  };
  const [reportData, setReportData] = useState({});
  const [loader, setLoader] = useState(false);

  const getAriOps = () => {
    setLoader(true);
    handleSpringDataRequest(`core/api/v1/cmLogs/get-ari-ops-logs`)
      .then((data) => {
        setReportData(data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleCustomText = (text) => {
    let tempText =
      text?.[0] + text.slice(1)?.toLowerCase()?.replaceAll("_", " ");
    return tempText;
  };

  useEffect(() => {
    getAriOps();
  }, [syncReportRefresh]);
  return (
    <>
      {loader ? (
        <BulkSyncLoader />
      ) : (
        <Box
          sx={{
            width: "100%",
            paddingX: 2,
            paddingBottom: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            overflow: "auto",
          }}
        >
          {Object.keys(reportData)?.map((logItem) => (
            <Card sx={{ padding: 2, width: "100%" }}>
              <CardContent>
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold", color: "black" }}>
                    {handleCustomText(reportData?.[logItem]?.[0]?.updateType)}
                  </span>{" "}
                  action done by{" "}
                  <span style={{ fontWeight: "bold", color: "black" }}>
                    {reportData?.[logItem]?.[0]?.updatedBy?.toLowerCase()}
                  </span>{" "}
                  on{" "}
                  <span style={{ fontWeight: "bold", color: "black" }}>
                    {moment(
                      reportData?.[logItem]?.[0]?.updateTime,
                      "DD-MM-YYYY HH:mm:ss"
                    ).format("Do MMM, YYYY hh:mm A")}
                  </span>
                </Typography>
                <Box mt={2}>
                  <Typography variant="body2">
                    <strong>Room Types:</strong>{" "}
                    {reportData?.[logItem]?.[0]?.roomType}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Rate Plans:</strong>{" "}
                    {reportData?.[logItem]?.[0]?.ratePlan}
                  </Typography>
                  <Typography variant="body2">
                    {/* Multiplier */}
                    <strong>Value:</strong>{" "}
                    {reportData?.[logItem]?.[0]?.message}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Date Range:</strong>{" "}
                    {`${moment(
                      reportData?.[logItem]?.[0]?.startDate,
                      "DD-MM-YYYY HH:mm:ss"
                    ).format("Do MMM, YYYY")} - ${moment(
                      reportData?.[logItem]?.[0]?.endDate,
                      "DD-MM-YYYY HH:mm:ss"
                    ).format("Do MMM, YYYY")}`}
                  </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                  {reportData?.[logItem]?.map((channel) => (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {channelLogoMapDash[channel?.source.toUpperCase()] ? (
                          <img
                            height="18px"
                            alt={channel?.source}
                            src={
                              channelLogoMapDash[channel?.source.toUpperCase()]
                            }
                          />
                        ) : channel?.source === "google" ? (
                          <img
                            height="18px"
                            alt={"google"}
                            src={
                              "https://prodimagesbucket.blob.core.windows.net/misc/GHA.png"
                            }
                          />
                        ) : (
                          <Typography variant="h5">
                            {channel?.source}
                          </Typography>
                        )}

                        <Typography
                          style={{
                            color: customStatus?.[channel?.status]?.color,
                            fontWeight: 500,
                          }}
                        >
                          {customStatus?.[channel?.status]?.text}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
    </>
  );
}

export default BulkSyncStatusReport;
