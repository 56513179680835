import React, { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Box,
  Divider,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  CircularProgress,
  Modal,
  Checkbox,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import ShopWrapper from "../../ShopWrapper";
// import { outlet_details } from "../Utils/OutletDetails.json";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import {
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../../api/index";
import { set } from "date-fns";
import { SecondaryButton } from "../../../../../Utils/ButtonHelper";
import ImageGallery from "react-image-gallery";
import ImageUploading from "react-images-uploading";
import { OutlinedButton } from "../../../../../Utils/ButtonHelper";
import AddImages from "../../../Images/AddImages";
import moment from "moment";
import { connect } from "react-redux";
import * as MdIcons from "react-icons/md";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
};

function OutletDetails({ data }) {
  const { t } = useTranslation();

  const url = new URL(window.location.href);
  const history = useHistory();
  const location = useLocation();
  const hotelId = url.searchParams.get("hotelId");
  const { shopId, shopName } = useParams();

  const isAdmin = data?.accessControl?.ADMINRIGHTS === "ADMIN" ? true : false;

  // Adding that specific outlet details
  const [outletDetails, setOutletDetails] = useState({
    date_created: "",
    enable_for_self_service: "",
    hotel_id: "",
    id: 0,
    is_group_tax: "",
    order_invoice_prefix: "",
    order_invoice_suffix: "",
    order_seq_start: 1,
    shop_desc: "",
    shop_metadata: "",
    shop_name: "",
    shop_status: "",
    shop_tax_id: 0,
    shop_type: "",
    tax_identification_number: "",
    shop_address: "",
    shop_phone: "",
    shop_email: "",
    service_charge_type: "PERCENTAGE",
    service_charge_value: 0,
    apply_service_charge_bydefault: "NO",
    service_charge_type_room: "PERCENTAGE",
    service_charge_value_room: 0,
  });

  // get the specific shop details
  const getShopDetails = () => {
    handleSpringDataRequest(
      `core/api/v1/shop/category/get-all?email=${localStorage.getItem("email")}`
    ).then((res) =>
      res.filter(
        (detail) =>
          String(detail.id) === String(shopId) && setOutletDetails(detail)
      )
    );
  };
  console.log(outletDetails);
  useEffect(() => {
    getShopDetails();
  }, []);

  // Loader untill API loads all data into shops
  const [loadingShops, setLoadingShops] = useState(false);

  // Getting all the tax categories
  // 1. Normal taxes
  // 2. Groups taxes

  const [taxes, setTaxes] = useState([]);
  const getAllTaxes = () => {
    djangoHandleDataRequests("tax/getAllTaxCategories")
      .then((res) => setTaxes(res))
      .catch((err) =>
        toast.error(
          `${t("Error in fetching the taxes. Contact the support team.")}`
        )
      );
  };
  useEffect(() => {
    getAllTaxes();
  }, []);

  //Putting the loader while editing the data
  const [outletEditLoading, setOutletEditLoading] = useState(false);

  // Outlet images
  const [uploadedImages, setUploadedImages] = useState([]);
  const maxNumber = 69;

  useEffect(() => {
    uploadedImages.push(
      "https://www.itchotels.com/content/dam/itchotels/in/umbrella/itc/hotels/itcrajputana-jaipur/images/overview/overview-desktop/itcrajputana-lobby.png"
    );
  }, []);

  const onChangeUploadedImg = (imgList, addUpdatedIndex) => {
    setUploadedImages(imgList);
  };

  const [uploadImage, setUploadImage] = useState(false);

  // Modal for changing the outlet details
  const [openOutletStatusModal, setOpenOutletStatusModal] = useState(false);
  const handleOpenOutletStatusModal = () => setOpenOutletStatusModal(true);
  const handleCloseOutletStautsModal = () => setOpenOutletStatusModal(false);

  //Edit/Update Shop Category
  const updateShopCategory = () => {
    handleSpringDataMutationRequest(
      "PUT",
      "core/api/v1/shop/category/update",
      outletDetails
    )
      // .then((res) => shopsImageUpload(res))
      .then((res) => setUploadImage(true))
      .then((res) =>
        toast.success(`${t("Successfully updated Outlet details")}`)
      )
      .then((res) => getShopDetails())
      // .then((res) => history.goBack())
      .then((res) =>
        history.push({
          pathname: "/allOutlets",
          search: `&hotelId=${hotelId}`,
        })
      )
      .catch((err) =>
        toast.error(
          `${t("Error in updating the shops! Please try again later.")}`
        )
      );
  };

  // Loader while deleting the outlet
  const [deleteOutletLoading, setDeleteOutletLoading] = useState(false);
  //Update shop status
  const updateShopStatus = () => {
    setDeleteOutletLoading(true);
    const updatedOutletDetails = {
      ...outletDetails,
      shop_status: "INACTIVE",
    };

    handleSpringDataMutationRequest(
      "PUT",
      "core/api/v1/shop/category/update",
      updatedOutletDetails
    )
      .then((res) => toast.success(`${t("Shop status updated successfully.")}`))
      .then((res) => getShopDetails())
      .then((res) => setDeleteOutletLoading(false))
      .then((res) =>
        history.push({
          pathname: "/allOutlets",
          search: `&hotelId=${hotelId}`,
        })
      )
      .catch((err) =>
        toast.error(
          `${t("Error in updating the status! Please try again later.")}`
        )
      )
      .catch((err) => handleCloseOutletStautsModal())
      .catch((err) => setDeleteOutletLoading(false));
  };

  const email = localStorage.getItem("rootEmail");
  const [isMasterUser, setisMasterUser] = useState(false);
  useEffect(() => {
    email !== null &&
      handleSpringDataRequest(`user/check-master-user?email=${email}`)
        .then((res) => setisMasterUser(res))
        .catch((err) => console.error("Please try again later."));
  }, []);

  console.log("outletDetails", outletDetails);

  return (
    <ShopWrapper
      backBtn={true}
      contentArea={
        <Stack spacing={2} className="ml-5 position-relative">
          <Stack spacing={2} direction="row">
            <Box
              className="position-relative border rounded border-dark border-3 w-40"
              style={{ width: "250px" }}
            >
              <Stack spacing={1}>
                <Typography className="pl-2 pt-2" style={{ color: "grey" }}>
                  {t("Outlet created on")}
                </Typography>
                <Divider />
                <Typography className="pl-2 pb-2">
                  {moment(outletDetails?.date_created).format(
                    "MMM DD YYYY HH:mm"
                  )}
                </Typography>
              </Stack>
            </Box>
            {/* <Box
              className="position-relative border rounded border-3 w-30"
              sx={{ borderColor: "#32cd32", width: "250px", cursor: "pointer" }}
            >
              <Stack spacing={1} onClick={() => handleOpenOutletStatusModal()}>
                <Typography className="pl-2 pt-4">
                  {outletDetails?.shop_status}
                </Typography>
                <Typography className="pl-2 pb-2">
                  <i style={{ fontSize: "12px" }}>
                    Click tab to change the status
                  </i>
                </Typography>
              </Stack>
            </Box> */}
          </Stack>
          <Box
            className="p-2 rounded mt-8"
            style={{ backgroundColor: "#4dffda", width: "650px" }}
          >
            {t(
              "Below are the configuration to manage your outlet information. Click on the Save button once you change the information."
            )}
          </Box>
          <Box
            className="mt-4 position-relative mt-4"
            style={{ width: "650px" }}
          >
            <Stack spacing={3} className="position-relative w-100 mt-4">
              <AddImages
                shopId={shopId}
                hotelId={hotelId}
                pType={"shops"}
                uploadImg={uploadImage}
                setUploadImg={setUploadImage}
              />
              {[outletDetails].length > 0 && (
                <>
                  {" "}
                  <TextField
                    required
                    id="outlined-required"
                    label={t("Outlet name")}
                    value={outletDetails?.shop_name}
                    onChange={(e) =>
                      setOutletDetails({
                        ...outletDetails,
                        ["shop_name"]: e.target.value,
                      })
                    }
                    helperText={
                      outletDetails?.shop_name.length === 0 && (
                        <Typography className="text-danger">
                          {t("Outlet name is required!")}
                        </Typography>
                      )
                    }
                  />
                  <TextField
                    required
                    id="outlined-required"
                    label={t("Outlet address")}
                    value={outletDetails?.shop_address}
                    onChange={(e) =>
                      setOutletDetails({
                        ...outletDetails,
                        ["shop_address"]: e.target.value,
                      })
                    }
                    helperText={
                      outletDetails?.shop_address?.length === 0 && (
                        <Typography className="text-danger">
                          {t("Outlet address is required!")}
                        </Typography>
                      )
                    }
                  />
                  <TextField
                    required
                    id="outlined-required"
                    label={t("Outlet phone")}
                    value={outletDetails?.shop_phone}
                    onChange={(e) =>
                      /^[0-9]*$/.test(e.target.value) &&
                      setOutletDetails({
                        ...outletDetails,
                        ["shop_phone"]: e.target.value,
                      })
                    }
                    helperText={
                      outletDetails?.shop_phone?.length === 0 && (
                        <Typography className="text-danger">
                          {t("Outlet contact info is required!")}
                        </Typography>
                      )
                    }
                  />
                  <TextField
                    required
                    id="outlined-required"
                    label={t("Outlet email")}
                    value={outletDetails?.shop_email}
                    onChange={(e) =>
                      setOutletDetails({
                        ...outletDetails,
                        ["shop_email"]: e.target.value,
                      })
                    }
                    helperText={
                      outletDetails?.shop_email?.length === 0 && (
                        <Typography className="text-danger">
                          {t("Outlet email address is required!")}
                        </Typography>
                      )
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    label={t("Outlet description")}
                    value={outletDetails?.shop_desc}
                    onChange={(e) =>
                      setOutletDetails({
                        ...outletDetails,
                        ["shop_desc"]: e.target.value,
                      })
                    }
                  />
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t("Shop tax")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={outletDetails?.shop_tax_id}
                      label={t("Outlet tax")}
                      onChange={(e) =>
                        setOutletDetails({
                          ...outletDetails,
                          ["shop_tax_id"]: e.target.value,
                        })
                      }
                    >
                      {taxes.map((item, index) => (
                        <MenuItem id={index} value={item.id}>
                          {item.name}&nbsp;[{item.percentage} %] &nbsp;
                          {String(item.is_group_tax) === "true" && (
                            <i style={{ fontSize: "10px" }}>(Group tax)</i>
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t("Self service")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={outletDetails?.enable_for_self_service}
                      label={t("Self service")}
                      onChange={(e) =>
                        setOutletDetails({
                          ...outletDetails,
                          ["enable_for_self_service"]: e.target.value,
                        })
                      }
                    >
                      <MenuItem value={"YES"}>
                        {t("Enable self service")}
                      </MenuItem>
                      <MenuItem value={"NO"}>
                        {" "}
                        {t("Disable self service")}{" "}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* <TextField
                    id="outlined-basic"
                    label="Order invoice prefix"
                    value={outletDetails?.order_invoice_prefix}
                    onChange={(e) =>
                      setOutletDetails({
                        ...outletDetails,
                        ["order_invoice_prefix"]: e.target.value,
                      })
                    }
                  /> */}
                  {/* <TextField
                    id="outlined-basic"
                    label="Order invoice suffix"
                    value={outletDetails?.order_invoice_suffix}
                    onChange={(e) =>
                      setOutletDetails({
                        ...outletDetails,
                        ["order_invoice_suffix"]: e.target.value,
                      })
                    }
                  /> */}
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t("Outlet type")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={outletDetails?.shop_type}
                      label={t("Outlet type")}
                      onChange={(e) => {
                        setOutletDetails({
                          ...outletDetails,
                          ["shop_type"]: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value={"FNB"}>F {"&"} B</MenuItem>
                      <MenuItem value={"OTHERS"}> {t("Other")}</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label={t("Tax identification number")}
                    value={outletDetails?.tax_identification_number}
                    onChange={(e) =>
                      setOutletDetails({
                        ...outletDetails,
                        ["tax_identification_number"]: e.target.value,
                      })
                    }
                    disabled={isAdmin ? false : true}
                  />
                  <Stack
                    direction={"row"}
                    spacing={1}
                    sx={{ position: "relative", width: "100%" }}
                  >
                    <Box
                      multiline={true}
                      minRows={3}
                      sx={{ position: "relative", width: "40%" }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t("Outlet service charge type")}
                        </InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={outletDetails?.service_charge_type}
                          label={t("Outlet service charge type")}
                          onChange={(e) =>
                            setOutletDetails({
                              ...outletDetails,
                              ["service_charge_type"]: e.target.value,
                            })
                          }
                        >
                          <MenuItem value={"PERCENTAGE"}>
                            {" "}
                            {t("Percentage")}
                          </MenuItem>
                          <MenuItem value={"FIXED"}> {t("Fixed")}</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <TextField
                      id="outlined-basic"
                      type="number"
                      label={t("Outlet service charge")}
                      value={outletDetails?.service_charge_value}
                      error={
                        (outletDetails?.service_charge_type === "PERCENTAGE" &&
                          outletDetails?.service_charge_value > 100) ||
                        ((outletDetails?.service_charge_type === "PERCENTAGE" ||
                          outletDetails?.service_charge_type === "FIXED") &&
                          outletDetails?.service_charge_value < 0)
                      }
                      helperText={
                        ((outletDetails?.service_charge_type === "PERCENTAGE" &&
                          outletDetails?.service_charge_value > 100) ||
                          ((outletDetails?.service_charge_type ===
                            "PERCENTAGE" ||
                            outletDetails?.service_charge_type === "FIXED") &&
                            outletDetails?.service_charge_value < 0)) && (
                          <Typography className="text-danger">
                            {t("Please enter a value between 0-100")}
                          </Typography>
                        )
                      }
                      onChange={(e) =>
                        setOutletDetails({
                          ...outletDetails,
                          ["service_charge_value"]: e.target.value,
                        })
                      }
                      sx={{ position: "relative", width: "60%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                outletDetails?.apply_service_charge_bydefault ===
                                "YES"
                                  ? `${t(
                                      "Service charge will be added by default"
                                    )}`
                                  : `${t(
                                      "Service charge would not be added by default"
                                    )}`
                              }
                            >
                              <IconButton
                                onClick={() => {
                                  setOutletDetails({
                                    ...outletDetails,
                                    ["apply_service_charge_bydefault"]:
                                      outletDetails?.apply_service_charge_bydefault ===
                                      "YES"
                                        ? "NO"
                                        : "YES",
                                  });
                                }}
                              >
                                {outletDetails?.apply_service_charge_bydefault ===
                                "YES" ? (
                                  <MdIcons.MdOutlineAddTask color="green" />
                                ) : (
                                  <MdIcons.MdOutlineAddTask color="#efefef" />
                                )}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    sx={{ position: "relative", width: "100%" }}
                  >
                    <Box
                      multiline={true}
                      minRows={3}
                      sx={{ position: "relative", width: "40%" }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t("Room service charge type")}
                        </InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={outletDetails?.service_charge_type_room}
                          label={t("Room service charge type")}
                          onChange={(e) =>
                            setOutletDetails({
                              ...outletDetails,
                              ["service_charge_type_room"]: e.target.value,
                            })
                          }
                        >
                          <MenuItem value={"PERCENTAGE"}>
                            {" "}
                            {t("Percentage")}
                          </MenuItem>
                          <MenuItem value={"FIXED"}> {t("Fixed")}</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <TextField
                      id="outlined-basic"
                      type="number"
                      label={t("Room service charge")}
                      value={outletDetails?.service_charge_value_room}
                      error={
                        (outletDetails?.service_charge_type_room ===
                          "PERCENTAGE" &&
                          outletDetails?.service_charge_value_room > 100) ||
                        ((outletDetails?.service_charge_type_room ===
                          "PERCENTAGE" ||
                          outletDetails?.service_charge_type_room ===
                            "FIXED") &&
                          outletDetails?.service_charge_value_room < 0)
                      }
                      helperText={
                        ((outletDetails?.service_charge_type_room ===
                          "PERCENTAGE" &&
                          outletDetails?.service_charge_value_room > 100) ||
                          ((outletDetails?.service_charge_type_room ===
                            "PERCENTAGE" ||
                            outletDetails?.service_charge_type_room ===
                              "FIXED") &&
                            outletDetails?.service_charge_value_room < 0)) && (
                          <Typography className="text-danger">
                            {t("Please enter a value between 0-100")}
                          </Typography>
                        )
                      }
                      onChange={(e) =>
                        setOutletDetails({
                          ...outletDetails,
                          ["service_charge_value_room"]: e.target.value,
                        })
                      }
                      sx={{ position: "relative", width: "60%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                outletDetails?.apply_service_charge_bydefault ===
                                "YES"
                                  ? `${t(
                                      "Service charge will be added by default"
                                    )}`
                                  : `${t(
                                      "Service charge would not be added by default"
                                    )}`
                              }
                            >
                              <IconButton
                                onClick={() => {
                                  setOutletDetails({
                                    ...outletDetails,
                                    ["apply_service_charge_bydefault"]:
                                      outletDetails?.apply_service_charge_bydefault ===
                                      "YES"
                                        ? "NO"
                                        : "YES",
                                  });
                                }}
                              >
                                {outletDetails?.apply_service_charge_bydefault ===
                                "YES" ? (
                                  <MdIcons.MdOutlineAddTask color="green" />
                                ) : (
                                  <MdIcons.MdOutlineAddTask color="#efefef" />
                                )}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <TextField
                    id="outlined-basic"
                    label={t("Order sequence start")}
                    value={outletDetails?.order_seq_start}
                    // onChange={(e) =>
                    //   setOutletDetails({
                    //     ...outletDetails,
                    //     ["order_seq_start"]: e.target.value,
                    //   })
                    // }
                    disabled
                  />
                  {isMasterUser && (
                    <Stack direction={"row"} spacing={1}>
                      <Checkbox
                        checked={openOutletStatusModal}
                        onChange={handleOpenOutletStatusModal}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      />
                      <Typography
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        <b>
                          <u>{t("I would like to delete this outlet")}</u>
                        </b>
                      </Typography>
                    </Stack>
                  )}
                </>
              )}
              <Stack
                direction="row"
                className="position-relative d-flex w-100 justify-content-between"
              >
                <SecondaryButton
                  text={t("Cancel")}
                  onClick={() =>
                    history.goBack({ state: { index: location.state.index } })
                  }
                />
                {uploadImage === false ? (
                  <Button
                    variant="custom-button"
                    onClick={updateShopCategory}
                    disabled={
                      String(outletDetails?.shop_name).length !== 0 &&
                      String(outletDetails?.shop_address).length !== 0 &&
                      String(outletDetails?.shop_email).length !== 0 &&
                      String(outletDetails?.shop_phone).length !== 0
                        ? false
                        : true
                    }
                  >
                    {t("Save")}
                  </Button>
                ) : (
                  <Button variant="custom-button">
                    <CircularProgress size="15px" />
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
          {/* Confirmation modal to change the status from active to inactive or vice vera */}
          <Modal
            open={openOutletStatusModal}
            onClose={handleCloseOutletStautsModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Stack className="position-relative w-100">
                <Stack
                  direction="row"
                  className="position-relative w-100 justify-content-between p-4"
                >
                  <Typography variant="h6" style={{ fontSize: "15px" }}>
                    {t("Delete confirmation")}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontSize: "15px", cursor: "pointer" }}
                    onClick={() => handleCloseOutletStautsModal()}
                  >
                    X
                  </Typography>
                </Stack>
                <Divider className="mt-2 mb-2" />
                <Stack spacing={2} className="p-4" direction={"row"}>
                  <Typography>
                    {" "}
                    {t("Are you sure you want to")}{" "}
                    <strong> {t("delete")}</strong>{" "}
                    {t("this outlet? Click on the confirm button to proceed.")}
                  </Typography>
                </Stack>
                <Typography className="d-flex flex-wrap p-4">
                  <i style={{ fontSize: "12.5px" }}>
                    {t(
                      "Note: In order to re-activate this outlet, kindly contact support at"
                    )}
                    <strong> {t("support@stayflexi.com")}</strong>
                  </i>
                </Typography>
                <Stack
                  direction="row"
                  className="mt-8 position-relative w-100 justify-content-between p-4"
                >
                  <SecondaryButton
                    text={t("Cancel")}
                    onClick={() => handleCloseOutletStautsModal()}
                  />
                  <Button
                    variant="custom-button"
                    onClick={() => !deleteOutletLoading && updateShopStatus()}
                  >
                    {deleteOutletLoading ? (
                      <CircularProgress size="15px" />
                    ) : (
                      `${t("Confirm")}`
                    )}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Modal>
        </Stack>
      }
    />
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(OutletDetails);
