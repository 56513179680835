import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import BusinessIcon from "@mui/icons-material/Business";
import {
  CardHeader,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  OutlinedInput,
  Autocomplete,
  TextField,
  FormHelperText,
  CircularProgress,
  Modal,
} from "@mui/material";
import company from "./sss.svg";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import moment from "moment";
import {
  channelLogoMap,
  getCountriesAndStates,
} from "../CalendarPage/constants/Calendar-constants";
import { isNull, isUndefined } from "lodash";
import { toast, ToastContainer } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import Currencies from "../../Utils/Currencies.json";
import { razorPayKey } from "../CalendarPage/constants/AmplitudeConstants";
import CounterPaymentsLogTable from "../Folios/ViewManagePayments/CounterPaymentsLogTable";
import axios from "axios";
import { BASE_SPRING_ENDPOINT } from "../../api/config";
import FlashManagerReportLoader from "../Reports/ManagerialReports/FlashManagerReport/FlashManagerReportLoader";
import PayNowLoader from "../Reports/ManagerialReports/FlashManagerReport/PayNowLoader";
import successAnimationData from "../CalendarPage/Views/lotties/celebration.json";
import Lottie from "react-lottie";
import { useHistory } from "react-router-dom";

const CustomPaymentPage = (props) => {
  const [groupId, setGroupId] = useState(null);
  const [resp, setResp] = useState(null);
  const [hotelCurr, setHotelCurr] = useState(null);
  const [isLanding, setisLanding] = useState(null);
  const [accBalance, setAccBalance] = useState(null);
  const [open, setOpen] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [imageUrl, setImageUrl] = useState(channelLogoMap.STAYFLEXI_OM);
  const [razorpayFinalKey, setRazorpayFinalKey] = useState(razorPayKey);
  const [amt, setAmt] = useState(null);

  const [razorpayAmount, setRazorpayAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [errorShow, setErrorShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  useEffect(() => {
    setGroupId(props?.groupId);
    setResp(props?.res);
    setHotelCurr(props?.hotelCurrency);
    setisLanding(props?.isLanding);
    props && setAccBalance(props?.accBalance);
    props && setAmt(props?.amt / 100);
  }, [props]);

  useEffect(() => {
    if (amt && amt !== undefined) {
      let v = parseInt(amt);
      v = v.toString();
      setRazorpayAmount(v);
    }
  }, [amt]);

  // console.log("ACC inf : ", resp);

  // console.log("bal is : ", accBalance);

  // const style = {
  //   position: "top-right",
  //   autoClose: 5000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   // progress: undefined,
  // };

  function ValidateEmail(mail) {
    if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(mail)) {
      setvalidEmail(true);
      return true;
    }
    setvalidEmail(false);
    return false;
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const invokeRazorpayModal = () => {
    payloadForRazorpay["amount"] = options["amount"];
    let rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on("payment.failed", function (response) {
      setErrorShow(true);
      setErrorMessage(response?.error?.description);
      setTimeout(() => {
        setErrorShow(false);
      }, 4000);
    });
  };
  const [openAmountModal, setOpenAmountModal] = useState(false);

  const handleAmountClose = () => setOpenAmountModal(false);
  const handleAmountOpen = () => setOpenAmountModal(true);
  const [depositLoading, setDepositLoading] = useState(false);

  const [depositAmt, setDepositAmt] = useState(0);

  const [pmDetails, setPmDetails] = useState(null);
  const fetchPaymentMethodData = () => {
    handleSpringDataRequest(
      `core/api/v1/account-payment-method/get-active-payment-method/?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        setPmDetails(res[0]);
        console.log("active payment method : ", res);
      })
      .catch((err) => {
        toast.error("No active payment method is set for the account");
        setDepositAmt(0);
        handleClose();
        console.log("The err is : ", err);
      });
  };

  const handleFortisPayment = () => {
    setDepositLoading(true);
    let mode = pmDetails?.payment_method_type === "account" ? "ach" : "cc";
    console.log("pmdetails are : ", pmDetails);
    let amt = depositAmt.toString();
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/account-payment-method/fortis_process_transaction/?groupId=${groupId}&hotelId=${groupId}&mode=${mode}&amt=${amt}`,
      pmDetails
    )
      .then((res) => {
        console.log("The fortis result is : ", res);
        toast.success(res.responseMessageStr);
        setDepositAmt(0);
        handleClose();
        const data = props?.getLedgerData();
        const datas = props?.getAccountBalance();
        setDepositLoading(false);
      })
      .catch((err) => {
        console.log("the err is : ", err);
        toast.error(err);
        setDepositAmt(0);
        handleClose();
        setDepositLoading(false);
      });
  };

  const [currSymbol, setCurrSymbol] = useState(null);
  useEffect(() => {
    let curr = "";
    const datass = Currencies?.filter((item) => {
      if (item.code === hotelCurr) {
        curr = item.symbol;
      }
    });
    setCurrSymbol(curr);
  }, [hotelCurr]);

  const [accountInfo, setAccountInfo] = useState(null);
  const [accoundDetails, setAccoundDetails] = useState(null);
  const [paymentMethods, setpaymentMethods] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [billingEmail, setBillingEmail] = useState("");
  const [billingCCEmails, setBillingCCEmails] = useState("");
  const [billingStreetAddress, setBillingStreetAddress] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingAddressZipcode, setBillingAddressZipcode] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const countries = getCountriesAndStates();

  const setCompanyData = () => {
    setAccountInfo(resp);
    setAccoundDetails(resp?.account_details);
    setpaymentMethods(resp?.payment_methods);
    setSubscriptionInfo(resp?.subscription_info);
    setBillingEmail(resp?.account_details?.account_billing_email);
    setBillingCCEmails(resp?.account_details?.account_billing_cc_emails);
    setBillingStreetAddress(resp?.account_details?.billing_address_street);
    setBillingCity(resp?.account_details?.billing_address_city);
    setBillingState(resp?.account_details?.billing_address_state);
    setBillingCountry(resp?.account_details?.billing_address_country);
    setBillingAddressZipcode(resp?.account_details?.billing_address_zipcode);
  };

  useEffect(() => {
    resp && setCompanyData();
  }, [resp]);

  const saveAccountDetails = () => {
    const accountInfo = accoundDetails;
    accountInfo.account_billing_email = billingEmail;
    accountInfo.billing_address_street = billingStreetAddress;
    accountInfo.billing_address_city = billingCity;
    accountInfo.billing_address_state = billingState;
    accountInfo.billing_address_country = billingCountry;
    accountInfo["billing_address_zipcode"] = billingAddressZipcode;

    if (billingEmail !== "") {
      if (billingStreetAddress !== "") {
        if (billingCity !== "") {
          if (billingAddressZipcode !== "") {
            if (validEmail) {
              handleSpringDataMutationRequest(
                "PUT",
                `core/api/v1/account/update/?groupId=${groupId}&hotelId=${groupId}`,
                accountInfo
              )
                .then((res) => {
                  toast.success("Billing info updated successfully");
                })
                .catch((err) => {
                  toast.error("Something wrong happened");
                });
            } else {
              toast.error("Please enter correct email");
            }
          }
        }
      }
    } else {
      toast.error("Please enter all fields");
    }
  };

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const [validEmail, setvalidEmail] = useState(true);

  const [firstAddress, setfirstAddress] = useState(null);
  const [secondAddress, setsecondAddress] = useState(null);

  useEffect(() => {
    if (accoundDetails) {
      let first =
        accoundDetails.billing_address_street !== ""
          ? accoundDetails.billing_address_street
          : "";
      if (first.length > 0)
        first =
          accoundDetails.billing_address_city !== ""
            ? first + ", " + accoundDetails.billing_address_city
            : first;
      else {
        first =
          accoundDetails.billing_address_city !== ""
            ? accoundDetails.billing_address_city
            : first;
      }

      let second =
        accoundDetails.billing_address_state !== ""
          ? accoundDetails.billing_address_state
          : "";
      if (second.length > 0)
        second =
          accoundDetails.billing_address_country !== ""
            ? second + ", " + accoundDetails.billing_address_country
            : second;
      else {
        second =
          accoundDetails.billing_address_country !== ""
            ? accoundDetails.billing_address_country
            : second;
      }
      setfirstAddress(first);
      setsecondAddress(second);
    }
  }, [accoundDetails]);

  const payloadForRazorpay = {
    group_id: groupId,
    "razorpay-pg-id": "",
    amount: 0,
    currency: "INR",
  };

  // Razorpay
  var options = {
    key: razorpayFinalKey,
    amount: "100",
    currency: "INR",
    name: "Stayflexi",
    description: "Recharge account",
    image: imageUrl,
    // order_id: "", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: function (response) {
      setTableLoader(true);
      payloadForRazorpay["razorpay-pg-id"] = response.razorpay_payment_id;
      axios
        .post(
          `${BASE_SPRING_ENDPOINT}core/api/v1/payments/razorpay/capture-payment?groupId=${groupId}&hotelId=${groupId}`,
          payloadForRazorpay
        )
        .then((resp) => {
          // const data = props?.getLedgerData();
          const datas = props?.getAccountBalance();
          setRazorpayAmount(0);
          setTableLoader(false);
          toast.success(resp?.data);
        })
        .catch((err) => {
          toast.error(err);
          setTableLoader(false);
        });
      // alert(response.razorpay_payment_id);
      // alert(response.razorpay_order_id);
      // alert(response.razorpay_signature);
    },
    prefill: {
      name: accountInfo?.account_details?.account_name,
      email: accountInfo?.account_details?.account_billing_email,
      contact: "",
    },
    notes: {
      account_id: groupId,
      module_source: "ACCOUNT_PAYMENT_LINK",
    },
    theme: {
      color: "#1ab394",
      hide_topbar: false,
    },
  };

  const successAnimation = {
    loop: true,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      {/* <Typography
        variant="h4"
        style={{ fontWeight: "400", marginBottom: "2rem" }}
      >
        Company Information
        Account Information
      </Typography> */}
      {tableLoader ? (
        <PayNowLoader />
      ) : (
        <Card sx={{ minWidth: 275, marginBottom: "2rem" }}>
          <CardContent>
            {Number(accBalance) != 0 ? (
              <div
                style={
                  {
                    //   display: "flex",
                    //   direction: "row",
                    // width: "100%",
                  }
                }
                // spacing={2}
              >
                {/* <div
              style={{
                display: "flex",
                direction: "row",
                width: "100%",
              }}
              //   spacing={2}
            > */}
                {/* <div style={{ padding: "3rem" }}>
                <img src={company} style={{ height: "12rem" }} />
              </div> */}
                <div
                  style={{
                    //   display: "flex",
                    //   direction: "row",
                    width: "100%",
                  }}
                >
                  <Typography variant="h5" style={{ fontWeight: "400" }}>
                    Account Name
                  </Typography>
                  {accoundDetails && (
                    <Typography>{accoundDetails.account_name}</Typography>
                  )}

                  <Typography
                    variant="h5"
                    style={{ marginTop: "2rem", fontWeight: "400" }}
                  >
                    Account Billing email
                  </Typography>
                  {accoundDetails && (
                    <Typography>
                      {accoundDetails.account_billing_email}
                    </Typography>
                  )}
                  {/* <Typography
                  variant="h5"
                  style={{ marginTop: "2rem", fontWeight: "400" }}
                >
                  Primary company address
                </Typography>
                {firstAddress && <Typography>{firstAddress}</Typography>}
                {secondAddress && <Typography>{secondAddress}</Typography>} */}
                  {amt && (
                    <Typography
                      variant="h5"
                      style={{ marginTop: "2rem", fontWeight: "400" }}
                    >
                      Current Outstanding{" "}
                    </Typography>
                  )}

                  {amt && (
                    <Typography>
                      {currSymbol + " " + parseFloat(amt).toFixed(2)}
                    </Typography>
                  )}

                  {/* {accBalance && accBalance < 0 && (
                  <Typography
                    variant="h5"
                    style={{ marginTop: "2rem", fontWeight: "400" }}
                  >
                    Total Outstanding{" "}
                  </Typography>
                )}

                {accBalance && accBalance > 0 && (
                  <Typography
                    variant="h5"
                    style={{ marginTop: "2rem", fontWeight: "400" }}
                  >
                    Available Balance{" "}
                  </Typography>
                )}

                {accBalance && (
                  <Typography>
                    {currSymbol + " " + parseFloat(accBalance).toFixed(2)}
                  </Typography>
                )} */}
                  <div>
                    {isLanding &&
                      isLanding === "YES" &&
                      accBalance &&
                      hotelCurr === "INR" && (
                        <div style={{ display: "flex" }}>
                          {/* <Typography
                          variant="h4"
                          style={{ fontWeight: "400", marginBottom: "2rem" }}
                        >
                          Current Outstanding :{" "}
                          {currSymbol + " " + parseFloat(accBalance).toFixed(2)}
                        </Typography> */}

                          <Button
                            variant="custom-button"
                            style={{
                              fontWeight: "400",
                              marginTop: "2rem",
                              //   marginLeft: "2rem",
                              //   marginBottom: "2rem",
                              height: "2.7rem",
                              width: "18rem",
                            }}
                            onClick={() => {
                              // fetchPaymentMethodData();
                              // handleOpen();
                              handleAmountOpen();
                            }}
                          >
                            Pay Now
                          </Button>
                        </div>
                      )}
                  </div>
                  {(hotelCurr === "INR" || hotelCurr === "USD") && (
                    <div>
                      <div
                        className="text-muted"
                        style={{ marginTop: "2rem", fontWeight: "400" }}
                      >
                        Staizi India Private Limited No. 2729, First Floor,
                        Nishvi Towers,
                      </div>
                      <div className="text-muted" style={{ fontWeight: "400" }}>
                        Opp to Nift Campus, HSR layout, Sector-1,
                        Bangalore-560102
                      </div>
                      <div
                        className="text-muted"
                        style={{ marginTop: "0.5rem", fontWeight: "400" }}
                      >
                        email - finance@Stayflexi.com, MO - 9898989898
                      </div>
                    </div>
                  )}
                  {/* </div> */}
                </div>
                {/* <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                marginRight: "3rem",
              }}
            > */}
                {/* {isLanding &&
                isLanding === "YES" &&
                accBalance &&
                hotelCurr === "USD" && (
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: "400", marginBottom: "2rem" }}
                    >
                      Current Outstanding :{" "}
                      {currSymbol + " " + parseFloat(accBalance).toFixed(2)}
                    </Typography>

                    <Button
                      variant="custom-button"
                      style={{
                        fontWeight: "400",
                        marginLeft: "2rem",
                        marginBottom: "2rem",
                        height: "2.7rem",
                      }}
                      onClick={() => {
                        fetchPaymentMethodData();
                        handleOpen();
                      }}
                    >
                      Pay Now
                    </Button>
                  </div>
                )} */}

                {/* {isLanding &&
                isLanding === "YES" &&
                accBalance &&
                hotelCurr === "INR" && (
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: "400", marginBottom: "2rem" }}
                    >
                      Current Outstanding :{" "}
                      {currSymbol + " " + parseFloat(accBalance).toFixed(2)}
                    </Typography>

                    <Button
                      variant="custom-button"
                      style={{
                        fontWeight: "400",
                        marginLeft: "2rem",
                        marginBottom: "2rem",
                        height: "2.7rem",
                      }}
                      onClick={() => {
                        // fetchPaymentMethodData();
                        // handleOpen();
                        handleAmountOpen();
                      }}
                    >
                      Pay Now
                    </Button>
                  </div>
                )} */}
                {/* </div> */}
              </div>
            ) : (
              <div className="w-100                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ">
                <Lottie options={successAnimation} height={150} width={150} />
                <h2 style={{ marginLeft: "30%" }}>Thanks for the payment</h2>
              </div>
            )}
          </CardContent>
        </Card>
      )}
      <Card sx={{ minWidth: 275, marginBottom: "2rem" }}>
        <Modal
          open={openAmountModal}
          onClose={handleAmountClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            width: "50%",
            justifyConten: "center",
            marginLeft: "25%",
          }}
        >
          <Box className="settleDuesModal" style={{ width: "100%" }}>
            <div className="settleDuesWrapper" style={{ width: "100%" }}>
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">Enter the amount</div>
                <div className="close" onClick={() => handleAmountClose()}>
                  X
                </div>
              </div>
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    required
                    id="outlined-name"
                    label="Enter the amount"
                    name="deposit_amount"
                    value={razorpayAmount}
                    onChange={(e) => {
                      /^[0-9.]*$/.test(e.target.value)
                        ? setRazorpayAmount(e.target.value)
                        : setRazorpayAmount(razorpayAmount);
                    }}
                    sx={{ width: "100%" }}
                    type={"text"}
                    disabled={true}
                  />
                </div>

                <div className="settleDuesSubmitBtns">
                  <Button
                    className="cancelBtn"
                    style={{ fontSize: "smaller" }}
                    onClick={() => handleAmountClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="submit"
                    variant="custom-button"
                    style={{
                      fontSize: "smaller",
                      width: "10%",
                      height: "2rem",
                    }}
                    onClick={() => {
                      handleAmountClose();
                      let amt = razorpayAmount.split(".");
                      let final = amt[0];
                      options["amount"] = final * 100 + "";

                      setRazorpayAmount(final * 100 + "");
                      invokeRazorpayModal();
                    }}
                    disabled={razorpayAmount <= 0}
                  >
                    {/* {depositLoading ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : ( */}
                    Pay
                    {/* )} */}
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal">
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Confirm the details</div>
              <div className="close" onClick={() => handleClose()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  required
                  id="outlined-name"
                  label="Enter the amount"
                  name="deposit_amount"
                  value={depositAmt}
                  onChange={(e) =>
                    /^[0-9.]*$/.test(e.target.value)
                      ? setDepositAmt(e.target.value)
                      : setDepositAmt(depositAmt)
                  }
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>

              {pmDetails && (
                <div className="fieldWrapper">
                  <Typography
                    variant="h5"
                    style={{ fontWeight: "600", marginBottom: "1rem" }}
                  >
                    Payment method details
                  </Typography>
                  {pmDetails?.payment_method_type === "account" ? (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Payment method type</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>Account</p>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Payment method type</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.card_type === "credit"
                          ? "Credit card"
                          : "Debit card"}
                      </p>
                    </div>
                  )}
                  {pmDetails?.payment_method_type === "account" ? (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Account last 4</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.ach_account_last4}
                      </p>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Card last 4</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.card_last4}
                      </p>
                    </div>
                  )}

                  {pmDetails?.payment_method_type === "account" ? (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Account type</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.ach_account_type}
                      </p>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Card issuer</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.card_issuer.toUpperCase()}
                      </p>
                    </div>
                  )}
                </div>
              )}
              <div className="settleDuesSubmitBtns">
                <Button className="cancelBtn" onClick={() => handleClose()}>
                  Cancel
                </Button>
                <Button
                  className="submit"
                  variant="custom-button"
                  onClick={handleFortisPayment}
                  disabled={depositLoading || depositAmt <= 0}
                >
                  {depositLoading ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomPaymentPage;
// export default CompanyInfo;
