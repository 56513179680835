import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Skeleton,
} from "@mui/material";

const GroupTableSkeleton = () => {
  let rowsPerPage = 10;
  const columns = [
    { id: "duration", label: "Duration covered", flex: 1 },
    { id: "reportType", label: "Report name", align: "right", flex: 1 },
    { id: "createdAt", label: "Request Time", align: "right", flex: 1 },
    { id: "status", label: "Status", align: "right", flex: 1 },
    { id: "url", label: "Download", align: "right", flex: 1 },
  ];
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ height: "83vh" }}>
        <Table stickyHeader aria-label="skeleton table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <Skeleton variant="text" />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(rowsPerPage)].map((_, rowIndex) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    <Skeleton variant="text" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={0} // No data initially
        rowsPerPage={rowsPerPage}
        page={0}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
      />
    </Paper>
  );
};

export default GroupTableSkeleton;
