import {
  Grid,
  IconButton,
  Stack,
  Button,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PayNowHeader from "./PayNowHeader";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import { default as ReactImageUploading } from "react-images-uploading";
import { format } from "date-fns";
import { setIshaUserImages } from "../BookingDashboard/apis/BrandingModalApi";
import { toast } from "material-react-toastify";
import moment from "moment";
import { getUserId } from "../../pages/BookingDashboard/apis/BrandingModalApi";
import { handleSpringDataRequest } from "../../api";
import CompanyInfo from "./CompanyInfo";
import axios from "axios";
import { BASE_ENDPOINT, BASE_SPRING_ENDPOINT } from "../../api/config";
import CustomPaymentPage from "./CustomPaymentPage";

const PayNowLandingPage = () => {
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelsId");
  const groupId = url.searchParams.get("group_id");
  const currency = "INR";
  const amt = url.searchParams.get("amt");

  const [accountInfo, setAccountInfo] = useState(null);
  const [accBalance, setAccBalance] = useState(null);

  const getCompanyData = () => {
    axios
      .get(
        `${BASE_SPRING_ENDPOINT}core/api/v1/account/get-single?groupId=${groupId}&hotelId=${groupId}`
      )
      .then((res) => {
        setAccountInfo(res?.data);
      })
      .catch((err) => {
        console.log("the err is : ", err);
        // toast.error(err);
      });
  };

  useEffect(() => {
    console.log("accountInfo", accountInfo);
  }, [accountInfo]);

  const getAccountBalance = () => {
    axios
      .get(
        `${BASE_SPRING_ENDPOINT}core/api/v1/account-ledger/get-account-balance?groupId=${groupId}&hotelId=${groupId}`
      )
      .then((res) => {
        setAccBalance(res?.data?.message);
      })
      .catch((err) => {
        console.log("the err is : ", err);
        toast.error(err);
      });
  };

  useEffect(() => {
    groupId && groupId !== undefined && groupId !== "" && getCompanyData();
    groupId && groupId !== undefined && groupId !== "" && getAccountBalance();
  }, [groupId]);

  return (
    <div
      style={{
        background: "white",
        margin: "0px",
        padding: "0px",
        height: "100vh",
        width: "100%",
        position: "absolute",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        backgroundColor: "#ffffff",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          paddingTop: "2rem",
          paddingLeft: "1.2rem",
          paddingBottom: "2rem",
        }}
      >
        <PayNowHeader />
      </div>

      <div
        className="w-100"
        style={{
          display: "flex",
          justifyContent: "center", // Centers horizontally
          alignItems: "center",
          // Centers vertically}}
        }}
      >
        <div style={{ marginLeft: "3%", marginRight: "3%", width: "35%" }}>
          {groupId && accountInfo && (
            <CustomPaymentPage
              groupId={groupId}
              res={accountInfo}
              isLanding={"YES"}
              getAccountBalance={getAccountBalance}
              accBalance={accBalance}
              hotelCurrency={currency}
              amt={amt}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PayNowLandingPage;
