// ** React Imports
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import {
  Autocomplete,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fab,
  Grid,
  Grow,
  IconButton,
  LinearProgress,
  Paper,
  Popper,
  TextField,
  Typography,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import {
  bulkSync,
  getAllNonHiddenRoomTypes,
  getAllRatePlans,
  getHotelChannels,
  getHotelChannelsAndId,
} from "../api/InventoryApi";
import CommonSlider from "../../CalendarPage/Views/CommonSlider";
import { DateRange } from "react-date-range";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { dateFormat } from "../../CalendarPage/constants/Calendar-constants";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { toast } from "material-react-toastify";
import { Button } from "@mui/material";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api/index";
import "./OtASync.css";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(2),
  },
  footer: {
    margin: theme.spacing(3),
    float: "right",
  },
  linerProgress: {
    width: "100%",
    "& > * + *": {
      marginop: theme.spacing(2),
    },
  },
  header: {
    padding: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const BulkSync = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const hotelId = hotel.hotelId;
  const [rooms, setRooms] = React.useState({});
  const [selectedRoom, setSelectedRoom] = useState([]);
  const [ratePlans, setRatePlans] = useState({});
  const [selectedRatePlan, setSelectedRatePlan] = useState([]);
  const [channels, setChannels] = useState({});
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showLine, setShowLine] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [syncType, setsyncType] = useState("RATE");
  const [currentDateSelection, setCurrentDateSelection] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(
        new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(
          0,
          0,
          0,
          0
        )
      ),
    },
  ]);

  const classes = useStyles();
  const {
    handleAddEventSidebar,
    open,
    refresh,
    setLineInvisible,
    setLineVisible,
    toOpenBulkSyncReport,
  } = props;

  // const setLineVisible = () => {
  //   setShowLine(true);
  // };

  // const setLineInvisible = () => {
  //   setShowLine(false);
  // };

  const getDateText = () => {
    let val = "";
    val =
      val +
      moment(currentDateSelection[0].startDate).format("DD MMM YYYY") +
      " - ";
    val = val + moment(currentDateSelection[0].endDate).format("DD MMM YYYY");
    return val;
  };

  useEffect(() => {
    getAlldata();
  }, []);

  const getAlldata = async () => {
    setLineVisible();
    setDataLoading(true);
    try {
      const [resp, rate_plans, channelData] = await Promise.all([
        getAllNonHiddenRoomTypes(),
        getAllRatePlans(),
        getHotelChannelsAndId(),
      ]);
      setSelectedRoom(Object.keys(resp));
      setRooms(resp);
      setRatePlans(rate_plans);
      setSelectedRatePlan(rate_plans);

      setChannels(channelData["registered"]);
      setSelectedChannels(channelData["registered"]);
      setLineInvisible();
      setDataLoading(false);
    } catch (e) {
      console.log(e);
      setLineInvisible();
      setDataLoading(false);
    }
  };

  const [syncLoader, setsyncLoader] = useState(false);

  const sync = async () => {
    try {
      const payload = {
        hotelId: hotelId,
        roomIds: selectedRoom,
        ratePlanIds: selectedRatePlan.map((rtp) => rtp.id),
        channelIds: selectedChannels.map((ch) => ch.channelId),
        fromDate: moment(currentDateSelection[0].startDate).format(dateFormat),
        toDate: moment(currentDateSelection[0].endDate).format(dateFormat),
      };
      setsyncLoader(true);
      if (syncType === "INVENTORY") {
        axios
          .post(
            `${process.env.REACT_APP_BG_API_URL}core/api/v1/inventory/bulk-sync-inventory`,
            payload
          )
          .then((res) => {
            toast.success(res.data);
            setsyncLoader(false);
          })
          .catch((err) => {
            toast.error(err.response.data);
            setsyncLoader(false);
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_BG_API_URL}core/api/v1/inventory/bulk-sync-rates`,
            payload
          )
          .then((res) => {
            toast.success(res.data);
            setsyncLoader(false);
            toOpenBulkSyncReport();
          })
          .catch((err) => {
            toast.error(err.response.data);
            setsyncLoader(false);
          });
      }

      // const data = await bulkSync({
      //   hotelId: hotelId,
      //   roomIds: selectedRoom,
      //   ratePlanIds: selectedRatePlan.map((rtp) => rtp.id),
      //   channelIds: selectedChannels.map((ch) => ch.channelId),
      //   fromDate: moment(currentDateSelection[0].startDate).format(dateFormat),
      //   toDate: moment(currentDateSelection[0].endDate).format(dateFormat),
      // });
      // setsyncLoader(false);
      // if (data === "Operation Successful") {
      //   toast.success(`${t("Successfully synced")}`);
      //   // getAllDatas();
      //   // handleShow();
      // } else {
      //   toast.error(`${t("Something went wrong")}`);
      // }
    } catch (e) {
      console.log(e);
    }
  };
  const [selectedFilter, setSelectedFilter] = useState("Inventory");
  const [filterLogData, setfilterLogData] = useState([]);
  const [show, setShow] = useState(false);
  const [cmData, setcmData] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setloader] = useState(false);
  // const getAllDatas = () => {
  //   setloader(true);
  //   handleSpringDataRequest(`core/api/v1/cmLogs/getCmLogs`)
  //     .then((res) => {
  //       setcmData(res);
  //       setloader(false);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       setloader(false);
  //     });
  // };
  // useEffect(() => {
  //   if (selectedFilter === "Inventory") {
  //     setfilterLogData(cmData["inventoryLogs"]);
  //   } else if (selectedFilter === "Pricing") {
  //     setfilterLogData(cmData["rateLogs"]);
  //   } else if (selectedFilter === "Restriction") {
  //     setfilterLogData(cmData["restrictionLogs"]);
  //   }
  // }, [selectedFilter, cmData]);

  return (
    <div className={classes.root}>
      <Box
      // open={open}
      // onClose={() => {
      //   handleAddEventSidebar();
      // }}
      >
        <Grid container>
          {/* <Grid container item xs={12} className={classes.header}>
            <Grid
              container
              xs={10}
              alignSelf="center"
              justifyContent="flex-start"
              item
            >
              <Typography variant="h5">{t("Bulk Sync")}</Typography>
            </Grid>
            <Grid container xs={2} item justifyContent="flex-end">
              <IconButton title="close" onClick={handleAddEventSidebar}>
                <Close />
              </IconButton>
            </Grid>
          </Grid> */}

          {/* {showLine && <LinearProgress className={classes.linerProgress} />} */}
          {/* {!showLine && <Divider sx={{ width: "100%", marginBottom: 2 }} />} */}
          {!dataLoading && (
            <Grow in timeout={1000} style={{ transformOrigin: "0 0 0" }}>
              <Grid container className={classes.textField} rowSpacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth style={{ marginBottom: "2rem" }}>
                    <InputLabel id="demo-simple-select-label">
                      {t("Sync type")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setsyncType(e.target.value)}
                      className="mr-4"
                      label={t("Sync type")}
                      value={syncType}
                    >
                      <MenuItem value={"RATE"}>{"Rate sync"}</MenuItem>
                      <MenuItem value={"INVENTORY"}>
                        {"Inventory sync"}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Autocomplete
                    fullWidth
                    disableClearable
                    multiple
                    id="room-types"
                    disableCloseOnSelect
                    onChange={(eve, val) => {
                      if (val.length > 0) setSelectedRoom(val);
                    }}
                    value={selectedRoom}
                    ChipProps={{ variant: "outlined", color: "primary" }}
                    getOptionLabel={(opt) => rooms[opt]}
                    // isOptionEqualToValue={(opt, val) => opt.id === val.id}
                    // defaultValue={channel_names.find(val => val.label[0])}
                    options={Object.keys(rooms)}
                    renderInput={(params) => (
                      <TextField {...params} label={t("Room Types")} />
                    )}
                  />
                </Grid>
                {syncType !== "INVENTORY" && (
                  <Grid item xs={12}>
                    <Autocomplete
                      fullWidth
                      multiple
                      disableCloseOnSelect
                      disableClearable
                      id="rate-plans"
                      onChange={(eve, val) => {
                        if (val.length > 0) setSelectedRatePlan(val);
                      }}
                      ChipProps={{ variant: "outlined", color: "primary" }}
                      value={selectedRatePlan}
                      isOptionEqualToValue={(opt, val) => opt.id === val.id}
                      options={ratePlans}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField {...params} label={t("Rate Plans")} />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    multiple
                    disableClearable
                    disableCloseOnSelect
                    id="channels"
                    onChange={(eve, val) => {
                      if (val.length > 0) setSelectedChannels(val);
                    }}
                    ChipProps={{ variant: "outlined", color: "primary" }}
                    value={selectedChannels}
                    isOptionEqualToValue={(opt, val) =>
                      opt.channelId === val.channelId
                    }
                    options={channels}
                    getOptionLabel={(option) => option.channelName}
                    renderInput={(params) => (
                      <TextField {...params} label={t("Channels")} />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <ClickAwayListener
                      onClickAway={() => {
                        setShowDatePicker(false);
                        setAnchorEl(null);
                      }}
                    >
                      <div>
                        <TextField
                          fullWidth
                          focused={showDatePicker}
                          required
                          InputProps={{ readOnly: true }}
                          value={getDateText()}
                          label={t("Date range")}
                          onClick={(eve) => {
                            setAnchorEl(eve.currentTarget);
                            setShowDatePicker(true);
                          }}
                        ></TextField>
                        {showDatePicker && (
                          <Popper
                            style={{ zIndex: 2 }}
                            open={showDatePicker}
                            anchorEl={anchorEl}
                            placement="bottom"
                            transition
                            disablePortal
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                style={{
                                  transformOrigin: (placement = "bottom-end"),
                                }}
                                {...TransitionProps}
                              >
                                <Paper
                                  elevation={5}
                                  className="inventory-daterange-custom"
                                >
                                  <DateRange
                                    color="#1BC5BD"
                                    rangeColors={["#1BC5BD"]}
                                    editableDateInputs={false}
                                    moveRangeOnFirstSelection={false}
                                    onChange={(data) => {
                                      setCurrentDateSelection([
                                        { ...data.range1 },
                                      ]);
                                    }}
                                    ranges={currentDateSelection}
                                  ></DateRange>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        )}
                      </div>
                    </ClickAwayListener>
                  </div>
                </Grid>
                {!dataLoading && (
                  <Grid container>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      className={classes.textField}
                      justifyContent="flex-end"
                      container
                    >
                      <Fab
                        onClick={sync}
                        variant="extended"
                        color="primary"
                        disabled={syncLoader}
                        sx={{ width: 100 }}
                      >
                        {syncLoader ? (
                          <CircularProgress
                            size={15}
                            color="inherit"
                            sx={{ ml: 1.5 }}
                          />
                        ) : (
                          `${t("Save")}`
                        )}
                      </Fab>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grow>
          )}
        </Grid>
        {/* <Modal
          show={show}
          onHide={handleClose}
          style={{ zIndex: "3000" }}
          dialogClassName={"myModalBodyota"}
          contentClassName={"myModalContentOta"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="d-flex align-items-center">
                <h4 className="mb-0">
                  {t("Inventory, Pricing and Restriction logs")}
                </h4>

                <Button variant="custom-button" style={{ marginLeft: "12rem" }}>
                  {loader ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    `${t("Refresh")}`
                  )}
                </Button>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                fontSize: "0.9rem",
                marginTop: "1rem",
                justifyContent: "space-between",
                marginLeft: "1rem",
                marginBottom: "1rem",
              }}
            >
              <div
                style={{
                  marginLeft: "1rem",
                  border: "1px solid #DDDDDD",
                  padding: "5px",
                  borderRadius: "25px",
                  width: "10rem",
                  textAlign: "center",
                  backgroundColor:
                    selectedFilter === "Inventory" &&
                    "linear-gradient(-150deg, #1ab394, #1a91ae)",
                  backgroundImage:
                    selectedFilter === "Inventory" &&
                    "linear-gradient(-150deg, #1ab394, #1a91ae)",
                  cursor: "pointer",
                  color: selectedFilter === "Inventory" && "#fff",
                  fontSize: "1.2rem",
                }}
                onClick={() => setSelectedFilter("Inventory")}
              >
                {t("Inventory logs")}
              </div>

              <div
                style={{
                  marginLeft: "1rem",
                  border: "1px solid #DDDDDD",
                  padding: "5px",
                  borderRadius: "25px",
                  width: "10rem",
                  textAlign: "center",
                  backgroundColor:
                    selectedFilter === "Pricing" &&
                    "linear-gradient(-150deg, #1ab394, #1a91ae)",
                  backgroundImage:
                    selectedFilter === "Pricing" &&
                    "linear-gradient(-150deg, #1ab394, #1a91ae)",
                  cursor: "pointer",
                  fontSize: "1.2rem",
                  color: selectedFilter === "Pricing" && "#fff",
                }}
                onClick={() => setSelectedFilter("Pricing")}
              >
                {t("Pricing logs")}
              </div>
              <div
                style={{
                  marginLeft: "1rem",
                  border: "1px solid #DDDDDD",
                  padding: "5px",
                  borderRadius: "25px",
                  width: "10rem",
                  textAlign: "center",
                  backgroundColor:
                    selectedFilter === "Restriction" &&
                    "linear-gradient(-150deg, #1ab394, #1a91ae)",
                  backgroundImage:
                    selectedFilter === "Restriction" &&
                    "linear-gradient(-150deg, #1ab394, #1a91ae)",

                  cursor: "pointer",
                  color: selectedFilter === "Restriction" && "#fff",
                  fontSize: "1.2rem",
                }}
                onClick={() => setSelectedFilter("Restriction")}
              >
                {t("Restriction logs")}
              </div>
            </div>

            {filterLogData?.map((e) => (
              <Card
                className="mb-3"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                }}
              >
                <Card.Body>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-column">
                      <h5>{t("Room name")}</h5>
                      <p>{e.roomName}</p>
                    </div>
                    <div className="d-flex flex-column">
                      <h5>{t("Date")}</h5>
                      <p>{e.dateRange}</p>
                    </div>
                    <div className="d-flex flex-column">
                      <h5>{t("OTA")}</h5>
                      <p>{e.channel}</p>
                    </div>
                    <div className="d-flex flex-column">
                      <h5>{t("Status")}</h5>
                      <p
                        style={{
                          backgroundImage:
                            e.status === "SUCCESS"
                              ? "linear-gradient(-150deg, #1ab394, #1a91ae)"
                              : "rgba(255, 0, 0, 0.45)",
                          backgroundColor:
                            e.status === "SUCCESS"
                              ? "#1ab394"
                              : "rgba(255, 0, 0, 0.45)",
                          borderRadius: "5px",
                          color: "#FFFFFF",
                          fontWeight: "bold",

                          padding: "3px",
                        }}
                      >
                        {e.status}
                      </p>
                    </div>
                    <div className="d-flex flex-column">
                      <h5>{t("Updated on OTA")}</h5>
                      <p>{e.updatedTime}</p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Modal.Footer>
        </Modal> */}
      </Box>
    </div>
  );
};

export default BulkSync;
