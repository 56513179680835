import { isObject, isUndefined } from "lodash";
import moment from "moment";
import { dateFormat, displayTimes } from "../constants/Calendar-constants";

export class BookingDataModel {
  roomsData = [];
  bookingData = [];
  checkInTimes = [];
  viewType = "";
  slotType = "";
  roomTypeInformation = {};
  totalUnassignedReservations = 0;
  constructor(data, viewType, slotType) {
    this.viewType = viewType;
    this.slotType = slotType;
    this.totalUnassignedReservations = this.filterRoomData(data);
    this.setRoomInfo(data.roomTypeList);
  }

  setRoomInfo(data) {
    if (isUndefined(data)) {
      return;
    }
    data.forEach((roomType) => {
      this.roomTypeInformation[roomType.roomTypeId] = roomType;
    });
  }

  filterRoomData = (data) => {
    if (
      !isObject(data) ||
      isUndefined(data.allRoomReservations) ||
      !data.allRoomReservations
    ) {
      return;
    }
    for (const key in data.allRoomReservations) {
      data.allRoomReservations[key].forEach((room, index) => {
        this.roomsData.push({
          title: room.roomId,
          id: room.roomId,
          roomType: room.roomTypeName,
          roomTypeId: room.roomTypeId,
          dirtyColor: room.dirtyColor,
          roomTypeCode: room.roomTypeCode,
          roomTypeColor: room.roomTypeColor,
          initalId: index,
        });
        if (this.slotType === "NOON") this.filterSingleTypeSlotData(room);
        else this.filterBookingData(room);
      });
    }
  };

  setCustomStart(reservation) {
    let temp_date = moment(reservation.displayCheckin, dateFormat);
    if (this.viewType === "resourceTimelineWeek") {
      const index = reservation.startSlot % 3;
      temp_date.set({ hour: displayTimes[index], minute: 0, second: 0 });
    }
    return temp_date.toISOString();
  }

  setCustomEnd(reservation) {
    let temp_date = moment(reservation.displayCheckout, dateFormat);
    if (this.viewType === "resourceTimelineWeek") {
      const index = (reservation.startSlot + reservation.reservationLength) % 3;
      temp_date.set({ hour: displayTimes[index], minute: 0, second: 0 });
    }
    return temp_date.toISOString();
  }

  filterSingleTypeSlotData(room) {
    if (
      isUndefined(room.resInfoList) ||
      !room.resInfoList ||
      room.resInfoList.length === 0
    ) {
      return;
    }
    room.resInfoList.map((reservation) => {
      var data = {};
      const new_date = moment(reservation.displayCheckin, dateFormat);
      const new_end =
        this.viewType === "resourceTimelineDay"
          ? moment(reservation.displayCheckout, dateFormat)
          : moment(reservation.displayCheckout, dateFormat)
              .subtract(1, "day")
              .set({ hour: new_date.hour() + 1 });
      if (reservation.reservationStatus === "BLOCKED") {
        data = {
          id: reservation.reservationId,
          resourceId: reservation.roomId,
          title: "Blocked",
          start: new_date.toISOString(),
          end: new_end.toISOString(),
          actual_start: moment(reservation.displayCheckin, dateFormat),
          actual_end: moment(reservation.displayCheckout, dateFormat),
          status: reservation.reservationStatus,
          enquiry: reservation.isEnquiry,
          locked_status: reservation.lockedStatus,
          bookingNotes: reservation.notesAdded,
          balanceDue: reservation.balanceDue,
          selfServiceStatus: reservation.selfServiceStatus,
          groupBooking: reservation.groupBooking,
          enquiryExpirationTime: reservation.enquiryExpirationTime,
          bookingId: reservation.bookingId,
          reservationId: reservation.reservationId,
          bookingSource: reservation.bookingSource,
          editable: false,
          resourceEditable: false,
          highBalanceDueAlertStatus: reservation.highBalanceDueAlertStatus,
          segment: reservation.segment,
        };
      } else if (reservation.reservationStatus === "COMBO") {
        data = {
          id: reservation.reservationId,
          resourceId: reservation.roomId,
          title: "Combo Room",
          start: new_date.toISOString(),
          end: new_end.toISOString(),
          actual_start: moment(reservation.displayCheckin, dateFormat),
          actual_end: moment(reservation.displayCheckout, dateFormat),
          status: reservation.reservationStatus,
          enquiry: reservation.isEnquiry,
          locked_status: reservation.lockedStatus,
          bookingNotes: reservation.notesAdded,
          bookingId: reservation.bookingId,
          reservationId: reservation.reservationId,
          bookingSource: reservation.bookingSource,
          balanceDue: reservation.balanceDue,
          selfServiceStatus: reservation.selfServiceStatus,
          groupBooking: reservation.groupBooking,
          enquiryExpirationTime: reservation.enquiryExpirationTime,
          editable: false,
          resourceEditable: false,
          highBalanceDueAlertStatus: reservation.highBalanceDueAlertStatus,
          segment: reservation.segment,
        };
      } else {
        const new_date_1 = moment(reservation.displayCheckin, dateFormat);
        const new_end_1 =
          this.viewType === "resourceTimelineDay"
            ? moment(reservation.displayCheckout, dateFormat)
            : moment(reservation.displayCheckout, dateFormat)
                .subtract(1, "day")
                .set({ hour: new_date_1.hour() + 1 });
        data = {
          id: reservation.reservationId,
          resourceId: reservation.roomId,
          title: reservation.username,
          start: new_date_1.toISOString(),
          end: new_end_1.toISOString(),
          actual_start: moment(reservation.displayCheckin, dateFormat),
          actual_end: moment(reservation.displayCheckout, dateFormat),
          status: reservation.reservationStatus,
          enquiry: reservation.isEnquiry,
          locked_status: reservation.lockedStatus,
          bookingNotes: reservation.notesAdded,
          balanceDue: reservation.balanceDue,
          selfServiceStatus: reservation.selfServiceStatus,
          groupBooking: reservation.groupBooking,
          enquiryExpirationTime: reservation.enquiryExpirationTime,
          bookingId: reservation.bookingId,
          reservationId: reservation.reservationId,
          bookingSource: reservation.bookingSource,
          editable:
            reservation.reservationStatus === "CHECKED_OUT" ||
            reservation.reservationStatus === "DONE"
              ? false
              : true,
          resourceEditable:
            reservation.reservationStatus === "CHECKED_OUT" ||
            reservation.reservationStatus === "DONE"
              ? false
              : true,
          highBalanceDueAlertStatus: reservation.highBalanceDueAlertStatus,
          segment: reservation.segment,
        };
      }
      this.bookingData.push(data);
    });
  }

  filterBookingData(room) {
    if (
      isUndefined(room.resInfoList) ||
      !room.resInfoList ||
      room.resInfoList.length === 0
    ) {
      return;
    }
    room.resInfoList.map((reservation) => {
      var data = {};
      if (reservation.reservationStatus === "BLOCKED") {
        data = {
          id: reservation.reservationId,
          resourceId: reservation.roomId,
          title: "Blocked",
          start:
            this.slotType === "PURE_HOURLY"
              ? moment(reservation.displayCheckin, dateFormat).toISOString()
              : this.setCustomStart(reservation),
          end:
            this.slotType === "PURE_HOURLY"
              ? moment(reservation.displayCheckout, dateFormat).toISOString()
              : this.setCustomEnd(reservation),
          actual_start: moment(reservation.displayCheckin, dateFormat),
          actual_end: moment(reservation.displayCheckout, dateFormat),
          status: reservation.reservationStatus,
          enquiry: reservation.isEnquiry,
          locked_status: reservation.lockedStatus,
          bookingNotes: reservation.notesAdded,
          balanceDue: reservation.balanceDue,
          selfServiceStatus: reservation.selfServiceStatus,
          groupBooking: reservation.groupBooking,
          enquiryExpirationTime: reservation.enquiryExpirationTime,
          bookingId: reservation.bookingId,
          reservationId: reservation.reservationId,
          bookingSource: reservation.bookingSource,
          editable: false,
          resourceEditable: false,
          highBalanceDueAlertStatus: reservation.highBalanceDueAlertStatus,
          segment: reservation.segment,
        };
      } else if (reservation.reservationStatus === "COMBO") {
        data = {
          id: reservation.reservationId,
          resourceId: reservation.roomId,
          title: "Combo Room",
          start:
            this.slotType === "PURE_HOURLY"
              ? moment(reservation.displayCheckin, dateFormat).toISOString()
              : this.setCustomStart(reservation),
          end:
            this.slotType === "PURE_HOURLY"
              ? moment(reservation.displayCheckout, dateFormat).toISOString()
              : this.setCustomEnd(reservation),
          actual_start: moment(reservation.displayCheckin, dateFormat),
          actual_end: moment(reservation.displayCheckout, dateFormat),
          status: reservation.reservationStatus,
          enquiry: reservation.isEnquiry,
          locked_status: reservation.lockedStatus,
          bookingNotes: reservation.notesAdded,
          balanceDue: reservation.balanceDue,
          selfServiceStatus: reservation.selfServiceStatus,
          groupBooking: reservation.groupBooking,
          enquiryExpirationTime: reservation.enquiryExpirationTime,
          bookingId: reservation.bookingId,
          reservationId: reservation.reservationId,
          bookingSource: reservation.bookingSource,
          editable: false,
          resourceEditable: false,
          highBalanceDueAlertStatus: reservation.highBalanceDueAlertStatus,
          segment: reservation.segment,
        };
      } else {
        data = {
          id: reservation.reservationId,
          resourceId: reservation.roomId,
          title: reservation.username,
          start:
            this.slotType === "PURE_HOURLY"
              ? moment(reservation.displayCheckin, dateFormat).toISOString()
              : this.setCustomStart(reservation),
          end:
            this.slotType === "PURE_HOURLY"
              ? moment(reservation.displayCheckout, dateFormat).toISOString()
              : this.setCustomEnd(reservation),
          actual_start: moment(reservation.displayCheckin, dateFormat),
          actual_end: moment(reservation.displayCheckout, dateFormat),
          status: reservation.reservationStatus,
          enquiry: reservation.isEnquiry,
          locked_status: reservation.lockedStatus,
          bookingNotes: reservation.notesAdded,
          selfServiceStatus: reservation.selfServiceStatus,
          groupBooking: reservation.groupBooking,
          enquiryExpirationTime: reservation.enquiryExpirationTime,
          balanceDue: reservation.balanceDue,
          bookingId: reservation.bookingId,
          reservationId: reservation.reservationId,
          bookingSource: reservation.bookingSource,
          editable:
            reservation.reservationStatus === "CHECKED_OUT" ||
            reservation.reservationStatus === "DONE"
              ? false
              : true,
          resourceEditable:
            reservation.reservationStatus === "CHECKED_OUT" ||
            reservation.reservationStatus === "DONE"
              ? false
              : true,
          highBalanceDueAlertStatus: reservation.highBalanceDueAlertStatus,
          segment: reservation.segment,
        };
      }
      this.bookingData.push(data);
    });
  }
}
