import { Box, Button, Modal } from "@mui/material";
import moment from "moment";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const PaymentModal = ({
  showPaymentModal,
  closePaymentModal,
  paymentAmount,
  days,
  finalDate,
}) => {
  const history = useHistory();

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  return (
    hotel?.country?.toLowerCase() === "india" && (
      <Modal
        open={showPaymentModal}
        onClose={closePaymentModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
          }}
        >
          <div>
            <div className="settleDuesHeader d-flex justify-content-between">
              <div className="settleDuesHeading">{"Important notice"}</div>
              <div className="close" onClick={() => closePaymentModal()}>
                X
              </div>
            </div>

            <div
              className="d-flex flex-column w-100"
              style={{ marginTop: "1rem" }}
            >
              <p
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "200",
                }}
              >
                You have <strong>{` INR ${Math.abs(paymentAmount)}`}</strong> is
                overdue by{" "}
                <strong>
                  {moment(finalDate, "YYYY-MM-DD HH:mm:ss")
                    .add(45, "days")
                    .format("D MMMM YYYY")}
                </strong>{" "}
                days. Please clear the dues by to avoid disruption in services.
              </p>

              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "200",
                  marginTop: "1rem",
                }}
              >
                Call us at <strong>08031404576</strong> or email us at
                <strong> support@satyflexi.com</strong>.If you have any
                questions.
              </p>
              <Button
                style={{ float: "left" }}
                variant="custom-button"
                onClick={() =>
                  history.push({
                    pathname: "recommendations",
                    search: `?hotelId=${hotel?.hotelId}&showPayment=true&paynow=true&showAutoPay=false`,
                  })
                }
              >
                Pay now
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    )
  );
};

export default PaymentModal;
