import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  Skeleton,
} from "@mui/material";

const BulkSyncLoader = () => {
  return (
    <Box
      sx={{
        width: "100%",
        paddingX: 2,
        paddingBottom: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        overflow: "auto",
      }}
    >
      {/* Simulate multiple cards */}
      {[...Array(3)].map((_, index) => (
        <Card sx={{ padding: 2, width: "100%" }} key={index}>
          <CardContent>
            {/* Header Skeleton */}
            <Typography variant="body1">
              <Skeleton variant="text" width="60%" height={24} />
            </Typography>
            <Box mt={2}>
              {/* Room Types Skeleton */}
              <Typography variant="body2">
                <Skeleton variant="text" width="40%" height={20} />
              </Typography>
              {/* Rate Plans Skeleton */}
              <Typography variant="body2">
                <Skeleton variant="text" width="40%" height={20} />
              </Typography>
              {/* Multiplier Value Skeleton */}
              <Typography variant="body2">
                <Skeleton variant="text" width="40%" height={20} />
              </Typography>
              {/* Date Range Skeleton */}
              <Typography variant="body2">
                <Skeleton variant="text" width="50%" height={20} />
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
              {[...Array(3)].map((_, idx) => (
                <Grid item xs={12} key={idx}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* Channel Logo Skeleton */}
                    <Skeleton variant="rectangular" width={100} height={18} />
                    {/* Status Skeleton */}
                    <Skeleton variant="text" width="20%" height={20} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default BulkSyncLoader;
